import { createStatefulActionTypes } from 'utils/redux-stateful';
import { START_SCENARIO } from './constants';

const { SET_INITIAL, SET_SUCCESS } = createStatefulActionTypes(START_SCENARIO);

const initialState = {
  isModalOpen: false,
  apiData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SUCCESS: {
      const { id: workflowId } = action.data.data;
      return {
        ...state,
        isModalOpen: true,
        workflowId,
      };
    }
    case SET_INITIAL:
      return initialState;
    default:
      return state;
  }
}
