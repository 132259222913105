import {
  EA_CLICK,
  EA_SENT,
  EC_CONVERTION,
  EC_PUSH,
  EVENT,
  EA_ERROR,
  EC_FORM,
  EC_ACCOUNT,
  EA_ACCOUNT,
  EA_TRY_SERVICE,
} from './constants';

export const pushDataLayer = ({ eventCategory, eventAction, ...event }) => {
  if (!window?.dataLayer) return;

  window.dataLayer.push({
    event: EVENT,
    eventCategory,
    eventAction,
    ...event,
  });
};

export const sendAuthAnalytics = (event) => pushDataLayer({
  eventCategory: EC_PUSH,
  eventAction: EA_SENT,
  ...event,
});

export const sendConsultationSuccess = (formName) => pushDataLayer({
  eventCategory: `${EC_FORM}${formName}`,
  eventAction: EA_SENT,
});

export const sendFieldAnalytics = ({ formName, event }) => {
  pushDataLayer({
    eventCategory: `${EC_FORM}${formName}`,
    ...event,
  });
};

export const sendClickAnalytics = ({ text }) => {
  pushDataLayer({
    eventCategory: EC_CONVERTION,
    eventAction: EA_CLICK,
    eventLabel: text,
  });
};

export const sendAuthSubmitFail = ({ formName, failedFields }) => pushDataLayer({
  eventCategory: `${EC_FORM}${formName}`,
  eventLabel: failedFields,
  eventAction: EA_ERROR,
});

export const sendAuthClickAnalytics = (eventLabel) => {
  pushDataLayer({
    eventCategory: EC_ACCOUNT,
    eventAction: EA_ACCOUNT,
    eventLabel,
  });
};

export const sendTryServiceAnalytics = (eventLabel) => {
  pushDataLayer({
    eventCategory: EC_ACCOUNT,
    eventAction: EA_TRY_SERVICE,
    eventLabel,
  });
};
