import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setContent } from 'store/content';

import { getReactModelRequest } from 'requests/api/react-model';

const convertReactModelData = (rawData) => rawData.reduce((accum, { alias, data }) => ({
  ...accum,
  [alias]: data,
}), {});

export const withReactModel = (alias) => (WrappedComponent) => {
  const Component = ({ onSetContent, ...props }) => {
    const { id: segmentId } = useParams();

    useEffect(() => {
      if (!alias && segmentId) {
        getReactModelRequest(segmentId).then((data) => {
          onSetContent(convertReactModelData(data)[segmentId]);
        });
      }

      if (typeof alias === 'string') {
        getReactModelRequest(alias).then((data) => {
          onSetContent(convertReactModelData(data)[alias]);
        });
      } else {
        onSetContent(alias);
      }
    }, []);

    return <WrappedComponent {...props} />;
  };

  Component.propTypes = {
    onSetContent: PropTypes.func,
  };

  return connect(null, { onSetContent: setContent })(Component);
};
