import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import commonStyles from 'styles/common.pcss';

import { IconCircle } from '../IconCircle';
import { LinkBlock } from '../LinkBlock';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


export function Step({ children, icon, isActive, onClick, className }) {
  return (
    <LinkBlock onClick={onClick} className={cx('component', className)}>
      <IconCircle isSmall className={cx('icon')} isActive={isActive}>{icon}</IconCircle>
      {children && <div className={cx('content', 'marl-m')}>{children}</div>}
    </LinkBlock>
  );
}

Step.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
