import {
  composeCheckedValidator,
  composeEmailValidator,
  composeNameValidator,
  composePhoneValidator,
  composeRequiredValidator,
} from 'utils/field-validators';

export const getValidators = (validationMsg) => () => ({
  fullName: [
    composeRequiredValidator(validationMsg.required),
    composeNameValidator(validationMsg.fullName),
  ],
  ctn: [
    composeRequiredValidator(validationMsg.required),
    composePhoneValidator(validationMsg.ctn),
  ],
  email: [
    composeRequiredValidator(validationMsg.required),
    composeEmailValidator(validationMsg.email),
  ],
  agreement: [
    composeCheckedValidator(validationMsg.agreement),
  ],
});
