import React, { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { REACT_MODELS } from 'constants';
import { getLocale } from 'requests/helpers';

import { withRenderFlag, withReactModel } from 'enhancers';
import commonStyles from 'styles/common.pcss';
import { BlockSlugs } from 'store/constants';
import { getPageReady } from 'store/common/selectors';
import { getCorporateAdTitle } from 'store/content';
import { isScreenBelow502, isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { PageLayout, RGrid, RCol, Suspense, IEWarningPopup } from 'shared';
import {
  Wrapper,
  Parameter,
  RemoveWrapper,
  Flex,
  ScrollContainer,
  ScrollContainerWithButton,
} from 'components';
import { MainBanner, MainBannerBg, BlockMapper, IfBlock, ActionButton, MainBannerHeading, MainBannerText,
  BlockHeading, BlockSubHeading, ItemMapper, PdfIcon, ItemTitle, ItemBenefit,
  ItemText, BenefitExtra, TariffPrice, DetailedPriceExtra, DetailedPriceText, ExampleAuthTabs, ExampleAuthTabItem,
  ExampleStep, ExampleStepsMapper, ExampleContainer, TryExampleButton, ExampleButtonInfo, ExampleBg, ExampleSmallText,
  ConnectedFaqItem, BusinessCard, BlockBenefit, BlockActionButton, BlockInstructionButton, ExampleActiveStepProvider,
  ExampleOfferCheckbox, ConsultationButton, Benefits, CorporateAd,
} from './components';

const cx = classNames.bind(commonStyles);


const MainPage = ({ isMobile, isSmallMobile, shouldRenderAdBlock }) => {
  const UsedByScrollComponent = useMemo(() => (
    isMobile ? ScrollContainer : ScrollContainerWithButton
  ), [isMobile]);

  return (
    <PageLayout className={cx}>
      <IEWarningPopup />
      <MainBanner bg={<MainBannerBg />}>
        <MainBannerHeading className={cx('marb-m')} />
        <MainBannerText className={cx('marb-xs')} />
        {/* <MainBannerSmallText className={cx('marb-m')} /> */}
        <ActionButton className={cx('marr-m', 'marb-s')} />
        <ConsultationButton />
      </MainBanner>
      <Wrapper>
        <BlockMapper>
          {({ slug: blockSlug }) => (
            <div className={cx('marb-xxl', 'mart-l')}>
              <BlockHeading className={cx('marb-l')} />
              <BlockSubHeading className={cx('marb-l')} />
              <IfBlock slug={BlockSlugs.BENEFITS}>
                <Benefits isMobile={isMobile} slug={blockSlug} />
              </IfBlock>
              {shouldRenderAdBlock && (
                <IfBlock slug={BlockSlugs.CORPORATE_AD}>
                  <CorporateAd isMobile={isMobile} slug={blockSlug} />
                </IfBlock>
              )}
              <IfBlock slug={[BlockSlugs.DOCS, BlockSlugs.HOW_TO]}>
                <RGrid>
                  <ItemMapper>
                    {(_, index) => (
                      <RCol size={4} m={6} s={12} className={cx('padb-s')}>
                        <ItemBenefit icon={blockSlug === BlockSlugs.HOW_TO ? index + 1 : <PdfIcon />}>
                          <ItemTitle />
                          <BenefitExtra />
                        </ItemBenefit>
                      </RCol>
                    )}
                  </ItemMapper>
                </RGrid>
              </IfBlock>
              <IfBlock slug={BlockSlugs.TARIFFS}>
                <ItemMapper>
                  <Parameter value={<TariffPrice />}>
                    <ItemTitle />
                  </Parameter>
                </ItemMapper>
                {/*
                <BlockBenefit icon={<PdfIcon />} className={cx('padt-m')}>
                  <DetailedPriceText />
                  <DetailedPriceExtra />
                </BlockBenefit>
                */}
              </IfBlock>
              <IfBlock slug={BlockSlugs.EXAMPLES}>
                <RemoveWrapper>
                  <ExampleContainer
                    media={<ExampleBg />}
                    tabs={(
                      <ExampleAuthTabs>
                        <ItemMapper>
                          <ExampleAuthTabItem />
                        </ItemMapper>
                      </ExampleAuthTabs>
                    )}
                  >
                    <ExampleActiveStepProvider>
                      <ItemText className={cx('marb-m', { 'padt-m': isMobile })} />
                      <ExampleStepsMapper>
                        {({ text, title, isTiny }, index) => {
                          // For &nbsp;
                          // eslint-disable-next-line react/no-danger
                          const textFormatted = <span dangerouslySetInnerHTML={{ __html: text }} />;
                          return (
                            <ExampleStep
                              icon={index + 1}
                              className={cx('marb-m', 'mart-m')}
                              step={index}
                              title={title}
                              text={text}
                            >
                              {isTiny ? null : textFormatted}
                            </ExampleStep>
                          );
                        }}
                      </ExampleStepsMapper>
                      <ExampleOfferCheckbox className={cx('mart-l')} />
                      <Flex isInline isColumn={isSmallMobile} className={cx('mart-m')}>
                        <TryExampleButton />
                        <ExampleButtonInfo />
                      </Flex>
                      <ExampleSmallText className={cx('mart-m')} />
                    </ExampleActiveStepProvider>
                  </ExampleContainer>
                </RemoveWrapper>
              </IfBlock>
              <IfBlock slug={BlockSlugs.USED_BY}>
                <div className={cx('relative')}>
                  <UsedByScrollComponent>
                    <RGrid noWrap>
                      <ItemMapper>
                        <RCol size="*"><BusinessCard /></RCol>
                      </ItemMapper>
                    </RGrid>
                  </UsedByScrollComponent>
                </div>
              </IfBlock>
              <IfBlock slug={BlockSlugs.FAQ}>
                <ItemMapper>
                  <ConnectedFaqItem />
                </ItemMapper>
              </IfBlock>
              <BlockActionButton className={cx('mart-l', { 'marr-m': !isMobile })} />
              <BlockInstructionButton className={cx('mart-l')} />
            </div>
          )}
        </BlockMapper>
      </Wrapper>
    </PageLayout>
  );
};

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  isSmallMobile: PropTypes.bool,
  shouldRenderAdBlock: PropTypes.bool,
};


const mapStateToProps = (state) => ({
  isMobile: isScreenBelow800(state),
  isSmallMobile: isScreenBelow502(state),
  shouldRender: getPageReady(state),
  shouldRenderAdBlock: !!getCorporateAdTitle(state),
});

export default compose(
  connect(mapStateToProps),
  withReactModel(REACT_MODELS.common),
  withReactModel(getLocale() === 'en' ? REACT_MODELS.en : REACT_MODELS.ru),
  withRenderFlag,
)(MainPage, <Suspense />);
