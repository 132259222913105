export const stateValues = {
  Initial: 'initial',
  Pending: 'pending',
  Success: 'success',
  Error: 'error',
};


export const createStatefulActionTypes = name => ({
  SET_PENDING: `${name}::setPending`,
  SET_SUCCESS: `${name}::setSuccess`,
  SET_ERROR: `${name}::setError`,
  SET_INITIAL: `${name}::setInitial`,
});


export const createStatefulActions = (name) => {
  const actionTypes = createStatefulActionTypes(name);
  return {
    setPending: () => ({ type: actionTypes.SET_PENDING }),
    setSuccess: (data) => ({ type: actionTypes.SET_SUCCESS, data }),
    setError: (error) => ({ type: actionTypes.SET_ERROR, error }),
    setInitial: () => ({ type: actionTypes.SET_INITIAL }),
  };
};


export const createStatefulRequest = (name, request, { pending, success, error } = {}) =>
  (...args) => {
    const actions = createStatefulActions(name);

    return async (dispatch) => {
      dispatch((pending || actions.setPending)());
      try {
        const data = await request(...args);
        /* console.log(`Request ${name}:`, JSON.stringify(data, null, 2)); */
        return dispatch((success || actions.setSuccess)(data));
      } catch (err) {
        const errorMsg = err && err.response && err.response.data || 'Что-то пошло не так';
        /* console.log(`Request ${name}:`, JSON.stringify(err, null, 2)); */
        return dispatch((error || actions.setError)(errorMsg));
      }
    };
  };


export function createStatefulReducer(name) {
  const actionTypes = createStatefulActionTypes(name);
  const initialState = stateValues.Initial;

  return (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SET_PENDING:
        return stateValues.Pending;
      case actionTypes.SET_SUCCESS:
        return stateValues.Success;
      case actionTypes.SET_ERROR:
        return stateValues.Error;
      case actionTypes.SET_INITIAL:
        return stateValues.Initial;
      default:
        return state;
    }
  };
}

