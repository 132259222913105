import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { withRenderFlag } from 'enhancers';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FiltersComponent = ({ content, children, className }) => (
  <div className={cx('component', className)}>
    {content.map(children)}
  </div>
);

const mapStateToProps = (state, props) => ({
  shouldRender: props.content?.length > 0,
  isMobile: isScreenBelow800(state),
});

FiltersComponent.propTypes = {
  content: PropTypes.array,
  children: PropTypes.any,
  className: classNamePropTypes,
};

export const Filters = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(FiltersComponent);
