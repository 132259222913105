import React, { useState, useCallback, useRef, Children, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { isScreenBelow800, isScreenBelow1280 } from 'store/common/breakpoint/selectors';

import { getClientRect } from 'utils/dom-helpers';

import { useEventListener } from 'hooks/useEventListener';

import { FrameSwitcher } from '../FrameSwitcher';
import { Dots } from './components/Dots';
import { Controls } from './components/Controls';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DESKTOP_VISIBLE_FRAMES = 1;

const FrameSwitcherWrapper = ({ isMobile, isTablet, onChange, children, className }) => {
  const [activeFrame, setActiveFrame] = useState(0);
  const [offset, setOffset] = useState(0);
  const [frameWidth, setFrameWidth] = useState(0);

  const mappedChildren = Children.toArray(children);
  const childrenLength = Children.count(children);
  const amountOfVisibleFrames = childrenLength - DESKTOP_VISIBLE_FRAMES;
  const dotsAmount = Children.toArray(children)?.splice?.(0, amountOfVisibleFrames + 1);
  const showControls = childrenLength > DESKTOP_VISIBLE_FRAMES && !isTablet;
  const isLastFrame = activeFrame === childrenLength - 1;
  const isFirstFrame = activeFrame === 0;

  const frame = useRef(null);
  const touchPosition = useRef(0);
  const swipeDirection = useRef('');

  useEventListener('resize', () => {
    if (typeof window !== 'undefined') {
      setOffset(getClientRect(frame).width * Math.sign(offset));
      setFrameWidth(getClientRect(frame)?.width);
    }
  });

  useEffect(() => {
    onChange?.();
  }, [activeFrame]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setFrameWidth(getClientRect(frame)?.width);
    }
  }, []);

  const setNextFrame = useCallback(() => {
    const { width } = getClientRect(frame);

    if (isLastFrame || (!isMobile && activeFrame === amountOfVisibleFrames)) {
      setActiveFrame(0);
      setOffset(0);
      return;
    }

    setOffset((prevWidth) => prevWidth - width);
    setActiveFrame((frameId) => frameId + 1);
  }, [activeFrame, isLastFrame, isMobile, amountOfVisibleFrames]);

  const setPrevFrame = useCallback(() => {
    const { width } = getClientRect(frame);

    if (isFirstFrame) {
      if (isMobile) {
        setActiveFrame(childrenLength - 1);
        setOffset((childrenLength - 1) * -width);
        return;
      }

      setActiveFrame(amountOfVisibleFrames);
      setOffset(amountOfVisibleFrames * -width);
      return;
    }

    setOffset((prevWidth) => prevWidth + width);
    setActiveFrame((frameId) => frameId - 1);
  }, [childrenLength, isFirstFrame, isMobile, amountOfVisibleFrames]);

  const handleTouchStart = (e) => {
    touchPosition.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const swipeRange = Math.abs(e.changedTouches[0].clientX - touchPosition.current);

    if (swipeRange > 50) {
      if (e.changedTouches[0].clientX > touchPosition.current) {
        swipeDirection.current = 'right';
        return;
      }

      swipeDirection.current = 'left';
    }
  };

  const handleTouchEnd = () => {
    touchPosition.current = 0;

    if (swipeDirection.current === 'left') {
      setNextFrame();
    }

    if (swipeDirection.current === 'right') {
      setPrevFrame();
    }

    swipeDirection.current = '';
  };

  return (
    <div className={cx('wrapper')}>
      <FrameSwitcher
        frame={frame}
        offset={offset}
        mappedChildren={mappedChildren}
        handleTouchStart={handleTouchStart}
        handleTouchMove={handleTouchMove}
        handleTouchEnd={handleTouchEnd}
        className={className}
      />

      <Dots
        dots={isMobile ? mappedChildren : dotsAmount}
        activeFrame={activeFrame}
        setActiveFrame={setActiveFrame}
        setOffset={setOffset}
        width={frameWidth}
      />

      {showControls && <Controls setPrevFrame={setPrevFrame} setNextFrame={setNextFrame} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isScreenBelow800(state),
  isTablet: isScreenBelow1280(state),
});

FrameSwitcherWrapper.propTypes = {
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default connect(mapStateToProps)(FrameSwitcherWrapper);
