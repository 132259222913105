import { createReducer } from '@reduxjs/toolkit';

import { addFilter, removeFilter } from './actions';

const initialState = [];

export const filtersReducer = createReducer(initialState, {
  [addFilter.type]: (state, action) => [...state, action.payload],
  [removeFilter.type]: (state, action) => state.filter((filter) => filter.id !== action.payload.id),
});
