import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Heading, Text } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const BenefitItem = ({ imageSrc, title, text, isMobile, className }) => (
  <div className={cx('component', className, { isMobile })}>
    <div className={cx('icon', { isMobile })}>
      <img src={imageSrc} alt={title} />
    </div>
    <div className={cx('content')}>
      <Heading isMobile={isMobile} size={4} className={cx('title', { isMobile })}>{title}</Heading>
      <Text>{text}</Text>
    </div>
  </div>
);

BenefitItem.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};
