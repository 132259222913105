import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, FrameSwitcher } from 'components';
import { Video } from '../Video';

import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const VideosComponent = ({ videos, isMobile, className }) => {
  const handleChange = useCallback(() => {
    videos.map((video) => {
      const videoElement = document.getElementById(video.id);
      const isActive = videoElement.currentTime > 0
        && !videoElement.paused
        && !videoElement.ended
        && videoElement.readyState > 2;

      if (isActive) {
        videoElement.pause();
      }
    });
  }, [videos]);

  return (
    <Wrapper className={cx('component', className)}>
      <FrameSwitcher isMobile={isMobile} onChange={handleChange}>
        {videos.map((video, index) => (
          <Video key={index.toString()} {...video} />
        ))}
      </FrameSwitcher>
    </Wrapper>
  );
};

VideosComponent.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    src: PropTypes.string,
  })),
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};
