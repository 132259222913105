import { connect } from 'react-redux';

import { hideExampleAuthModal } from 'store/common/exampleAuth/mainPage/actions';
import {
  getExampleAuthModalButtonText, getExampleAuthModalHeading, getExampleAuthModalText,
} from 'store/common/exampleAuth/mainPage/selectors';
import { RButton, RHeading, RText } from '../../../Connected';


export const ExampleAuthModalHeading = connect((state) => ({
  size: 2,
  children: getExampleAuthModalHeading(state),
}))(RHeading);

export const ExampleAuthModalText = connect((state) => ({
  children: getExampleAuthModalText(state),
}))(RText);

export const ExampleAuthModalButton = connect((state) => ({
  children: getExampleAuthModalButtonText(state),
}), { onClick: hideExampleAuthModal })(RButton);
