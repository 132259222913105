import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getLocale } from 'requests/helpers';
import { AuthType } from 'store/common/exampleAuth/mainPage/constants';
import { SuperRetinaImage, ContentSwitcher } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const ExampleBgLayout = ({ slug, step, isMobile, className, withBlur, imageUrl }) => {
  const mediaName = slug === AuthType.FILL ? 'screen-fill' : `screen-${step + 1}`;
  const locale = getLocale();
  const imageSrc = imageUrl || `https://static.beeline.ru/upload/MobileID/images/b2b-landing/${locale || 'ru'}/examples/webp/${mediaName}.webp`;

  return (
    <div className={cx(isMobile ? 'mobile' : 'desktop', className)}>
      <div className={cx('phone', { 'with-blur': withBlur })}>
        <SuperRetinaImage
          src={`https://static.beeline.ru/upload/MobileID/images/b2b-landing/${locale || 'ru'}/examples/phone.png`}
          className={cx('screen')}
          alt={locale === 'en' ? 'Phone screen' : 'Экран телефона'}
        />
        <div className={cx('image')}>
          <div className={cx('imageIn')}>
            <ContentSwitcher secondaryIndex={slug === AuthType.FILL ? 9 : step}>
              <SuperRetinaImage src={imageSrc} alt={locale === 'en' ? 'Screen content' : 'Содержимое экрана'} />
            </ContentSwitcher>
          </div>
        </div>
      </div>
    </div>
  );
};

ExampleBgLayout.propTypes = {
  slug: PropTypes.string,
  step: PropTypes.number,
  isMobile: PropTypes.bool,
  withBlur: PropTypes.bool,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
};
