export const START_SCENARIO = 'Scenario::Start';
export const SAVE_DATA = 'Scenario::SaveData';

export const ORDER_NAME_FIELD = 'name';
export const ORDER_PHONE_FIELD = 'phone';
export const ORDER_EMAIL_FIELD = 'email';
export const ORDER_COMPANY_FIELD = 'company';
export const ORDER_WEBSITE_FIELD = 'siteAddress';
export const ORDER_CAPTCHA_FIELD = 'captcha';
export const ORDER_OFFER_FIELD = 'offer';

export const ERROR_REASON_CAPTCHA = 'need_captcha';
export const ERROR_REASON_UNKNOWN = 'unknown_reason';
