/* eslint-disable */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';


import { NestedDepthHelper, NestedContext } from './helpers';
import { convertListToObjectBy } from '../fn';
import { spreadLast } from '../arrays';


/**
 * A higher order createReduceSelector,
 * attaches reactContext to the result of the selector
 */
export const createContextSelector = (reactContext) =>
  spreadLast((allSelectors, combinerFn) => {
    /**
     * The result of this part gets memorised
     * it returns the global data object along with selector context
     */
    const memorizedSelector = createSelector(
      ...allSelectors,
      (...values) => {
        const nestedMapper = NestedDepthHelper.getNestedMapper(allSelectors);
        const selectResult = nestedMapper.deepMap(values, combinerFn);

        const ancestorContexts = NestedContext.collectContext(...values);
        NestedContext.bindContext(selectResult, ancestorContexts);
        if (reactContext) NestedContext.bindContext(selectResult, reactContext);
        return selectResult;
      },
    );

    /**
     * The result of this part gets called each time selector is used,
     * in different contexts it returns different parts of data
     */
    const unpackableSelector = (state) => {
      const unpackSelectResult = NestedContext.getDataByContextUnpacker(state);
      const originalState = NestedContext.withoutContextParser(state);
      const selectResult = memorizedSelector(originalState);
      return unpackSelectResult(selectResult);
    };


    const maxNestingDepth = NestedDepthHelper.maxDepth(allSelectors);
    NestedDepthHelper.setDepth(unpackableSelector, maxNestingDepth);

    /** bind context to selectors as well, to be able to extract them before running */
    const ancestorContexts = NestedContext.collectContext(...allSelectors);
    NestedContext.bindContext(unpackableSelector, ancestorContexts);

    if (reactContext) {
      NestedContext.bindContext(unpackableSelector, reactContext);
      NestedDepthHelper.incrementDepth(unpackableSelector);
    }

    return unpackableSelector;
  });


/**
 * Has exactly the same interface as `createSelector` from `reselect`
 * Differs in a way result is calculated:
 *  it takes nestedSelectors and applies combinerFn to every set of nested values
 *
 * For example: createReduceSelector(dictA, dictB, selA, selB, fn), turns into
 *   result[`id`] = createSelector(dictA[`id`], dictB[`id`], selA, selB, fn);
 */
export const createReduceSelector = createContextSelector();

export const connectNested = (contextArray, mapStateToProps, ...otherConnectArgs) => {
  const contextInjector = new NestedContext(contextArray || []);
  const contextBoundMapStateToProps = (state, ownProps) => {
    contextInjector.fillContextValues(ownProps);
    const richState = contextInjector.withContextParser(state);
    return mapStateToProps(richState, ownProps);
  };

  return compose(
    ...contextInjector.buildContextConsumers(),
    connect(contextBoundMapStateToProps, ...otherConnectArgs),
  );
};


/**
 * @param {Context} Context
 * @param {function} listSelector
 * @param {string} field
 */
export function composeContextSelectorGroup(Context, listSelector, field = 'id') {
  if (process.env.NODE_ENV !== 'production') {
    const { isContextConsumer } = require('react-is');

    const funcName = 'composeContextSelectorGroup';
    console.assert(isContextConsumer(<Context.Consumer />), `Expecting context as first argument to ${funcName}`);
    console.assert(typeof listSelector === 'function', `Expecting func as second argument to ${funcName}`);
    console.assert(typeof field === 'string', `Expecting string as third argument to ${funcName}`);
  }

  const contextConnect = (...args) => connectNested(
    [...NestedContext.collectContext(listSelector), Context],
    ...args
  );

  const getData = createContextSelector(Context)(
    listSelector,
    convertListToObjectBy(field),
  );
  return {
    connect: contextConnect,
    getIds: createSelector(
      listSelector,
      (list) => list.map((item) => item[field]),
    ),
    getData,
    getReduced: (combinerFn) => createReduceSelector(getData, combinerFn),
  };
}

