import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Tooltip } from '../Tooltip';
import { Text } from '../Text';
import CheckIcon from './assets/check.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function Checkbox(props) {
  const {
    children,
    name,
    value,
    isDisabled,
    onChange,
    className,
    info,
    isMobile,
    isWhite,
  } = props;

  const [id] = React.useState(`Checkbox${`${Math.random()}`.substr(2, 8)}`);

  return (
    <div className={cx('layout', { disabled: isDisabled, white: isWhite }, className)}>
      <input
        className={cx('absolute', 'field')}
        data-component="Checkbox"
        type="checkbox"
        id={id}
        checked={value}
        name={name}
        disabled={isDisabled}
        onChange={onChange}
      />
      <span className={cx('absolute', 'checkbox', { checked: value })}>
        <CheckIcon className={cx('icon', { checked: value })} />
      </span>
      {children ? (
        <label className={cx('label')} htmlFor={id}>
          <Text isInline>{children}</Text>
        </label>
      ) : null}
      {info ? (
        <Tooltip isMobile={isMobile} className={cx('tooltip')}>
          <Text>{info}</Text>
        </Tooltip>
      ) : null}
    </div>
  );
}

Checkbox.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  isMobile: PropTypes.bool,
  isWhite: PropTypes.bool,
  info: PropTypes.string,
};
