import PropTypes from 'prop-types';

import * as Blocks from 'store/page/blocks/selectors';
import { BlockSlugs } from 'store/constants';


function IfBlockLayout({ slug, currentSlug, children }) {
  const slugList = Array.isArray(slug) ? slug : [slug];
  return slugList.indexOf(currentSlug) !== -1 && children || null;
}

IfBlockLayout.propTypes = {
  slug: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(BlockSlugs)),
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(BlockSlugs))),
  ]),
  currentSlug: PropTypes.oneOf(Object.values(BlockSlugs)),
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  currentSlug: Blocks.getSlug(state),
});

export const IfBlock = Blocks.connect(mapStateToProps)(IfBlockLayout);
