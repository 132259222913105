import React from 'react';
import { useDrag } from 'react-use-gesture';


/**
 * Automatically binds gesture handler to domTarget
 */
const withGestureRef = originalGestureHook =>
  (handler, config) => {
    const bind = originalGestureHook(handler, config);
    React.useEffect(bind, [bind]);
  };


export const useDragRef = withGestureRef(useDrag);
