import React, { Suspense, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { initializeStore } from 'store';
import { checkAuthStatusAction } from 'store/options/actions';
import { withHMR, withReduxStore, withAdaptivity, withMeta, withPreloadMedia } from 'enhancers';
import withReactRouter from 'enhancers/withReactRouter';
import { breakpoints, setBreakpoint, getBreakpoint } from 'store/common/breakpoint';

import { BackgroundDrop } from 'components';
import { PageHeader, Suspense as Fallback, ErrorBoundary } from 'shared';
import { MainPage, CorporateAuthPage, SegmentPage, NotFoundPage } from '..';

const App = ({ checkAuthenticatedStatus }) => {
  useEffect(() => {
    checkAuthenticatedStatus();
  }, []);

  return (
    <Suspense fallback={<Fallback />}>
      <BackgroundDrop />
      <PageHeader />
      <ErrorBoundary>
        <Switch>
          <Route
            path={['/', '/en']}
            component={MainPage}
            exact
          />
          <Route
            path={['/corporate', '/corporate/en']}
            component={CorporateAuthPage}
            exact
          />
          <Route
            path="/segment/:id"
            component={SegmentPage}
            exact
          />
          <Route component={NotFoundPage} />
        </Switch>
      </ErrorBoundary>
    </Suspense>
  );
};

App.propTypes = { checkAuthenticatedStatus: PropTypes.func };

export default compose(
  withHMR(module),
  withReduxStore(initializeStore),
  withReactRouter(),
  withAdaptivity({ breakpoints, setBreakpoint, getBreakpoint }),
  connect(null, { checkAuthenticatedStatus: checkAuthStatusAction }),
  withPreloadMedia,
  withMeta,
)(App);
