import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';
import { EL } from 'analytics/constants';

import { getIsAuth, getIsEmployee } from 'store/options/selectors';
import { openPopup } from 'store/common/b2bAuth/actions';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { getLoginText } from 'store/common/b2bAuth/selectors';
import { setAuthModalSource } from 'store/options/actions';

import { Text } from 'components';
import ClientIcon from './assets/client.svg';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const LoginButtonComponent = ({
  openB2BModal,
  onSetModalSource,
  isAuthenticated,
  isEmployee,
  text,
  isMobile,
  className,
}) => {
  const handleClick = useCallback(() => {
    if (isEmployee) {
      window.location.href = '/b2b/employee/main';
      return;
    }

    if (isAuthenticated) {
      window.location.href = '/b2b/';
      return;
    }

    onSetModalSource(EL.header);
    openB2BModal();
  }, [isAuthenticated, isEmployee]);

  return (
    <div className={cx('component', className)} onClick={handleClick}>
      <ClientIcon className={cx('icon')} />

      {!isMobile && (
        <Text>
          {text}
        </Text>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isMobile: isScreenBelow800(state),
  isAuthenticated: getIsAuth(state),
  isEmployee: getIsEmployee(state),
  text: getLoginText(state),
});

const mapDispatchToProps = {
  openB2BModal: openPopup,
  onSetModalSource: setAuthModalSource,
};

LoginButtonComponent.propTypes = {
  openB2BModal: PropTypes.func,
  onSetModalSource: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  isEmployee: PropTypes.bool,
  isMobile: PropTypes.bool,
  text: PropTypes.string,
  className: classNamePropTypes,
};

export const LoginButton = connect(mapStateToProps, mapDispatchToProps)(LoginButtonComponent);
