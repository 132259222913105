import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { isScreenBelow1004 } from 'store/common/breakpoint/selectors';
import { getExampleAuthOpenFlag } from 'store/common/exampleAuth/mainPage/selectors';
import { isPopupOpen } from 'store/common/b2bAuth/selectors';

import { ErrorBoundary } from '@sentry/react';
import Footer from '../Footer';
import GlobalPreloader from '../GlobalPreloader';
import ExampleAuthModal from '../ExampleAuthModal';
import AuthViaB2BModal from '../AuthViaB2BModal';
import { ConsultationModal } from '../ConsultationModal';
import TelesalesResultModal from '../TelesalesResultModal';
import { ErrorModal } from '../ErrorModal';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


function PageLayout({ children, isAuthModalOpen, isMobile, isB2BModalOpen }) {
  return (
    <div className={cx('container', isMobile && commonStyles.mobile)}>
      <div className={cx('middle')}>
        <div className={cx('content')}>
          {children}
        </div>
      </div>
      <Footer />
      <ErrorBoundary fallback={<ErrorModal />}>
        {isAuthModalOpen && <ExampleAuthModal className={cx('modal')} />}
        {isB2BModalOpen && <AuthViaB2BModal />}
        <ConsultationModal />
        <TelesalesResultModal />
      </ErrorBoundary>
      <GlobalPreloader />
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node,
  isAuthModalOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  isB2BModalOpen: PropTypes.bool,
};


const mapStateToProps = (state) => ({
  isAuthModalOpen: getExampleAuthOpenFlag(state),
  isMobile: isScreenBelow1004(state),
  isB2BModalOpen: isPopupOpen(state),
});

export default connect(mapStateToProps)(PageLayout);
