import { useEffect } from 'react';

import { getMobileOS } from 'utils/detect-mobile';

export const useLockScroll = () => {
  useEffect(() => {
    const scrollingElement = document.scrollingElement || document.documentElement;
    const html = document.querySelector('html');
    const bodyScrollTop = scrollingElement.scrollTop;

    html.classList.add('fix-scroll');

    if (getMobileOS() === 'ios') {
      html.classList.add('fix-scroll-ios');
      document.body.scrollTop = bodyScrollTop;
    }

    return () => {
      html.classList.remove('fix-scroll', 'fix-scroll-ios');
      scrollingElement.scrollTop = bodyScrollTop;
    };
  }, []);
};
