import { blankObj } from 'utils/fn';
import { createStatefulActionTypes } from 'utils/redux-stateful';
import { SET_ORDER_FORM_VALUES } from './constants';
import { SAVE_DATA } from '../scenario/constants';

const initialState = {
  values: blankObj,
  reasonCode: null,
};

const { SET_INITIAL, SET_ERROR } = createStatefulActionTypes(SAVE_DATA);
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_FORM_VALUES:
      return { ...state, values: action.payload };
    case SET_ERROR:
      return { ...state, reasonCode: action.error.reasonCode };
    case SET_INITIAL:
      return { ...state, reasonCode: null };
    default:
      return state;
  }
}
