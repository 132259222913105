import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { animated, useSpring, config as springConfig } from 'react-spring/web.cjs';

import CustomPropTypes from '../../../../utils/prop-types';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


const fillRef = (ref, data) => {
  if (typeof ref === 'object') {
    ref.current = data; // eslint-disable-line no-param-reassign
  } else if (typeof ref === 'function') {
    ref(data);
  }
};

/**
 * A super simple spring-based ScrollContainer, that provides it`s interfaces via `controlsRef`
 */
export default function SpringContainer({ controlsRef, domNodeRef, className, ...otherProps }) {
  const [{ scroll }, startScroll, stop] = useSpring(() => ({
    config: {
      ...springConfig.slow,
      precision: 3,
    },
    scroll: 0,
  }));

  const getScroll = () => domNodeRef.current.scrollLeft;

  function scrollTo(position, { immediate = false, config = springConfig.slow } = {}) {
    return new Promise((resolve) => {
      startScroll({
        immediate,
        ...immediate ? {
          scroll: position,
        } : {
          to: async (next) => {
            await next({ scroll: position });
            resolve();
          },
          config,
          reset: true,
          from: { scroll: getScroll() },
        },
      });
    });
  }

  const stopScroll = () => stop(true);

  /** provide scrollContainer interface to the SpringContainer */
  fillRef(controlsRef, { scrollTo, stopScroll, getScroll });

  return (
    <animated.div
      {...otherProps}
      scrollLeft={scroll}
      className={cx('container', className)}
      ref={domNodeRef}
    />
  );
}

SpringContainer.propTypes = {
  /**
   * Spring way of passing controls up to another component,
   * this low level function returns three methods to control scroll:
   * - scrollTo, stopScroll and getScroll
   */
  controlsRef: CustomPropTypes.ref,

  /** common props */
  domNodeRef: CustomPropTypes.ref.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};
