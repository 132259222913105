import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { classNamePropTypes } from 'constants';

import { getExamplesHeading } from 'store/content/selectors';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { Wrapper, Heading } from 'components';
import { Image, Steps, ExampleAuthTabs, ExampleStep } from '../Connected';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const ExamplesComponent = ({ heading, isMobile, className }) => (
  <Wrapper className={cx('wrapper', className)}>
    <div className={cx('blur')} />

    <Heading className={cx('marb-m')} isMobile={isMobile}>
      {heading}
    </Heading>

    <div className={cx('component')}>
      <div className={cx('content', 'marr-xs')}>
        <ExampleAuthTabs />

        {isMobile && <Image className={cx('image')} />}

        <Steps>
          {(props) => (
            <ExampleStep className={cx('step')} {...props} />
          )}
        </Steps>
      </div>

      {!isMobile && <Image className={cx('image')} />}
    </div>
  </Wrapper>
);

const mapStateToProps = (state) => ({
  heading: getExamplesHeading(state),
  isMobile: isScreenBelow800(state),
});

ExamplesComponent.propTypes = {
  heading: PropTypes.string,
  isMobile: PropTypes.bool,
  className: classNamePropTypes,
};

export const Examples = connect(mapStateToProps)(ExamplesComponent);
