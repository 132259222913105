const valignToClassBindings = {
  top: null,
  middle: 'valignMiddle',
  bottom: 'valignBottom',
};

export const mapValignToClass = key => (key in valignToClassBindings ? valignToClassBindings[key] : null);


const gutterToClassBindings = {
  collapse: 'gutterCollapse',
  small: 'gutterSmall',
  medium: 'gutterMedium',
  large: null,
};

const isProperGutterType = key => key in gutterToClassBindings;

const mapGutterToClass = key => gutterToClassBindings[key];


/**
 * returns { className, style } pair for given gutter
 */
export const getGutterData = (gutter) => {
  if (isProperGutterType(gutter)) {
    return {
      className: mapGutterToClass(gutter),
    };
  }
  return {
    gridStyle: { marginLeft: `-${gutter / 2}px`, marginRight: `-${gutter / 2}px` },
    colStyle: { paddingLeft: `${gutter / 2}px`, paddingRight: `${gutter / 2}px` },
  };
};
