import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import commonStyles from 'styles/common.pcss';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { Wrapper } from 'components/Wrapper';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


function MainBannerLayout({ children, top, bg, isMobile, className }) {
  return (
    <div className={cx('container', { mobile: isMobile }, className)}>
      <div className={cx('top')}>
        {top}
      </div>
      <div className={cx('content', 'padt-xl', 'padb-xl')}>
        <Wrapper>
          <div className={cx('contentIn')}>
            {children}
          </div>
        </Wrapper>
      </div>
      <div className={cx('background')}>
        {bg}
      </div>
    </div>
  );
}

MainBannerLayout.propTypes = {
  children: PropTypes.node,
  top: PropTypes.node,
  bg: PropTypes.node,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isMobile: isScreenBelow800(state),
});

export const MainBanner = connect(mapStateToProps)(MainBannerLayout);
