export const nbsp = '\u00A0';

/**
 * splits price value into three-digit groups
 * */

export const formatPrice = (value, precision) => (
  typeof value === 'number' ? (
    value.toFixed(precision)
      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1 ')
      .replace('.', ',')
  ) : ''
);

export const formatRub = (value) => {
  if (typeof value === 'string') return value;

  return `${formatPrice(value, 2)} ₽`;
};

const phonePattern = '+7 ___ ___ __ __';
export function formatPhone(phone, pattern = phonePattern) {
  let ind = 0;
  // eslint-disable-next-line no-plusplus
  return pattern.replace(/_/g, () => phone[ind++] || '*');
}

export const postfixImageName = (src, postfix) => {
  if (!src) return;

  return src.replace(/\.(jpg|webp|jpeg|png|gif)$/, `${postfix}.$1`);
};

/**
 * Takes a template like "Age: {{age}}" and replaces {{age}} on values
 * from the object `vars` with the appropriate fields
 * e.g.
 * templateParser('Age: {{age}}', { age: 21 }) => 'Age: 21'
 * @param {string} string
 * @param {object} vars
 */
export const templateParser = (string, vars) => {
  if (!string) return '';
  if (!vars) return string;
  return string.replace(/{{(\w+)}}/ig, (full, match) => vars[match]);
};
