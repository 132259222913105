import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function Preloader({ className }) {
  return (
    <div className={cx('component', className)} />
  );
}

Preloader.propTypes = {
  className: PropTypes.string,
};
