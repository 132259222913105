import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { getLocale } from 'requests/helpers';

import Arrow from './arrow.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);


export const ScrollButton = ({ onClick, right, left }) => (
  <button
    className={cx('component', { right, left, absolute: right || left })}
    onClick={onClick}
    aria-label={getLocale() === 'en' ? 'Next' : 'Дальше'}
  >
    <Arrow />
  </button>
);

ScrollButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  right: PropTypes.bool,
  left: PropTypes.bool,
};
