import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

export const Benefit = ({ text, imageUrl, className }) => (
  <div className={cx('component', className)}>
    <div className={cx('image', 'marb-xxs')} style={{ backgroundImage: `url(${imageUrl})` }} />
    {/*<img className={cx('image', 'marb-xxs')} src={imageUrl} />*/}

    <Text>
      {text}
    </Text>
  </div>
);

Benefit.propTypes = {
  text: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.any,
};

