import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { getMainBannerBg } from 'store/page/selectors';

import { Blur } from './Blur';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Mobile = ({ bgUrl, className }) => <img className={cx('image', className)} src={bgUrl} alt="" />;

Mobile.propTypes = {
  bgUrl: PropTypes.string,
  className: PropTypes.any,
};

const Desktop = ({ bgUrl, className }) => (
  <div className={cx('background', className)} style={{ backgroundImage: `url('${bgUrl}')` }} />
);

Desktop.propTypes = {
  bgUrl: PropTypes.string,
  className: PropTypes.any,
};

const mapStateToProps = (state) => ({
  bgUrl: getMainBannerBg(state),
});

export const MainBannerBg = ({ blur = <Blur />, children }) => (
  <>
    {blur}
    {children}
  </>
);

MainBannerBg.Mobile = connect(mapStateToProps)(Mobile);
MainBannerBg.Desktop = connect(mapStateToProps)(Desktop);
