const PROD = 'mobileid.beeline.ru';
const UAT = 'mobileiduat.beeline.ru';
const LOCALHOST = 'localhost:8080';

const ENV_DICTIONARY = {
  [PROD]: 'production',
  [UAT]: 'uat',
  [LOCALHOST]: 'development',
};

const isProduction = process.env.NODE_ENV !== 'development';

export const sentryEnv = ENV_DICTIONARY[window.location.host];
export const sentryRelease = isProduction ? '' : 'unreleased';
