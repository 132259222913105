import React from 'react';
import classNames from 'classnames/bind';

import { BenefitHeading } from '../../../Connected';
import { BenefitsContainer } from '../BenefitsContainer';
import { ItemMapper } from '../../../ItemMapper';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const SlidersView = () => (
  <div className={cx('container')}>
    <ItemMapper>
      {(_, index) => (
        <BenefitsContainer
          key={index.toString()}
          title={<BenefitHeading />}
        />
      )}
    </ItemMapper>
  </div>
);
