import React from 'react';
import PropTypes from 'prop-types';

import { useDragRef } from 'utils/react-spring';
import withScrollClickSuppression from './withScrollClickSuppression';


const RELEASE_VELOCITY = 300;


/**
 * HOC that enables drag behavior for scroll container
 */
export default function withDragScroll(WrappedScrollContainer) {
  function DragScrollContainer({
    UNSAFE_onScrollRelease, UNSAFE_onScrollEnd, UNSAFE_onScrollStart, UNSAFE_onScroll, // eslint-disable-line camelcase
    controlsRef, domNodeRef, ...otherProps
  }) {
    /** decipher controls */
    const scrollTo = (...args) => controlsRef.current.scrollTo(...args);
    const stopScroll = () => controlsRef.current.stopScroll();
    const getScroll = () => controlsRef.current.getScroll();
    const isTouchDevice = React.useRef(false);

    /** Attach drag handler */
    useDragRef(({ first, last, movement, vxvy, memo: initialScroll = getScroll() }) => {
      const currentScroll = initialScroll - movement[0];

      function callScrollHandler(scrollHandler) {
        if (scrollHandler) scrollHandler({ movement, vxvy, current: currentScroll, initial: initialScroll });
      }

      if (!isTouchDevice.current) {
        if (last) {
          if (vxvy[0]) {
            /** simulate momentum scroll */
            scrollTo(currentScroll - vxvy[0] * RELEASE_VELOCITY);
            callScrollHandler(UNSAFE_onScrollRelease);
          } else {
            callScrollHandler(UNSAFE_onScrollEnd);
          }
        } else {
          scrollTo(currentScroll, { immediate: true });

          if (first) {
            callScrollHandler(UNSAFE_onScrollStart);
          } else {
            callScrollHandler(UNSAFE_onScroll);
          }
        }
      }

      return initialScroll;
    }, { domTarget: domNodeRef });

    /** cancel active dragScroll when user uses manual scroll */
    const handleHorizontalWheel = (event) => (Math.abs(event.deltaX) > 2 ? stopScroll() : null);
    /** disable click/drag behavior entirely if touch events are supported */
    const handleTouchStart = () => { isTouchDevice.current = true; };
    /** prevent drag from firing for images */
    const handleDragStart = (event) => event.preventDefault();

    return (
      <WrappedScrollContainer
        {...otherProps}
        controlsRef={controlsRef}
        domNodeRef={domNodeRef}
        onMouseDown={stopScroll}
        onWheel={handleHorizontalWheel}
        onTouchStart={handleTouchStart}
        onDragStart={handleDragStart}
      />
    );
  }


  DragScrollContainer.propTypes = {
    ...WrappedScrollContainer.propTypes,

    /** settings props */
    // isLocked: PropTypes.bool,
    // isDraggable: PropTypes.bool,

    /**
     * Following handlers only work with drag behavior,
     * and may change in the future - use at your own risk
     */
    UNSAFE_onScroll: PropTypes.func,
    UNSAFE_onScrollStart: PropTypes.func,
    UNSAFE_onScrollRelease: PropTypes.func,
    UNSAFE_onScrollEnd: PropTypes.func,
  };

  if (process.env.NODE_ENV !== 'production') {
    DragScrollContainer.displayName = 'DragScrollContainer';
  }

  return withScrollClickSuppression(DragScrollContainer);
}
