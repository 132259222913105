import React, { useState, useCallback } from 'react';
import { ScrollButton } from '../views';

const POSITION = {
  start: 0,
  center: 1,
  end: 2,
};

export function withScrollButton(WrappedComponent) {
  function WithScrollButton(props) {
    const [position, setPosition] = useState(POSITION.start);
    const scrollControls = React.useRef(null);
    const scrollElement = React.useRef(null);

    const scrollContainer = useCallback((direction = 1) => () => {
      scrollControls.current.scrollTo(scrollElement.current.scrollLeft + window.innerWidth * 0.8 * direction);
    }, [scrollControls, scrollElement]);

    const handleScroll = useCallback(({ target }) => {
      let nextPosition = position;
      const { scrollLeft, scrollWidth } = scrollElement.current;

      if (target.scrollLeft < 10) {
        nextPosition = POSITION.start;
      } else if (scrollWidth - scrollLeft - window.innerWidth < 10) {
        nextPosition = POSITION.end;
      } else {
        nextPosition = POSITION.center;
      }

      if (nextPosition !== position) {
        setPosition(nextPosition);
      }
    }, [scrollElement, position]);

    return (
      <>
        {position !== POSITION.start && (
          <ScrollButton onClick={scrollContainer(-1)} left />
        )}
        <WrappedComponent
          {...props}
          controlsRef={scrollControls}
          domNodeRef={scrollElement}
          onScroll={handleScroll}
        />
        {position !== POSITION.end && (
          <ScrollButton onClick={scrollContainer()} right />
        )}
      </>
    );
  }

  WithScrollButton.propTypes = {
    ...WrappedComponent.propTypes,
  };

  return WithScrollButton;
}

