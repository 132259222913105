import React from 'react';


/**
 * Uses ref that is passed down as first argument or creates a new one if there is none
 * @param existingRef {React.MutableRefObject}
 * @param initialValue
 */
export const useExistingRef = (existingRef, initialValue) => {
  const newRef = React.useRef(initialValue);
  return existingRef || newRef;
};
