import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { getIsSegmentsMenuOpen } from 'store/common/menu';
import { getSegmentsButtonIcon, getSegmentsButtonText } from 'store/content';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { Text } from 'components';
import Arrow from './arrow.svg';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const ButtonComponent = ({ isOpen, isMobile, iconSrc, text, onClick, className }) => (
  <button
    type="button"
    className={cx('component', className)}
    onClick={onClick}
    aria-label={text}
  >
    {iconSrc && <img src={iconSrc} alt="" className={cx('marr-xxs')} />}

    {!isMobile && (
      <>
        <Text>
          {text}
        </Text>

        <Arrow className={cx('arrow', { open: isOpen })} />
      </>
    )}
  </button>
);

const mapStateToProps = (state) => ({
  isOpen: getIsSegmentsMenuOpen(state),
  isMobile: isScreenBelow800(state),
  iconSrc: getSegmentsButtonIcon(state),
  text: getSegmentsButtonText(state),
});

ButtonComponent.propTypes = {
  isOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  iconSrc: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: classNamePropTypes,
};

export const Button = connect(mapStateToProps)(ButtonComponent);
