import { OPEN_POPUP, CLOSE_POPUP } from 'store/common/b2bAuth/constants';

const initialState = {
  isOpen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_POPUP: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case CLOSE_POPUP: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default:
      return state;
  }
}
