import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { REACT_MODELS } from 'constants';

import { getLocale } from 'requests/helpers';

import { getSharedComponentsReady } from 'store/common/selectors';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { withRenderFlag, withReactModel } from 'enhancers';

import { Button, Heading, Text } from 'components';
import { Suspense } from '../Suspense';
import PageLayout from '../PageLayout';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const _ErrorTemplate = ({ content, isMobile }) => {
  const onButtonClick = useCallback(() => {
    window.location.href = getLocale() === 'en' ? '/en' : '/';
  }, []);

  return (
    <PageLayout>
      <div className={cx('wrapper')}>
        <div className={cx('component')}>
          <Heading className={cx('heading')} isMobile={isMobile}>
            {content.heading}
          </Heading>

          <Text className={cx('text')}>
            {content.text}
          </Text>

          <Button isOnlyText className={cx('mart-m')} onClick={onButtonClick}>
            {content.buttonText}
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  shouldRender: getSharedComponentsReady(state),
  isMobile: isScreenBelow800(state),
});

_ErrorTemplate.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    text: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
};

export const ErrorTemplate = compose(
  connect(mapStateToProps),
  withReactModel(REACT_MODELS.common),
  withRenderFlag,
)(_ErrorTemplate, <Suspense />);
