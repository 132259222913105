import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { getPaginationShowMoreButton } from 'store/content';

import { templateParser } from 'utils/formatting';

import { withRenderFlag } from 'enhancers';

import { Button } from '../../../Button';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ShowMoreButtonComponent = ({ onClick, amountOfItemsLeft, text, className }) => (
  <Button
    className={cx('component', className)}
    onClick={onClick}
    isDark
    isOnlyText
    isWide
  >
    {templateParser(text, { number: amountOfItemsLeft })}
  </Button>
);

const mapStateToProps = (state) => ({
  text: getPaginationShowMoreButton(state),
});

ShowMoreButtonComponent.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  amountOfItemsLeft: PropTypes.number,
  className: classNamePropTypes,
};

export const ShowMoreButton = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(ShowMoreButtonComponent);
