import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { GridContext } from './context';
import { getGutterData } from './helpers';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


/**
 * Col represents adaptive 12-grid-layout column, adaptivity is controled by size array
 *
 */
export function Grid({ children, noWrap, gutter, className }) {
  const gutterData = getGutterData(gutter);
  return (
    <div className={cx('container', gutterData.className, { noWrap }, className)} style={gutterData.gridStyle}>
      <GridContext.Provider value={gutterData}>
        {children}
      </GridContext.Provider>
    </div>
  );
}

Grid.defaultProps = {
  gutter: 'medium',
};

Grid.propTypes = {
  children: PropTypes.node,
  noWrap: PropTypes.bool,
  gutter: PropTypes.oneOfType([
    PropTypes.oneOf(['collapse', 'small', 'medium', 'large']),
    PropTypes.number,
  ]),
  className: PropTypes.string,
};
