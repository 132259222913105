import { authMobileIdRequest, getAuthMobileIdStatusRequest } from 'requests/api/auth';
import { AUTH_MOBILE_ID_STATUSES } from 'constants';

export const startMobileIdAuth = ({ phone }) => (
  new Promise((resolve, reject) => {
    authMobileIdRequest({ phone })
      .then(({ operationId, timeSeconds }) => {
        const start = Date.now();
        const maxWaitingTime = timeSeconds * 1000;
        const checkStatus = () => {
          getAuthMobileIdStatusRequest({ operationId })
            .then((status) => {
              const diff = Date.now() - start;
              if (status === AUTH_MOBILE_ID_STATUSES.retry && diff < maxWaitingTime) {
                setTimeout(checkStatus, 1000);
              } else if (status === AUTH_MOBILE_ID_STATUSES.success) {
                resolve();
              } else {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject(status === AUTH_MOBILE_ID_STATUSES.pushDenied ? status : AUTH_MOBILE_ID_STATUSES.timeout);
              }
            })
            .catch(reject);
        };
        setTimeout(checkStatus, 2500);
      })
      .catch(reject);
  })
);
