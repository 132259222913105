import { composeContextSelectorGroup, createReduceSelector } from 'utils/redux';
import { BlockContext } from './context';
import { getPageBlocksList } from '../selectors';


export const { connect, getReduced } = composeContextSelectorGroup(
  BlockContext,
  getPageBlocksList,
  'slug',
);

export const getSlug = getReduced(data => data.slug);
export const getHeading = getReduced(data => data.heading);
export const getSubHeading = getReduced(data => data.subHeading);
export const getItems = getReduced(data => data.list || []);
export const getMoreLinkTitle = getReduced(data => data.moreTitle);
export const getMoreLinkExtra = getReduced(data => data.moreExtra);
export const getButtonText = getReduced(data => data.buttonText);
export const getButtonAlt = getReduced(data => data.buttonAlt);
export const getOfferText = getReduced(data => data.offerText);
export const getSmallText = getReduced(data => data.smallText);
export const hasButton = getReduced(data => data.hasButton);
export const getAttachmentUrl = getReduced(data => data.attachmentUrl);

export const getItemSlugs = createReduceSelector(getItems, list => list.map(item => item.slug));

export const getItemsDesktopViewTitles = createReduceSelector(getItems, list => list.map(el => el.title));
export const getItemsDesktopViewCards = createReduceSelector(getItems, benefitsItems => {
  // benefitsItems - [{},{}]
  const cardsDesktopView = []; // [[{},{}],[{},{}]]
  const cardsList = benefitsItems.map(el => el.list); // [[], [], []]
  const cardsListMaxLength = Math.max.apply(null, benefitsItems?.map(el => el?.list?.length));

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cardsListMaxLength; i++) {
    const card = cardsList.map(el => el[i]);
    cardsDesktopView.push(card);
  }

  return cardsDesktopView;
});
