import { createReducer } from '@reduxjs/toolkit';
import { toggleConsultationPopup, setFormValues } from './actions';

const initialState = {
  isOpen: null,
  formValues: {
    agreement: true,
  },
};

export const consultationReducer = createReducer(initialState, {
  [toggleConsultationPopup.type]: (state, action) => { state.isOpen = action.payload; },
  [setFormValues.type]: (state, action) => { state.formValues = action.payload; },
});
