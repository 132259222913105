import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from 'utils/prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export default function ContentFrame({ children, nodeRef, style, className }) {
  return (
    <div className={cx('component', className)} style={style} ref={nodeRef}>
      {children}
    </div>
  );
}

ContentFrame.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,

  /** is not an actual prop */
  nodeRef: CustomPropTypes.ref,
};
