import React from 'react';
import { useRequest } from 'ahooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { FORM_NAMES } from 'constants';

import { RHeading } from '../Connected';
import { BasePopupStateless, Text, Button, LinkBlock, Preloader, ErrorBlock } from 'components';
import { FFCheckbox, FFPhoneInput, FFTextInput, FinalForm } from 'components/FinalForm';

import { withRenderFlag } from 'enhancers';
import { closeConsultationPopup, setFormValues } from 'store/common/consultation/actions';
import { getFormValues, getIsOpenPopup, getPopupContent } from 'store/common/consultation/selectors';
import { openTelesalesPopup } from 'store/common/telesalesResult/actions';

import { getValidators } from './validators';
import { sendConsultationRequest } from 'requests/api';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const ConsultationModalComponent = ({
  closePopup,
  openTelesalesResult,
  validationMsg,
  onFormChange,
  formValues,
  title,
  description,
  fields,
  buttonText,
}) => {
  const { loading, error, run: submit } = useRequest(sendConsultationRequest, {
    manual: true,
    onSuccess: () => {
      closePopup();

      openTelesalesResult();
    },
  });

  const onSubmit = React.useCallback(({ fullName, ctn, email }) => submit({
    email,
    fullName,
    msisdn: `7${ctn}`,
  }), []);

  return (
    <BasePopupStateless isOpen onClose={closePopup} className={cx('popup')}>
      <section className={cx('content-container')}>
        <RHeading className={cx('marb-s')}>
          {title}
        </RHeading>
        <Text>
          {description}
        </Text>

        <div className={cx('form-wrapper', 'mart-m')}>
          <FinalForm
            onSubmit={onSubmit}
            values={formValues}
            onChange={onFormChange}
            getValidators={getValidators(validationMsg)}
            name={FORM_NAMES.freeConsultation}
          >
            <FFTextInput
              isColumn
              className={cx('mart-s')}
              {...fields.fullName}
            />

            <FFPhoneInput
              isColumn
              className={cx('mart-s')}
              {...fields.ctn}
            />

            <FFTextInput
              isColumn
              className={cx('mart-s')}
              {...fields.email}
            />

            <FFCheckbox name={fields.agreement.name} className={cx('left', 'mart-s', 'marb-s')}>
              <LinkBlock href={fields.agreement.href}>
                <Text isInline isSmall isSecondary>
                  {fields.agreement.label}
                </Text>
              </LinkBlock>
            </FFCheckbox>

            {error && (
              <ErrorBlock className={cx('marb-s')}>
                {validationMsg.request}
              </ErrorBlock>
            )}

            {loading ? (
              <Preloader className={cx('loader')} />
            ) : (
              <Button isSubmit>
                {buttonText}
              </Button>
            )}
          </FinalForm>
        </div>
      </section>
    </BasePopupStateless>
  );
};

const mapStateToProps = (state) => ({
  ...getPopupContent(state),
  formValues: getFormValues(state),
  shouldRender: getIsOpenPopup(state),
});

const mapDispatchToProps = {
  onFormChange: setFormValues,
  closePopup: closeConsultationPopup,
  openTelesalesResult: openTelesalesPopup,
};

ConsultationModalComponent.propTypes = {
  closePopup: PropTypes.func,
  onFormChange: PropTypes.func,
  openTelesalesResult: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  fields: PropTypes.object,
  formValues: PropTypes.object,
  validationMsg: PropTypes.object,
};

export const ConsultationModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRenderFlag(ConsultationModalComponent));
