import { useCallback, useRef, useState } from 'react';

import { useEventListener } from 'hooks';

export const useVideo = () => {
  const video = useRef(null);
  const [isPlaying, setPlaying] = useState(false);

  const handleChange = useCallback((e) => {
    if (e.type === 'play') {
      setPlaying(true);
    }

    if (e.type === 'pause') {
      setPlaying(false);
    }
  }, []);

  const startPlaying = useCallback(() => video.current.play(), []);

  useEventListener('play', handleChange, video);
  useEventListener('pause', handleChange, video);

  return {
    ref: video,
    isPlaying,
    startPlaying,
  };
};
