/**
 * NOTE: when using along with `withIcon`, `withIcon` must be used above `withMask`,
 * or isClearable must be defined in resulting component,
 * otherwise issues with empty input with clear button will appear
 */
import React from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from 'utils/prop-types';
import { isNullOrUndefined } from 'utils/fn';
import {
  getCursorPosition, setCursorPosition, getCursorSelectionLength, getPositionOfOccurrence, valueToString,
} from './helpers';

const KEYBOARD_BACKSPACE_KEYCODE = 8;


/**
 * Simple MaskedInput handles mask properly
 * while passing all the props freely as if element is an input
 */
export function withMask(WrappedInput) {
  class MaskedInput extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isFocused: false,
      };

      /**
       * current and prev mask are used to properly manage unmasking,
       * it doesn't literally affect component, thus is not in state
       */
      this.currentMaskedValue = this.getMaskedValue();
      this.prevMaskedValue = '';
      this.prevMask = this.getMask();
      this.domNodeRef = React.createRef();
      /**
       * next cursor buffers position to be fired on componentDidUpdate
       */
      this.nextMaskIndex = -1;
    }

    componentDidUpdate(prevProps, prevState) {
      this.prevMaskedValue = this.currentMaskedValue;

      if (this.nextMaskIndex !== -1) {
        this.setCursorOnPlaceholder(this.nextMaskIndex);
        this.nextMaskIndex = -1;
      } else if (prevState.isFocused === false && this.state.isFocused && !this.shouldShowPlaceholder()) {
        /** when we focus empty field, initial mask content appears and we need to move cursor after it */
        requestAnimationFrame(() => this.setCursorPosition((this.getInputElement().value || '').length));
      }
    }

    getValueString = () => valueToString(this.props.value);

    getInputElement = () => (this.props.domNodeRef || this.domNodeRef).current;

    setCursorPosition = caretPos => setCursorPosition(this.getInputElement(), caretPos);

    getCursorPosition = () => getCursorPosition(this.getInputElement());

    getCursorSelectionLength = () => getCursorSelectionLength(this.getInputElement());

    /**
     * set cursor on masks `revIndex`th placeholder from the end
     * if before is true, cursor will be set before given placeholder
     */
    setCursorOnPlaceholder = (number) => {
      const mask = this.getMask();

      /** apply this hack if i want to move cursor to end when typing */
      if (number === 0) {
        /** if at first character, just set cursor at the first writable character */
        this.setCursorPosition(mask.indexOf('_'));
      } else if (number >= this.getValueString().length) {
        /** if at the end, set it to the end */
        this.setCursorPosition(this.getInputElement().value.length);
      } else {
        /** otherwise set it up to the place */
        const caretPos = getPositionOfOccurrence(mask, '_', number - 1);
        this.setCursorPosition(caretPos + 1);
      }
    };

    /**
     * We show placeholder if it is not null, value is empty and input is not focused
     */
    shouldShowPlaceholder() {
      const { value, placeholder } = this.props;
      const { isFocused } = this.state;
      return isNullOrUndefined(value) && !isFocused && typeof placeholder === 'string';
    }

    getMask = (value) => {
      const { mask, unmaskValue } = this.props;
      const nextValue = unmaskValue(isNullOrUndefined(value) ? this.getValueString() : value);
      return typeof mask === 'function' ? mask(nextValue, this.prevMaskedValue) : mask;
    };

    getMaskedValue = () => {
      const mask = this.getMask();
      const value = this.getValueString();
      let maskedValue = '';
      let valueIndex = 0;
      let caretPos;
      for (caretPos = 0; caretPos < mask.length; caretPos += 1) {
        if (mask[caretPos] === '_') {
          if (valueIndex >= value.length) break;
          maskedValue += value[valueIndex];
          valueIndex += 1;
        } else {
          maskedValue += mask[caretPos];
        }
      }
      return maskedValue;
    };

    handleChange = (_, event) => {
      const { onChange, unmaskValue } = this.props;
      let maskedValue = event.target.value;
      const mask = this.getMask(unmaskValue(maskedValue, this.prevMaskedValue));
      let cursor = this.getCursorPosition();

      /**
       * Backspace requires additional processing if original event only erased part of the mask, not value
       * we also need to make sure selection was empty, otherwise only selection needs to erased
       */
      const shouldProcessBackspace = (
        this.lastActionBackspace &&
        this.lastActionHasSelection &&
        this.prevMask[cursor] !== '_'
      );
      this.lastActionBackspace = false;
      this.lastActionHasSelection = false;

      if (shouldProcessBackspace) {
        const prevCursorPos = this.prevMask.lastIndexOf('_', cursor);

        if (prevCursorPos !== -1) {
          /** erase closest placeholder symbol, and move cursor left */
          maskedValue = maskedValue.slice(0, prevCursorPos) + maskedValue.slice(prevCursorPos + 1);
          cursor -= 1;
        } else {
          /** if we are trying to erase prefix, we prevent default behavior */
          requestAnimationFrame(() => this.setCursorPosition(cursor + 1));
          return;
        }
      }

      /**
       * mask defines maxLength
       */
      const value = unmaskValue(maskedValue, this.prevMaskedValue)
        .substring(0, mask.replace(/[^_]/g, '').length);

      this.currentMaskedValue = maskedValue;
      this.prevMask = mask;

      /**
       * determine maskIndex position for next tick by counting number of characters after unmask
       */
      this.nextMaskIndex = unmaskValue(maskedValue.slice(0, cursor), this.prevMaskedValue).length;

      if (onChange) onChange(value, event);
    };

    /**
     * input event used in par with keyDown for cross browser detection of pressed key
     * since Android 4 devices with T9 on throw *dead* key `229` instead of proper keyCode for backspace
     */
    handleInput = (event) => {
      const { onInput } = this.props;

      if (event.nativeEvent && event.nativeEvent.inputType === 'deleteContentBackward') {
        this.lastActionBackspace = true;
      }

      if (onInput) onInput(event);
    };

    handleKeyDown = (event) => {
      const { onKeyDown } = this.props;
      this.lastActionHasSelection = !this.getCursorSelectionLength();

      if (event.keyCode === KEYBOARD_BACKSPACE_KEYCODE) {
        this.lastActionBackspace = true;
      }
      if (onKeyDown) onKeyDown(event);
    };

    /**
     * we have to override onFocus and onBlur,
     * normally they fire with event as first argument,
     * we add value as second
     */
    handleFocus = (_, event) => {
      const { onFocus, value } = this.props;
      if (onFocus) onFocus(value, event);
      this.setState({ isFocused: true });
    };

    handleBlur = (_, event) => {
      const { onBlur, value } = this.props;
      if (onBlur) onBlur(value, event);
      this.setState({ isFocused: false });
    };

    render() {
      const { value, mask, unmaskValue, isClearable, domNodeRef, ...otherProps } = this.props;
      return (
        <WrappedInput
          {...otherProps}
          value={this.shouldShowPlaceholder() ? '' : this.getMaskedValue()}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          onInput={this.handleInput}
          domNodeRef={domNodeRef || this.domNodeRef}
          /** if isClearable is true, we show clear button only when value is not empty */
          {...typeof isClearable !== 'undefined' ? { isClearable: isClearable && !!value } : {}}
        />
      );
    }
  }

  MaskedInput.propTypes = {
    ...WrappedInput.propTypes,

    value: PropTypes.string,

    /** string with '_' placeholders or a function that takes unmaskedValue and returns mask */
    mask: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    /**
     * fn to clean mask constants out of value
     * takes two arguments - (maskedValue, prevMaskedValue)
     * returns value or an array [value, cursor]
     */
    unmaskValue: PropTypes.func.isRequired,

    /** default props of input dom element */
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onInput: PropTypes.func,

    /** is not an actual prop */
    domNodeRef: CustomPropTypes.ref,

    /** CrossProps, these props will be here if we wrap withIcon around our MaskedInput */
    isClearable: PropTypes.bool,
  };

  MaskedInput.defaultProps = {
    ...WrappedInput.defaultProps,

    /**
     * if placeholder is null, we show part of mask instead,
     * if its a string, even empty, we show placeholder
     */
    placeholder: '',
  };


  /**
   * Override component name by prepending `Masked~`
   * to make it look nice, for example: `MaskedTextInput`
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedInput.displayName || WrappedInput.name || 'Input';
    MaskedInput.displayName = `Masked${WrappedComponentName}`;
  }

  return MaskedInput;
}
