import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import * as BlockItem from 'store/page/blockItems/selectors';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


function BusinessCardLayout({ imageUrl, alt, className }) {
  return (
    <img src={imageUrl} className={cx('component', className)} alt={alt} />
  );
}

BusinessCardLayout.propTypes = {
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  imageUrl: BlockItem.getImageUrl(state),
  alt: BlockItem.getTitle(state),
});

export const BusinessCard = BlockItem.connect(mapStateToProps)(BusinessCardLayout);
