import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { getLocale } from 'requests/helpers';

import {
  isScreenBelow1004,
  isScreenBelow640,
  isScreenBelow800,
  isScreenBelow502,
} from 'store/common/breakpoint/selectors';
import { getBeelineLogoUrl, getMobileIdLogoUrl } from 'store/common/selectors';
import {
  getPrices,
  getIEWarningPopupText,
  getIEWarningPopupButtonText,
  getExamplesButtonText,
  getExamplesSmallText,
  getExamplesOfferText,
  getExamplesButtonAlt,
} from 'store/content';
import * as Block from 'store/page/blocks/selectors';
import { getTitle } from 'store/page/examples/selectors';
import {
  getExampleSegmentAuthConditionsMet,
  getExampleSegmentAuthOfferAccepted,
  getExampleSegmentAuthSlug,
  AuthType,
} from 'store/common/exampleAuth/segmentPage';
import { triggerMobileIdAuthSegment, toggleSegmentExampleOffer } from 'store/common/exampleAuth/segmentPage/actions';

import { withRenderFlag } from 'enhancers';
import { Col, Grid, Heading, SubHeading, Text, Button, SuperRetinaImage, Checkbox } from 'components';
import { PricesComponent } from '../Prices';


import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const RGrid = connect((state) => ({
  gutter: isScreenBelow1004(state) ? 18 : 42,
}))(Grid);
export const RCol = connect((state) => ({
  isUnderDesktop: isScreenBelow1004(state),
  isUnderTablet: isScreenBelow800(state),
  isUnderMobile: isScreenBelow640(state),
  size: isScreenBelow1004(state) ? '290px' : '375px',
}))(Col);

const mapStateToProps = (state) => ({
  isMobile: isScreenBelow800(state),
});

export const RHeading = connect(mapStateToProps)(Heading);
export const RSubHeading = connect(mapStateToProps)(SubHeading);
export const RText = Text;
export const RButton = connect((state) => ({
  isWide: isScreenBelow800(state),
}))(Button);

export const BeelineLogo = connect((state) => ({
  src: getBeelineLogoUrl(state),
  alt: getLocale() === 'en' ? 'beeline logo' : 'Логотип билайн',
}), {})(SuperRetinaImage);

export const MobileIDLogo = connect((state) => ({
  src: getMobileIdLogoUrl(state),
  alt: getLocale() === 'en' ? 'MobileID logo' : 'Логотип MobileID',
}), {})(SuperRetinaImage);

export const Prices = connect((state) => ({
  prices: getPrices(state),
  shouldRender: !!getPrices(state)?.length,
  isMobile: isScreenBelow800(state),
}))(withRenderFlag(PricesComponent));

export const IEWarningPopupText = connect((state) => ({
  isMedium: true,
  children: getIEWarningPopupText(state),
}))(RText);

export const IEWarningPopupButton = connect((state) => ({
  children: getIEWarningPopupButtonText(state),
  isOnlyText: true,
}))(RButton);

export const TryExampleButton = Block.connect((state) => ({
  children: getExamplesButtonText(state),
  isMobileId: true,
  isDisabled: !getExampleSegmentAuthConditionsMet(state),
  title: getTitle(state),
}), { onClick: triggerMobileIdAuthSegment })(RButton);

export const ExampleOfferCheckbox = Block.connect((state) => ({
  children: getExamplesOfferText(state),
  shouldRender: getExampleSegmentAuthSlug(state) === AuthType.FILL,
  value: getExampleSegmentAuthOfferAccepted(state),
  isWhite: true,
}), { onChange: toggleSegmentExampleOffer })(withRenderFlag(Checkbox));

export const ExampleSmallText = Block.connect((state) => ({
  children: getExamplesSmallText(state),
  shouldRender: getExampleSegmentAuthSlug(state) === AuthType.FILL,
  isSmall: true,
}))(withRenderFlag(RText));

export const ExampleButtonInfo = Block.connect((state, props) => {
  const exampleAuthSlug = getExampleSegmentAuthSlug(state) || AuthType.PUSH;

  return {
    isSmall: true,
    isSecondary: true,
    children: getExamplesButtonAlt(state)?.[exampleAuthSlug],
    className: cx({ buttonAlt: !isScreenBelow502(state), ...props.className }),
  };
}, {})(RText);
