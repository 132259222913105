import { createSelector } from '@reduxjs/toolkit';
import { blankObj } from 'utils/fn';
import { getAppCommonContent, getAppCommons } from '../selectors';
import { getMainBannerHeading } from '../page/selectors';
import { getIsAuth } from '../options/selectors';
import { getIsSegmentsMenuOpen } from './menu';
import { isScreenBelow800 } from './breakpoint/selectors';


export const getScenarioData = state => getAppCommons(state).scenario || blankObj;
export const getOrderModalData = state => getAppCommonContent(state).orderModal || blankObj;
export const getStatusModalData = state => getAppCommonContent(state).statusModal || blankObj;
export const getExampleAuthData = state => getAppCommons(state).exampleAuth || blankObj;
export const getExampleCorporateAuthData = state => getAppCommons(state).exampleCorporateAuth || blankObj;
export const getExampleSegmentAuthData = state => getAppCommons(state).exampleSegmentAuth || blankObj;
export const getExampleAuthModalData = state => getAppCommonContent(state).exampleAuthModal || blankObj;
export const getErrorMessages = state => getAppCommonContent(state).errorMessages || blankObj;
export const getNeedsCaptchaErrorMessage = state => getErrorMessages(state).needsCaptcha;
export const getUnknownErrorMessage = state => getErrorMessages(state).unknown;

export const getRequiredFieldErrorMessage = state => getErrorMessages(state).requiredField;
export const getIncorrectPhoneErrorMessage = state => getErrorMessages(state).incorrectPhone;

export const getFooterCopyright = (state) => getAppCommonContent(state).copyright;
export const getBeelineLogoUrl = (state) => getAppCommonContent(state).beelineLogoUrl;
export const getMobileIdLogoUrl = state => getAppCommonContent(state).mobileIdLogoUrl;
export const getActionButtonText = state => getAppCommonContent(state).actionButtonText;
export const getConsultationButtonText = state => getAppCommonContent(state).consultationButtonText;
export const getLinkButtonText = state => getAppCommonContent(state).linkButtonText;
export const getInstructionButtonText = state => getAppCommonContent(state).instructionButtonText;
export const getInstructionLink = state => getAppCommonContent(state).instructionLink;
export const getMobileIdHref = (state) => getAppCommonContent(state).mobIdHref;

export const getSharedComponentsReady = createSelector(
  [getFooterCopyright, getIsAuth],
  (footer, authFlag) => !!footer && typeof authFlag === 'boolean',
);

export const getPageReady = createSelector(
  [getMainBannerHeading, getSharedComponentsReady],
  (heading, sharedComponentsReady) => !!heading && sharedComponentsReady,
);

export const getNotFound = (state) => getAppCommonContent(state).notFound || blankObj;

export const displayBackgroundDrop = createSelector(
  [getIsSegmentsMenuOpen, isScreenBelow800],
  (isMenuOpen, isMobile) => isMenuOpen && !isMobile,
);
