import { AuthType, HIDE_EXAMPLE_AUTH_POPUP, SET_EXAMPLE_SLUG, SET_EXAMPLE_STEP, TOGGLE_OFFER_CHECK } from './constants';

const initialState = {
  callbackData: null,
  slug: AuthType.PUSH,
  step: 0,
  offerAccepted: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HIDE_EXAMPLE_AUTH_POPUP: {
      return {
        ...state,
        callbackData: null,
      };
    }
    case SET_EXAMPLE_SLUG:
      return {
        ...state,
        slug: action.payload,
        step: 0,
      };
    case SET_EXAMPLE_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case TOGGLE_OFFER_CHECK:
      return {
        ...state,
        offerAccepted: action.payload,
      };
    default:
      return state;
  }
}
