import { connect } from 'react-redux';

import { getHeadingText, getButtonText, getText } from 'store/common/telesalesResult/selectors';
import { RButton, RHeading, RText } from '../../Connected';

export const ModalHeading = connect(state => ({
  children: getHeadingText(state),
}))(RHeading);

export const ModalButton = connect(state => ({
  isOnlyText: true,
  children: getButtonText(state),
}))(RButton);

export const ModalText = connect(state => ({
  children: getText(state),
}))(RText);
