import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { FORM_NAMES, AUTH_MOBILE_ID_STATUSES } from 'constants';

import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { closePopup } from 'store/common/b2bAuth/actions';
import { getUrlForRedirectToB2B } from 'requests/api/constants';
import { getErrorMessages } from 'store/common/selectors';
import { getAuthTryAgainText, getAgreementText, getTooltipText } from 'store/common/b2bAuth/selectors';
import { getAuthModalSource } from 'store/options/selectors';

import { composeB2BPhoneValidator, composeRequiredValidator } from 'utils/field-validators';

import { BasePopupStateless, Preloader, Text, TextTooltip } from 'components';
import { FinalForm, FFPhoneInput } from 'components/FinalForm';
import { ModalHeading, InputLabel, ModalButton, AuthLoaderText } from './components/Connected';
import Error from './components/Error';

import { startMobileIdAuth } from './helpers';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const stateValues = {
  Initial: 'initial',
  Pending: 'pending',
  Success: 'success',
  Error: 'error',
};

const AuthViaB2BModal = ({
  errorMessages,
  tryAgainText,
  dataProcessingAgreement,
  tooltipMessage,
  closeModal,
  isMobile,
}) => {
  const [step, setStep] = useState(stateValues.Initial);
  const [isForbiddenError, setIsForbiddenError] = useState(false);
  const [isTimeoutError, setIsTimeoutError] = useState(false);
  const [isPushDeniedError, setIsPushDeniedError] = useState(false);

  const submitValues = useCallback(values => {
    setStep(stateValues.Pending);

    startMobileIdAuth({ phone: `7${values.phone}` })
      .then(() => onSuccess())
      .catch((err) => handleError(err));
  }, []);

  const onSuccess = () => {
    setStep(stateValues.Initial);
    closeModal();
    window.location.href = getUrlForRedirectToB2B();
  };

  const handleError = (err) => {
    if (err === AUTH_MOBILE_ID_STATUSES.timeout) {
      setIsTimeoutError(true);
    }

    if (err === AUTH_MOBILE_ID_STATUSES.pushDenied) {
      setIsPushDeniedError(true);
    }

    if (err?.response?.status === 403) {
      setIsForbiddenError(true);
    }

    setStep(stateValues.Error);
  };

  const renderError = () => {
    const errorProps = {};

    if (!isForbiddenError && !isTimeoutError) {
      errorProps.subTitle = errorMessages.unknown;
    }

    if (isForbiddenError) {
      errorProps.heading = errorMessages.accessDenied;
    }

    if (isTimeoutError) {
      errorProps.heading = errorMessages.timeout;
      errorProps.subTitle = errorMessages.unknown;
    }

    if (isPushDeniedError) {
      const { heading, description } = errorMessages.pushDenied;
      errorProps.heading = heading;
      errorProps.subTitle = description;
    }

    return <Error onClick={onReset} btnText={tryAgainText} {...errorProps} />;
  };

  const renderPending = () => (
    <div className={cx('loaderWrap')}>
      <Preloader />
      <AuthLoaderText isSmall className={cx('mart-xxs', 'mobidLoaderText')} />
    </div>
  );

  const onReset = useCallback(() => {
    setStep(stateValues.Initial);
    setIsForbiddenError(false);
    setIsTimeoutError(false);
    setIsPushDeniedError(false);
  }, []);

  const getValidators = () => ({
    phone: [
      composeRequiredValidator(errorMessages.requiredField),
      composeB2BPhoneValidator(errorMessages.incorrectPhone),
    ],
  });

  useEffect(() => () => {
    onReset();
  }, []);

  return (
    <BasePopupStateless isOpen onClose={closeModal} className={cx('modal', 'overlay')}>
      <div className={cx('content')}>
        {step === stateValues.Error && renderError()}
        {step === stateValues.Pending && renderPending()}
        {step === stateValues.Initial &&
          <FinalForm
            className={cx('form')}
            getValidators={getValidators}
            onSubmit={async values => {
              submitValues(values);
            }}
            name={FORM_NAMES.loginWithMobileID}
          >
            <ModalHeading className={cx('headText')} />
            <InputLabel />
            <FFPhoneInput
              name="phone"
              placeholder="+7"
              className={cx('inputContainer')}
            />
            <ModalButton className={cx('button')} isWide isSubmit />

            {/* TODO: remove after MOBID-5002 testing */
              localStorage.getItem('test_8cfcc6') && (
                <ModalButton className={cx('button')} isWide onClick={brokenMethod} />
              )
            }
            {tooltipMessage && (
              <TextTooltip
                tooltipMessage={tooltipMessage}
                isMobile={isMobile}
                className={cx('tooltip')}
                tooltipPosition={{ bottom: false, right: false }}
              >
                <Text className={cx('dataProcessingAgreement')} isSmall isInline>
                  {dataProcessingAgreement}
                </Text>
              </TextTooltip>
            )}
          </FinalForm>
        }
      </div>
    </BasePopupStateless>
  );
};

AuthViaB2BModal.propTypes = {
  closeModal: PropTypes.func,
  errorMessages: PropTypes.object,
  tryAgainText: PropTypes.node,
  dataProcessingAgreement: PropTypes.string,
  tooltipMessage: PropTypes.string,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  errorMessages: getErrorMessages(state),
  tryAgainText: getAuthTryAgainText(state),
  dataProcessingAgreement: getAgreementText(state),
  tooltipMessage: getTooltipText(state),
  isMobile: isScreenBelow800(state),
});

const mapDispatchToProps = {
  closeModal: closePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthViaB2BModal);
