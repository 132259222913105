import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import commonStyles from 'styles/common.pcss';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


export function Parameter({ children, value, className }) {
  return (
    <div className={cx('component', 'padt-m', 'padb-m', className)}>
      <div className={cx('label', 'marr-xs')}>{children}</div>
      <div className={cx('value')}>{value}</div>
    </div>
  );
}

Parameter.propTypes = {
  children: PropTypes.node,
  value: PropTypes.node,
  className: PropTypes.string,
};
