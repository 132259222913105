export const HIDE_EXAMPLE_AUTH_POPUP = 'ExampleAuth:TogglePopup';
export const AuthType = {
  PUSH: 'pushOk',
  PIN: 'pinOk',
  FILL: 'fill',
};

export const SET_EXAMPLE_SLUG = 'ExampleAuth:SetSlug';
export const SET_EXAMPLE_STEP = 'ExampleAuth:SetStep';
export const TOGGLE_OFFER_CHECK = 'ExampleAuth:ToggleOffer';
