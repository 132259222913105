import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { withToggle } from 'enhancers';

import FixedOverlay from '../FixedOverlay';
import CloseIcon from '../../assets/CloseIcon.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


/**
 * Stateless popup layout with screen overlay
 * Appears by sliding down from top of the screen,
 * Content has predefined size and fixed outer indents, but no padding
 */
export function BasePopupStateless({
  children, isOpen, isWide, onClose, className, hideCloseButton, hasScrollOverlay,
}) {
  return (
    <FixedOverlay isOpen={isOpen} onClose={onClose} hasScrollOverlay={hasScrollOverlay} className={cx('overlay')}>
      <div className={cx('component', className, { isWide })}>
        <button className={cx('cross', 'popupCloseButton', { hideCloseButton })} onClick={onClose} type="button">
          <CloseIcon className={cx('icon')} />
        </button>
        {children}
      </div>
    </FixedOverlay>
  );
}

BasePopupStateless.propTypes = {
  isOpen: PropTypes.bool,
  isWide: PropTypes.bool,
  hasScrollOverlay: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};


/**
 * One-use popup, initially open, it can only be closed once and for good
 * For all other use cases, please refer to Stateless version
 */
export const BasePopupDisposable = withToggle(BasePopupStateless);

BasePopupDisposable.defaultProps = {
  isOpen: true,
};
