import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';
import { Text } from '../Text';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


export function FaqItem({ title, children, isOpen, onClick, className }) {
  return (
    <div className={cx('component', { open: isOpen }, className)}>
      <Text className={cx('title', 'padt-s', 'padb-s')} onClick={onClick}>{title}</Text>
      <Text isSecondary className={cx('content', 'padb-s')}>
        {children}
      </Text>
    </div>
  );
}

FaqItem.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export function StatefulFaqItem(props) {
  const [isOpen, setOpen] = React.useState(false);

  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, [isOpen, props.title]);

  return (
    <FaqItem {...props} isOpen={isOpen} onClick={handleClick} />
  );
}

StatefulFaqItem.propTypes = {
  ...FaqItem.propTypes,
};
