/**
 * calculate offset from node, to current scrollTop of the window
 * @param node - DOM node
 * @param isBottom - if true, returns bottom offset, otherwise top offset
 * @returns number of pixels from window top edge, negative if left behind scroll, null on error
 */
export const getNodeScrollOffset = (node, isBottom) => {
  if (typeof node !== 'object' || (node === null) || !('getBoundingClientRect' in node)) return null;
  if (isBottom) return node.getBoundingClientRect().bottom;
  return 0 - node.getBoundingClientRect().top;
};

export const getNodeCenterOffset = (node) => {
  if (typeof node !== 'object' || (node === null) || !('getBoundingClientRect' in node)) return null;
  const { width, left } = node.getBoundingClientRect();
  return left + width / 2;
};

export const getClientRect = (elem) => elem?.current?.getBoundingClientRect();

/**
 * Функция принимает id и вертикальное выравнивание для блока, к которому необходимо скроллить
 * @param {string} id - id блока, к которому необходим скролл
 * @param {('center' | 'end' | 'nearest' | 'start')} [block='start'] - вертикальное выравнивание
 * @param {('auto' | 'smooth')} [behavior='smooth'] - тип анимации скролла
 * @returns {undefined}
 */

export const scrollTo = (id, block = 'start', behavior = 'smooth') => {
  document.getElementById(id)?.scrollIntoView({ block, behavior });
};
