import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Tab = ({ isActive, onClick, children, className }) => (
  <div
    className={cx('component', isActive && 'active', className)}
    onClick={onClick}
    role="menuitem"
    tabIndex={0}
  >
    {children}
  </div>
);

Tab.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
