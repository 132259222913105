import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

export const Item = ({ title, className }) => (
  <li className={cx('component', className)}>
    <Text>
      {title}
    </Text>
  </li>
);

Item.propTypes = {
  title: PropTypes.string,
  className: PropTypes.any,
};

