import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { getMainBannerBg, getMainBannerMobileBg } from 'store/page/selectors';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


function MainBannerBgLayout({ isMobile, bgDesktop, bgMobile, className }) {
  return (
    <div
      className={cx(isMobile ? 'mobile' : 'desktop', className)}
      style={isMobile ? { backgroundImage: `url('${bgMobile}')` } : null}
    >
      {!isMobile && <div className={cx('bg')} style={{ backgroundImage: `url('${bgDesktop}')` }} />}
    </div>
  );
}

MainBannerBgLayout.propTypes = {
  isMobile: PropTypes.bool,
  bgDesktop: PropTypes.string,
  bgMobile: PropTypes.string,
  className: PropTypes.string,
};


const mapStateToProps = (state) => ({
  bgDesktop: getMainBannerBg(state),
  bgMobile: getMainBannerMobileBg(state),
  isMobile: isScreenBelow800(state),
});

export const MainBannerBg = connect(mapStateToProps)(MainBannerBgLayout);
