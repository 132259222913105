import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';
import * as BlockItem from 'store/page/blockItems/selectors';
import { TabsItem } from 'components';

const cx = classNames.bind(commonStyles);


export const ExampleAuthTabItem = BlockItem.connect((state) => ({
  className: cx('padt-xxs', 'padb-xxs'),
  isSmall: true,
  isSecondary: true,
  id: BlockItem.getSlug(state),
  children: BlockItem.getTitle(state),
}))(TabsItem);

export { default as ExampleAuthTabs } from './ExampleAuthTab';

