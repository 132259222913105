import React from 'react';
import PropTypes from 'prop-types';

import { BlockItemContext } from 'store/page/blockItems/context';
import * as Blocks from 'store/page/blocks/selectors';


function Mapper({ slugs, children }) {
  return slugs.map((slug, index) => (
    <BlockItemContext.Provider value={slug} key={slug}>
      {typeof children === 'function' ? children(slug, index) : children}
    </BlockItemContext.Provider>
  ));
}

Mapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  slugs: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  slugs: Blocks.getItemSlugs(state),
});

export const ItemMapper = Blocks.connect(mapStateToProps)(Mapper);
