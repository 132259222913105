import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import * as BlockItem from 'store/page/blockItems/selectors';
import { getExampleAuthStep } from 'store/common/exampleAuth/mainPage/selectors';
import { getTitle } from 'store/page/blockItems/selectors';
import { Text } from 'components/Text';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


const Mapper = ({ list, title, children, step, isMobile }) => (
  list.length ? (
    <div className={cx({ mobile: isMobile })}>
      <div className={cx('steps')}>
        {list.map((item, index) => (
          children({ ...item, title, isTiny: isMobile }, index)
        ))}
      </div>
      {isMobile && !!list.length && <Text>{list[step].text}</Text>}
    </div>
  ) : null
);

Mapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    imageUrl: PropTypes.string,
  })),
  step: PropTypes.number,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  title: getTitle(state),
  list: BlockItem.getSteps(state),
  step: getExampleAuthStep(state),
  isMobile: isScreenBelow800(state),
});

export const ExampleStepsMapper = BlockItem.connect(mapStateToProps)(Mapper);
