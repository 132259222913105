import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes, USE_CASES_BLOCK } from 'constants';

import { Heading, Wrapper } from 'components';
import { Accordion, Filters, FilterButton, Item } from './components';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

export const UseCasesComponent = ({ heading, filters, onFilterClick, isMobile, className }) => (
  <Wrapper id={USE_CASES_BLOCK} className={className}>
    <div className={cx('marb-l', 'header')}>
      <Heading className={cx('marr-xs')} isMobile={isMobile}>
        {heading}
      </Heading>

      <Filters className={cx('filters')} content={filters}>
        {(filter, index) => (
          <FilterButton
            key={index.toString()}
            isMobile={isMobile}
            filter={filter}
            onClick={onFilterClick}
            isActive
          />
        )}
      </Filters>
    </div>

    <Accordion>
      {(item, index) => (
        <Item
          key={index.toString()}
          className={cx('item')}
          item={item}
        />
      )}
    </Accordion>
  </Wrapper>
);

UseCasesComponent.propTypes = {
  heading: PropTypes.string,
  isMobile: PropTypes.bool,
  filters: PropTypes.array,
  onFilterClick: PropTypes.func,
  className: classNamePropTypes,
};
