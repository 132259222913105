import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { withError, withLabel } from '../enhancers';
import CoreInput from './CoreInput';

import styles from './CaptchaInput.pcss';
const cx = classNames.bind(styles);


function CaptchaInput({ captchaSrc, className, ...inputProps }) {
  return (
    <div className={cx('component', className)}>
      <img src={captchaSrc} alt="Код подтверждения" className={cx('captcha')} />
      <CoreInput {...inputProps} className={cx('input')} />
    </div>
  );
}

CaptchaInput.propTypes = {
  value: PropTypes.string,
  captchaSrc: PropTypes.string,
  className: PropTypes.string,
};


CaptchaInput.defaultProps = {
  value: '',
};

export default withError(withLabel(CaptchaInput));
