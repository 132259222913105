export const EVENT = 'GA_event';

export const EC_PUSH = 'push';
export const EC_CONVERTION = 'micro-conversion';
export const EC_FORM = 'form_';
export const EC_ACCOUNT = 'mobileid_account';

export const EA_SENT = 'sent';
export const EA_FOCUS = 'onfocus';
export const EA_SUCCESS = 'successFilled';
export const EA_ERROR = 'error';
export const EA_CLICK = 'click';
export const EA_ACCOUNT = 'enter_account';
export const EA_TRY_SERVICE = 'try_service';

export const EL = {
  header: 'Шапка сайта',
  connect: 'Кнопка Подключить',
  connect_2: 'Кнопка Подключить_2',
  try_simple: 'Простой вход',
  try_pin: 'Вход с PIN-кодом',
  try_auto: 'Автозаполнение',
};
