import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function IconCircle({ children, isActive, isSmall, className }) {
  return (
    <div className={cx('component', { active: isActive, alwaysActive: isActive === null, small: isSmall }, className)}>
      {children}
    </div>
  );
}

IconCircle.propTypes = {
  /** null means always active */
  isActive: PropTypes.oneOf([true, false, null]),
  isSmall: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

IconCircle.defaultProps = {
  isActive: null,
};
