import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import * as Blocks from 'store/page/blocks/selectors';
import { Heading } from 'components';
import { BenefitItem } from '../BenefitItem';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Component = ({ titles, cards }) => (
  <div className={cx('container')}>
    <div className={cx('row')}>
      {titles.map((title, idx) => (
        <div key={idx.toString()} className={cx('column')}>
          <Heading size={3} className={cx('item')}>{title}</Heading>
        </div>
      ))}
    </div>

    {cards.map((row, rowIdx) => (
      <div key={rowIdx.toString()} className={cx('row')}>
        {row.map((card, cardIdx) => (
          <div className={cx('column')} key={cardIdx.toString()}>
            <BenefitItem className={cx('item')} {...card} />
          </div>
        ))}
      </div>
    ))}

  </div>
);

Component.propTypes = {
  cards: PropTypes.array,
  titles: PropTypes.array,
};

const mapStateToProps = (state) => ({
  cards: Blocks.getItemsDesktopViewCards(state),
  titles: Blocks.getItemsDesktopViewTitles(state),
});

export const DesktopView = Blocks.connect(mapStateToProps)(Component);
