import React from 'react';
import PropTypes from 'prop-types';
import { DesktopView } from './components/DesktopView';
import { SlidersView } from './components/SlidersView';


export const Benefits = ({ isMobile }) => (isMobile
  ? <SlidersView />
  : <DesktopView />);

Benefits.propTypes = {
  isMobile: PropTypes.bool,
};
