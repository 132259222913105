import React from 'react';
import CustomPropTypes from 'utils/prop-types';


/**
 * HOC that created domNodeRef and controlsRef - both of which are mandatory props for the ScrollContainer
 * This HOC can be omitted if domNodeRef and controlsRef are passed manually
 */
export default function withDefaultRefs(WrappedComponent) {
  function WithDefaultRefs(props) {
    return (
      <WrappedComponent
        domNodeRef={React.useRef()}
        controlsRef={React.useRef()}
        {...props}
      />
    );
  }

  WithDefaultRefs.propTypes = {
    ...WrappedComponent.propTypes,

    /** these refs are made optional */
    domNodeRef: CustomPropTypes.newRef,
    controlsRef: CustomPropTypes.newRef,
  };

  if (process.env.NODE_ENV !== 'production') {
    WithDefaultRefs.displayName = WrappedComponent.displayName;
  }

  return WithDefaultRefs;
}

