// Стандартное количество элементов в странице
const PAGE_SIZE = 10;

// Разбиваем элементы одного массива на подмассивы из 10 элементов (ну и остаток от деления на 10)
const mapList = (list) => {
  const mappedList = [];
  for (let i = 0; i < list.length; i += PAGE_SIZE) {
    mappedList.push(list.slice(i, i + PAGE_SIZE));
  }

  return mappedList;
};

export const mapListByFilters = (list, filters) => {
  let accum = [...list];
  let i = 0;

  while (i < filters.length) {
    accum = accum.filter((item) => {
      const filterIds = item.filters.map(({ id }) => id);
      return (filterIds.includes(filters[i].id) ? item : undefined);
    });
    i += 1;
  }

  return accum;
};

export const mapUseCases = (list) => {
  if (!list?.length) return [];

  const mappedList = mapList(list);

  return ({
    list: mappedList,
    pagesNumbers: Array.from({ length: mappedList.length }, (v, k) => k + 1),
  });
};
