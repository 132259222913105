import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { isNullOrUndefined } from 'utils/fn';
import { GridContext } from './context';
import styles from './styles.pcss';
import { mapValignToClass } from './helpers';
const cx = classNames.bind(styles);


/**
 * Layout for non adaptive 12-grid column,
 */
export function BaseCol({ children, size, valign, isFull, className }) {
  /**
   * returns number for column-based grid, or nothing for 12 column or unknown number of columns
   */
  const getSizeModifier = () => {
    if (!size) return '';
    if (!isSizeInUnits()) return '-fixed';
    return `-${size}`;
  };

  /**
   * test if size is percentage, rather than columns
   */
  const isSizeInUnits = () => typeof size === 'number';

  /**
   * for percentage-based columns returns style object extended with width
   */
  const injectStyles = (baseStyles) => {
    if (isSizeInUnits()) return baseStyles;
    return { ...baseStyles, [isSizeInUnits() ? 'width' : 'flexBasis']: size };
  };

  const classes = cx(
    `columns${getSizeModifier()}`,
    { full: isFull },
    mapValignToClass(valign),
    className,
  );

  const { colStyle } = React.useContext(GridContext);
  return size !== 0 && (
    <div className={classes} style={injectStyles(colStyle)}>
      {children}
    </div>
  );
}

BaseCol.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valign: PropTypes.oneOf(['middle', 'bottom']),
  isFull: PropTypes.bool,
  className: PropTypes.string,
};


export function Col({ size, s, m, l, isUnderDesktop, isUnderTablet, isUnderMobile, ...baseColProps }) {
  function getSize() {
    if (!isNullOrUndefined(s) && isUnderMobile) return s;
    if (!isNullOrUndefined(m) && isUnderTablet) return m;
    if (!isNullOrUndefined(l) && isUnderDesktop) return l;
    return size;
  }

  return (
    <BaseCol
      {...baseColProps}
      size={getSize()}
    />
  );
}

Col.propTypes = {
  ...BaseCol.propTypes,
  isUnderDesktop: PropTypes.bool,
  isUnderTablet: PropTypes.bool,
  isUnderMobile: PropTypes.bool,
  l: PropTypes.number,
  m: PropTypes.number,
  s: PropTypes.number,
};
