import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import commonStyles from 'styles/common.pcss';

import { IconCircle } from '../IconCircle';
import { LinkBlock } from '../LinkBlock';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

export function Benefit({ children, icon, attachmentUrl, className }) {
  return (
    <LinkBlock href={attachmentUrl} className={cx('component', className)}>
      <IconCircle className={cx('icon', 'marr-xs')}>{icon}</IconCircle>
      <div className={cx('content')}>{children}</div>
    </LinkBlock>
  );
}

Benefit.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  attachmentUrl: PropTypes.string,
  className: PropTypes.string,
};
