import React from 'react';
import PropTypes from 'prop-types';

import { useVideo } from './useVideo';

import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { Overlay } from './Overlay';

const cx = classNames.bind(styles);

export const Video = ({ name, id, src, className }) => {
  const { ref, isPlaying, startPlaying } = useVideo();

  return (
    <>
      {!isPlaying && (
        <Overlay
          name={name}
          onClick={startPlaying}
          className={className}
        />
      )}

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={ref}
        className={cx('component')}
        id={id}
        src={src}
        preload="auto"
        controls={isPlaying}
        controlsList="nodownload"
      />
    </>
  );
};

Video.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
