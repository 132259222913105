import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { REACT_MODELS } from 'constants';

import { getLocale } from 'requests/helpers';

import { withRenderFlag, withReactModel } from 'enhancers';

import { getPageReady } from 'store/common/selectors';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { PageLayout, Suspense, IEWarningPopup, MainBanner, MainBannerBg } from 'shared';
import { Prices } from 'shared/Connected';
import {
  ActionButton,
  ConsultationButton,
  MainBannerHeading,
  MainBannerText,
  Benefits,
  Authorization,
  HowTo,
  Examples,
  Videos,
} from './components';

import commonStyles from 'styles/common.pcss';

import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const CorporateAuthPage = ({ isMobile }) => (
  <PageLayout className={cx}>
    <IEWarningPopup />
    <MainBanner
      desktopBg={(
        <MainBannerBg>
          <MainBannerBg.Desktop className={cx('background')} />
        </MainBannerBg>
      )}
      className={cx('marb-xxxl')}
    >
      <MainBannerHeading />
      <MainBannerText className={cx(isMobile)} />
      {isMobile && (
        <MainBannerBg>
          <MainBannerBg.Mobile />
        </MainBannerBg>
      )}
      <ActionButton className={cx('marr-s', 'marb-s')} />
      <ConsultationButton />
    </MainBanner>

    <Benefits className={cx('marb-xxxl')} />

    <Authorization className={cx('marb-xxxl')} />

    <Prices className={cx('marb-xxxl')} />

    <Examples className={cx('marb-xxxl')} />

    <Videos className={cx('marb-xxxl')} />

    <HowTo className={cx('marb-xxxl')}>
      <ActionButton className={cx('marr-m', 'marb-s')} />
      <ConsultationButton />
    </HowTo>
  </PageLayout>
);


const mapStateToProps = (state) => ({
  shouldRender: getPageReady(state),
  isMobile: isScreenBelow800(state),
});

CorporateAuthPage.propTypes = {
  isMobile: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps),
  withReactModel(REACT_MODELS.common),
  withReactModel(getLocale() === 'en' ? REACT_MODELS.corporate_en : REACT_MODELS.corporate_ru),
  withRenderFlag,
)(CorporateAuthPage, <Suspense />);
