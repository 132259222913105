import { createSelector } from '@reduxjs/toolkit';

import { getExampleAuthSlug, getExampleAuthStep } from 'store/common/exampleAuth/mainPage/selectors';

const getExamplesList = (state) => state?.content?.page?.examples?.list ?? [];

const getExampleAuth = createSelector(
  [getExamplesList, getExampleAuthSlug],
  (list, authSlug) => list.find(({ slug }) => slug === authSlug),
);

export const getImageUrl = createSelector([
  getExampleAuth,
  getExampleAuthStep,
], (state, step) => (state?.steps ? state.steps?.[step]?.imageUrl : null));

export const getTitle = createSelector(getExampleAuth, (state) => state?.title || null);
