import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames/bind';

import { simulateResize } from 'enhancers/helpers';
import { ignoreState } from 'utils/decorators';

import ContentFrame from './ContentFrame';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


const takeContentFields = ({ children, activeSlug, contentKey, primaryIndex, secondaryIndex }) =>
  ({ children, contentKey: contentKey || `${primaryIndex}-${secondaryIndex}`, primaryIndex, secondaryIndex, activeSlug });


const computeNextTransitionState = (nextProps, prevState) => ({
  inTransition: true,
  prevContent: prevState.currentContent,
  currentContent: takeContentFields(nextProps),
  currentContentSlug: nextProps?.activeSlug,
  scheduledContent: undefined,
});


export class ContentSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inTransition: false,
      currentContent: takeContentFields(props),
      currentContentSlug: props?.activeSlug,
    };
    this.nextNodeRef = React.createRef();
  }

  static getDerivedStateFromProps = ignoreState((nextProps, prevState) => {
    const { contentKey, activeSlug } = takeContentFields(nextProps);
    const { currentContent, inTransition, currentContentSlug } = prevState;

    /** If new contentKey is passed while animation is in progress, new animation is scheduled */
    if (inTransition) {
      return (contentKey === currentContent.contentKey || currentContentSlug === activeSlug) ? {
        scheduledContent: undefined,
      } : {
        scheduledContent: takeContentFields(nextProps),
      };
    }

    /** If new contentKey is passed, an animation starts */
    if (currentContent.contentKey !== contentKey || currentContentSlug !== activeSlug) {
      return computeNextTransitionState(nextProps, prevState);
    }
    return null;
  });

  /**
   * Terminate current transition and switch to scheduled if there is one
   */
  finishTransition = () => {
    const { scheduledContent } = this.state;
    this.setState({ inTransition: false, transitionStarted: false }, () => {
      if (scheduledContent !== undefined) {
        this.setState(computeNextTransitionState(scheduledContent, this.state));
      }
      simulateResize();
    });
  };

  getContentStyles() {
    if (this.state.inTransition) {
      const height = this.nextNodeRef.current.clientHeight;
      switch (this.getTransitionDirection()) {
        case 'transDown':
          return { transform: `translateY(${height}px)` };
        default:
          return {};
      }
    }
    return null;
  }

  getTransitionDirection() {
    const { prevContent = {}, currentContent } = this.state;
    if (currentContent.secondaryIndex > prevContent.secondaryIndex) {
      return 'transIn';
    } else if (currentContent.secondaryIndex < prevContent.secondaryIndex) {
      return 'transOut';
    } else if (currentContent.primaryIndex < prevContent.primaryIndex) {
      return 'transUp';
    }
    return 'transDown';
  }

  render() {
    const { currentContent, prevContent, inTransition } = this.state;
    const { duration } = this.props;
    return (
      <CSSTransition
        in={!inTransition}
        classNames={{
          exit: cx('exit'),
          exitActive: cx('exitActive'),
          exitDone: cx('exitDone'),
        }}
        timeout={duration}
        enter={false}
        onExited={this.finishTransition}
      >
        <div className={cx('container', { animated: inTransition }, this.getTransitionDirection())}>
          {inTransition && (
            <ContentFrame
              key={`${prevContent.contentKey}-${prevContent.activeSlug}`}
              className={cx('component', 'prev')}
            >
              {prevContent.children}
            </ContentFrame>
          )}
          <ContentFrame
            key={`${currentContent.contentKey}-${currentContent.activeSlug}`}
            className={cx('component', 'next')}
            style={this.getContentStyles()}
            nodeRef={this.nextNodeRef}
          >
            {currentContent.children}
          </ContentFrame>
        </div>
      </CSSTransition>
    );
  }
}

ContentSwitcher.propTypes = {
  duration: PropTypes.number,

  /** Content related fields. You know nothing `Eslint Snow` */
  children: PropTypes.node, // eslint-disable-line react/no-unused-prop-types
  contentKey: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  activeSlug: PropTypes.string,

  /**
   * Direction of animation is determined by index pair,
   * `primaryIndex` affects vertical scroll, `secondaryIndex` - horizontal scroll
   * Horizontal scroll is favored over vertical
   */
  // eslint-disable-next-line react/no-unused-prop-types
  primaryIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  // eslint-disable-next-line react/no-unused-prop-types
  secondaryIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};

ContentSwitcher.defaultProps = {
  duration: 450,
};
