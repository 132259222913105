import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPageMeta } from 'store/selectors';

import { Meta } from 'shared/Meta';

export const withMeta = (WrappedComponent) => {
  const Component = ({ meta, ...restProps }) => (
    <>
      <Meta title={meta.title} canonical={meta.canonical}>
        {meta.meta?.map?.(({ name, content }) => <meta key={name} name={name} content={content} />)}
      </Meta>

      <WrappedComponent {...restProps} />
    </>
  );

  const mapStateToProps = (state) => ({
    meta: getPageMeta(state),
  });

  Component.propTypes = {
    meta: PropTypes.object,
  };

  return connect(mapStateToProps)(Component);
};
