import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import CustomPropTypes from 'utils/prop-types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Frame = ({ children, frame }) => (
  <div className={cx('frame')} ref={frame}>
    {children}
  </div>
);

Frame.propTypes = {
  frame: CustomPropTypes.ref,
  children: PropTypes.any,
};

export const FrameSwitcher = ({
  frame,
  offset,
  mappedChildren,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  className,
}) => (
  <div className={cx('container', className)}>
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={cx('mainContainer')}
      style={{ transform: `translateX(${offset}px)` }}
    >
      {mappedChildren?.map?.((child, index) => (
        <Frame key={index.toString()} frame={frame}>
          {child}
        </Frame>
      ))}
    </div>
  </div>
);

FrameSwitcher.propTypes = {
  frame: CustomPropTypes.ref,
  offset: PropTypes.number,
  mappedChildren: PropTypes.node,
  handleTouchStart: PropTypes.func,
  handleTouchMove: PropTypes.func,
  handleTouchEnd: PropTypes.func,
  className: PropTypes.string,
};
