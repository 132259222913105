import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { displayBackgroundDrop } from 'store/common/selectors';
import { toggleSegmentsMenu } from 'store/common/menu';

import { withRenderFlag } from 'enhancers';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const BackgroundDropComponent = ({ onClick }) => (
  <div onClick={onClick} className={cx('component')} />
);

const mapStateToProps = (state) => ({
  shouldRender: displayBackgroundDrop(state),
});

const mapDispatchToProps = {
  onClick: toggleSegmentsMenu,
};

BackgroundDropComponent.propTypes = {
  onClick: PropTypes.func,
};

export const BackgroundDrop = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRenderFlag,
)(BackgroundDropComponent);
