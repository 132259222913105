import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { EL } from 'analytics/constants';

import { withRenderFlag } from 'enhancers';
import { openPopup as openB2BModal } from 'store/common/b2bAuth/actions';
import { openConsultationPopup } from 'store/common/consultation/actions';
import { setAuthModalSource } from 'store/options/actions';

import {
  getExampleCorporateAuthSlug,
  getExampleCorporateAuthStep,
  getActiveSteps,
} from 'store/common/exampleAuth/corporateAuth';
import {
  setExampleCorporateAuthStep,
  setExampleCorporateAuthSlug,
} from 'store/common/exampleAuth/corporateAuth/actions';

import { getIsAuth } from 'store/options/selectors';
import { getMainBannerHeading, getMainBannerText } from 'store/page/selectors';
import {
  getActionButtonText,
  getBenefitsList,
  getBenefitsHeading,
  getBenefitsText,
  getAuthorizationHeading,
  getAuthorizationImage,
  getAuthorizationList,
  getHowToHeading,
  getHowToList,
  getVideos,
  getExamplesTabs,
} from 'store/content';
import { getConsultationButtonText } from 'store/common/selectors';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import * as Block from 'store/page/blocks/selectors';

import { Step } from 'components';
import { RHeading, RSubHeading, RButton } from 'shared/Connected';
import { BenefitsComponent } from '../Benefits';
import { AuthorizationComponent } from '../Authorization';
import { HowToComponent } from '../HowTo';
import { VideosComponent } from '../Videos';
import { ImageComponent, StepsComponent, ExampleAuthTabsComponent } from 'shared';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export const MainBannerHeading = connect((state, props) => ({
  size: 1,
  className: cx(['heading', ...props.className]),
  children: getMainBannerHeading(state),
}))(RHeading);

export const MainBannerText = connect((state, props) => ({
  children: getMainBannerText(state),
  className: cx(['text', ...props.className]),
  isMedium: true,
}))(RSubHeading);

export const ActionButton = Block.connect((state) => ({
  children: getActionButtonText(state),
  isOnlyText: true,
  shouldRender: !getIsAuth(state),
}), (dispatch) => ({ onClick: () => {
  dispatch(setAuthModalSource(EL.connect));
  dispatch(openB2BModal());
} }))(withRenderFlag(RButton));

export const ConsultationButton = Block.connect((state) => ({
  children: getConsultationButtonText(state),
  isOnlyText: true,
  isDark: true,
  shouldRender: !getIsAuth(state),
}), { onClick: openConsultationPopup })(withRenderFlag(RButton));

export const Benefits = connect((state) => ({
  list: getBenefitsList(state),
  heading: getBenefitsHeading(state),
  description: getBenefitsText(state),
  isMobile: isScreenBelow800(state),
}))(BenefitsComponent);

export const Authorization = connect((state) => ({
  list: getAuthorizationList(state),
  image: getAuthorizationImage(state),
  heading: getAuthorizationHeading(state),
  isMobile: isScreenBelow800(state),
}))(AuthorizationComponent);

export const HowTo = connect((state) => ({
  list: getHowToList(state),
  heading: getHowToHeading(state),
  isMobile: isScreenBelow800(state),
}))(HowToComponent);

export const ExampleStep = connect((state, { step }) => ({
  isActive: step === getExampleCorporateAuthStep(state),
  isTiny: true,
}), (dispatch, { step, title, text }) => ({
  onClick: () => {
    dispatch(setExampleCorporateAuthStep(step));
  },
}))(Step);

export const Videos = connect((state) => ({
  videos: getVideos(state),
  isMobile: isScreenBelow800(state),
}))(VideosComponent);

export const Image = connect((state) => ({
  activeStep: getExampleCorporateAuthStep(state),
  activeExampleSlug: getExampleCorporateAuthSlug(state),
}))(ImageComponent);

export const Steps = connect((state) => ({
  isTiny: isScreenBelow800(state),
  activeStepIndex: getExampleCorporateAuthStep(state),
  steps: getActiveSteps(state),
}))(StepsComponent);

export const ExampleAuthTabs = connect((state) => ({
  tabs: getExamplesTabs(state),
  activeExampleSlug: getExampleCorporateAuthSlug(state),
}), {
  onActiveChange: setExampleCorporateAuthSlug,
})(ExampleAuthTabsComponent);
