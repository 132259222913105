import { axiosBaseRequest } from 'requests/helpers';
import { getReactModelUrl } from './constants';

/**
 * @param {string} models - Алиас нужной реакт-модели. Если необходимо несколько реакт-моделей, аргументом функции
 * будет строка из алиасов, перечисленных через запятую
 * */
export const getReactModelRequest = (models) => axiosBaseRequest({
  url: getReactModelUrl(),
  params: { models },
})
  .then(({ data }) => data);
