import { AuthType, SET_EXAMPLE_SLUG, SET_EXAMPLE_STEP } from './constants';

const initialState = {
  slug: AuthType.ONE_FACTOR,
  step: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_EXAMPLE_SLUG:
      return {
        ...state,
        slug: action.payload,
        step: 0,
      };
    case SET_EXAMPLE_STEP:
      return {
        ...state,
        step: action.payload,
      };
    default:
      return state;
  }
}
