import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { getPaginationPrevButton, getPaginationNextButton } from 'store/content';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { Text } from '../../../Text';
import Arrow from './arrow.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PrevNextButtonComponent = ({
  isForward,
  isMobile,
  disabled,
  onClick,
  nextText,
  prevText,
  className,
}) => (
  <button
    className={cx('component', className)}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    <div className={cx('button')}>
      <Arrow className={cx('arrow', { isForward })} />
    </div>

    {!isMobile && (
      <Text className={cx('text')}>
        {isForward ? nextText : prevText}
      </Text>
    )}
  </button>
);

const mapStateToProps = (state) => ({
  prevText: getPaginationPrevButton(state),
  nextText: getPaginationNextButton(state),
  isMobile: isScreenBelow800(state),
});

PrevNextButtonComponent.propTypes = {
  onClick: PropTypes.func,
  prevText: PropTypes.string,
  nextText: PropTypes.string,
  isForward: PropTypes.bool,
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  className: classNamePropTypes,
};

export const PrevNextButton = connect(mapStateToProps)(PrevNextButtonComponent);
