import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { MainBannerBg, MainBanner, BackgroundBlur } from 'shared';
import { MainBannerDescription, MainBannerHeading, MainBannerText } from '../Connected';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const BannerComponent = ({ isMobile, className }) => (
  <MainBanner
    desktopBg={(
      <MainBannerBg blur={<BackgroundBlur className={cx('blur')} />}>
        <MainBannerBg.Desktop className={cx('background')} />
      </MainBannerBg>
    )}
    className={className}
  >
    <div className={cx('content')}>
      <MainBannerHeading className={cx('heading')} />
      <MainBannerDescription className={cx('description')} />
      <MainBannerText />
    </div>
    {isMobile && (
      <MainBannerBg blur={<BackgroundBlur className={cx('blur')} />}>
        <MainBannerBg.Mobile />
      </MainBannerBg>
    )}
  </MainBanner>
);


const mapStateToProps = (state) => ({
  isMobile: isScreenBelow800(state),
});

BannerComponent.propTypes = {
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export const Banner = connect(mapStateToProps)(BannerComponent);
