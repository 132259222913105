import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BlockContext } from 'store/page/blocks/context';
import { getPageBlockSlugs } from 'store/page/selectors';


function Mapper({ ids, children }) {
  return ids.map((id, index) => (
    <BlockContext.Provider value={id} key={id}>
      {children({ slug: id }, index)}
    </BlockContext.Provider>
  ));
}

Mapper.propTypes = {
  children: PropTypes.func,
  ids: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  ids: getPageBlockSlugs(state),
});

export const BlockMapper = connect(mapStateToProps)(Mapper);
