import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import Arrow from './arrow.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Button = ({ isOpen, className }) => (
  <div className={cx('component', { open: isOpen }, className)}>
    <Arrow className={cx('arrow')} />
  </div>
);

Button.propTypes = {
  isOpen: PropTypes.bool,
  className: classNamePropTypes,
};
