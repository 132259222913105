import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Heading, Wrapper } from 'components';
import { ListItem } from './ListItem';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

export const AuthorizationComponent = ({ heading, image, list, isMobile, className }) => (
  <Wrapper className={className}>
    <Heading className={cx(isMobile ? 'marb-s' : 'marb-xl')} isMobile={isMobile}>
      {heading}
    </Heading>

    <div className={cx('content')}>
      <img
        className={cx(isMobile && 'marb-xl', 'image')}
        src={image}
        alt=""
      />

      <ul className={cx('list')}>
        {list.map((element, index) => (
          <ListItem
            key={index.toString()}
            className={cx('element')}
            isMobile={isMobile}
            {...element}
          />
        ))}
      </ul>
    </div>
  </Wrapper>
);

AuthorizationComponent.propTypes = {
  className: PropTypes.string,
  list: PropTypes.any,
  heading: PropTypes.string,
  image: PropTypes.string,
  isMobile: PropTypes.bool,
};
