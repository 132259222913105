import { TextInput, PhoneInput } from 'components/TextInput';
import withFinalField from './enhancers/withFinalField';
import { Checkbox } from '../Checkbox';
import CaptchaInput from '../TextInput/components/CaptchaInput';
import { withError, withAnalytics } from '../TextInput/enhancers';

export const FFTextInput = withFinalField(TextInput);
export const FFPhoneInput = withFinalField(PhoneInput);
export const FFCheckbox = withFinalField({ type: 'checkbox' })(withError(Checkbox));
export const FFCaptchaInput = withFinalField(CaptchaInput);
export { FinalForm } from './FinalForm';

/* WithAnalytics */
export const FFPhoneInputGA = withFinalField(withAnalytics(PhoneInput));
