import { getAppCommonContent, getAppPageContent } from '../selectors';
import { blankArr, blankObj } from '../../utils/fn';
import { mapListByFilters, mapUseCases } from './helpers';
import { createSelector } from '@reduxjs/toolkit';
import { getFilters } from '../filters';

export const getContent = (state) => state.content;
export const getActionButtonText = state => getAppPageContent(state).actionButtonText;


/** IEWarningPopup */
export const getIEWarningPopupData = state => getAppCommonContent(state).ieWarningPopup || blankObj;
export const getIEWarningPopupText = state => getIEWarningPopupData(state).text;
export const getIEWarningPopupButtonText = state => getIEWarningPopupData(state).buttonText;

/* Segments */
export const getSegmentsData = state => getAppCommonContent(state).segments || blankObj;
export const getSegmentsButton = state => getSegmentsData(state).button || blankObj;
export const getSegmentsButtonIcon = state => getSegmentsButton(state).iconSrc;
export const getSegmentsButtonText = state => getSegmentsButton(state).text;
export const getSegmentsMenu = state => getSegmentsData(state).menu;

/* CorporatePage */
export const getBenefits = state => getAppPageContent(state).benefits || blankObj;
export const getBenefitsHeading = state => getBenefits(state).heading;
export const getBenefitsText = state => getBenefits(state).text;
export const getBenefitsList = state => getBenefits(state).list || blankArr;

export const getAuthorization = state => getAppPageContent(state).authorization || blankObj;
export const getAuthorizationHeading = state => getAuthorization(state).heading;
export const getAuthorizationImage = state => getAuthorization(state).imageSrc;
export const getAuthorizationList = state => getAuthorization(state).list || blankArr;

export const getPrices = state => getAppPageContent(state).prices || blankArr;

export const getExamples = state => getAppPageContent(state).examples || blankObj;
export const getExamplesHeading = state => getExamples(state).heading;
export const getExamplesList = state => getExamples(state).list || blankArr;
export const getExamplesTabs = state => getExamplesList(state).map(({ slug, title }) => ({ slug, title }));
export const getExamplesButtonText = state => getExamples(state).buttonText;
export const getExamplesSmallText = state => getExamples(state).smallText;
export const getExamplesOfferText = state => getExamples(state).offerText;
export const getExamplesButtonAlt = state => getExamples(state).buttonAlt;

export const getHowToBlock = state => getAppPageContent(state).howTo;
export const getHowToHeading = state => getHowToBlock(state).heading;
export const getHowToList = state => getHowToBlock(state).list || blankArr;

export const getVideos = state => getAppPageContent(state).videos || blankArr;

export const getCorporateAd = state => getAppPageContent(state).corporateAd || blankObj;
export const getCorporateAdTitle = state => getCorporateAd(state).title;
export const getCorporateAdText = state => getCorporateAd(state).text;
export const getCorporateAdImage = state => getCorporateAd(state).imageSrc;
export const getCorporateAdButtonText = state => getCorporateAd(state).buttonText;

export const getUseCases = state => getAppPageContent(state)['use-cases'] || blankObj;
export const getUseCasesHeading = state => getUseCases(state).heading;
export const getUseCasesFullList = state => getUseCases(state).list || blankArr;
export const getPagination = state => getAppCommonContent(state).pagination || blankObj;
export const getPaginationShowMoreButton = state => getPagination(state).showMoreButton;
export const getPaginationNextButton = state => getPagination(state).nextButton;
export const getPaginationPrevButton = state => getPagination(state).prevButton;

export const getUseCasesList = createSelector(
  [getFilters, getUseCasesFullList],
  (filters, fullList) => {
    if (filters.length > 0) {
      return mapUseCases(mapListByFilters(fullList, filters));
    }

    return mapUseCases(fullList);
  },
);
