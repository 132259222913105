import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import CustomPropTypes from 'utils/prop-types';


/**
 * Factory for creating Tab components, by applying custom styles,
 * @param styles dictionary of class: hashClass created by cssModules
 * @param displayName created component name
 * @param customContainerClass additional class for container to override parents class
 * @returns a two element array of [TabsList, TabsItem] components
 */
export default function tabViewGroupFactory(styles, displayName = '', customContainerClass) {
  const cx = classNames.bind(styles);

  function TabsList({ children, className }) {
    return (
      <div
        className={cx('container', customContainerClass, className)}
        role="menu"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }

  TabsList.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  TabsList.displayName = `${displayName}TabsList`;


  function TabsItem({ children, href, isActive, onClick, prefix, postfix, count, className, domNodeRef }) {
    const TagName = href ? 'a' : 'div';
    return (
      <TagName
        className={cx('component', { active: isActive }, className)}
        href={href}
        onClick={onClick}
        ref={domNodeRef}
        role="menuitem"
        tabIndex={0}
      >
        {prefix ? (
          <span className={cx('prefix')}>{prefix}</span>
        ) : null}
        <span className={cx('content')}>{children}</span>
        {count ? (
          <span className={cx('count')}>{count}</span>
        ) : null}
        {postfix ? (
          <span className={cx('postfix')}>{postfix}</span>
        ) : null}
      </TagName>
    );
  }

  TabsItem.propTypes = {
    children: PropTypes.node,
    prefix: PropTypes.node,
    postfix: PropTypes.node,
    count: PropTypes.number,
    isActive: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    domNodeRef: CustomPropTypes.ref,
  };

  TabsItem.displayName = `${displayName}TabsItem`;

  return [
    TabsList,
    TabsItem,
  ];
}
