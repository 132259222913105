import { connect } from 'react-redux';
import { compose } from 'redux';

import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { getMainBannerHeading, getMainBannerText, getMainBannerDescription } from 'store/page/selectors';
import {
  getBenefitsList,
  getBenefitsHeading,
  getBenefitsText,
  getExamplesTabs,
  getUseCasesHeading,
} from 'store/content';
import {
  AuthType,
  getActiveSteps,
  getExampleSegmentAuthStep,
  getExampleSegmentAuthSlug,
  getImageUrl,
} from 'store/common/exampleAuth/segmentPage';
import {
  setExampleSegmentAuthSlug,
  setExampleSegmentAuthStep,
} from 'store/common/exampleAuth/segmentPage/actions';
import { getFilters, removeFilter } from 'store/filters';

import { withRenderFlag } from 'enhancers';

import { postfixImageName } from 'utils/formatting';

import { Step } from 'components';
import { ExampleBgLayout, StepsComponent, ExampleAuthTabsComponent } from 'shared';
import { RHeading, RSubHeading, RText } from 'shared/Connected';
import { BenefitsComponent } from '../Benefits';
import { UseCasesComponent } from '../UseCases';

export const MainBannerHeading = connect((state) => ({
  size: 1,
  children: getMainBannerHeading(state),
}))(RHeading);

export const MainBannerText = connect((state) => ({
  children: getMainBannerText(state),
  isMedium: !isScreenBelow800(state),
}))(RText);

export const MainBannerDescription = connect((state) => ({
  children: getMainBannerDescription(state),
  isMedium: true,
}))(RSubHeading);

export const Benefits = connect((state) => ({
  list: getBenefitsList(state),
  heading: getBenefitsHeading(state),
  description: getBenefitsText(state),
  isMobile: isScreenBelow800(state),
}))(BenefitsComponent);

export const UseCases = compose(
  connect((state) => ({
    heading: getUseCasesHeading(state),
    filters: getFilters(state),
    isMobile: isScreenBelow800(state),
    shouldRender: !!getUseCasesHeading(state),
  }), {
    onFilterClick: removeFilter,
  }),
  withRenderFlag,
)(UseCasesComponent);

export const Image = connect((state) => {
  const props = {
    isMobile: isScreenBelow800(state),
    slug: getExampleSegmentAuthSlug(state),
    step: getExampleSegmentAuthStep(state),
  };

  let imageUrl = getImageUrl(state);
  const needFormatImageName = imageUrl
    && props.isMobile
    && (props.step === 2 || (props.step === 3 && props.slug === AuthType.PIN))
    && (props.slug !== AuthType.FILL);

  if (needFormatImageName) {
    imageUrl = postfixImageName(imageUrl, '@mobile');
  }

  return {
    ...props,
    imageUrl,
  };
})(ExampleBgLayout);

export const Steps = connect((state) => ({
  isTiny: isScreenBelow800(state),
  activeStepIndex: getExampleSegmentAuthStep(state),
  steps: getActiveSteps(state),
}))(StepsComponent);

export const ExampleAuthTabs = connect((state) => ({
  tabs: getExamplesTabs(state),
  activeExampleSlug: getExampleSegmentAuthSlug(state),
}), {
  onActiveChange: setExampleSegmentAuthSlug,
})(ExampleAuthTabsComponent);

export const ExampleStep = connect((state, { step }) => ({
  isActive: step === getExampleSegmentAuthStep(state),
  isTiny: true,
}), (dispatch, { step, title, text }) => ({
  onClick: () => {
    dispatch(setExampleSegmentAuthStep(step));
  },
}))(Step);
