import { axiosBaseRequest } from '../helpers';
import { composeSaveDataScenarioUrl, composeStartScenarioUrl, telesaleUrl } from './constants';


export const startScenarioRequest = () => axiosBaseRequest({
  url: composeStartScenarioUrl(),
});

export const saveDataScenarioRequest = ({ workflowId, name, email, company, siteAddress, phone, captcha }) =>
  axiosBaseRequest({
    url: composeSaveDataScenarioUrl({ workflowId, name, email, company, siteAddress, phone, captcha }),
  });

export const sendConsultationRequest = (data) => axiosBaseRequest({
  method: 'POST',
  url: telesaleUrl,
  data,
});
