import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getUseCasesList } from 'store/content';

import { usePagination } from 'hooks';

import { scrollTo } from 'utils/dom-helpers';

import { USE_CASES_BLOCK } from 'constants';

import { Pagination } from 'components';

const AccordionComponent = ({ list, children }) => {
  const { visibleItems, handlePageClick, handlePrevClick, handleNextClick, ...restProps } = usePagination(list);

  const onPageClick = useCallback((page) => {
    scrollTo(USE_CASES_BLOCK);
    handlePageClick(page);
  }, []);

  const onNextClick = useCallback(() => {
    scrollTo(USE_CASES_BLOCK);
    handleNextClick();
  }, []);

  const onPrevClick = useCallback(() => {
    scrollTo(USE_CASES_BLOCK);
    handlePrevClick();
  }, []);

  return (
    <>
      {visibleItems.map(children)}

      <Pagination
        className="mart-l"
        visibleItems={visibleItems}
        shouldRender={list.list.length > 1}
        pagesNumbers={list.pagesNumbers}
        handlePageClick={onPageClick}
        handleNextClick={onNextClick}
        handlePrevClick={onPrevClick}
        {...restProps}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  list: getUseCasesList(state),
});

AccordionComponent.propTypes = {
  list: PropTypes.shape({
    list: PropTypes.array,
    pagesNumbers: PropTypes.array,
  }),
  children: PropTypes.any,
};

export const Accordion = connect(mapStateToProps)(AccordionComponent);
