import { connect } from 'react-redux';

import { ErrorTemplate } from '../../ErrorTemplate';

const errorTexts = {
  heading: 'Что-то пошло не так',
  text: 'Повторите попытку позже',
  buttonText: 'Вернуться на главную',
};

export const Error = connect(() => ({ content: errorTexts }))(ErrorTemplate);
