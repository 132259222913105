import { axiosBaseRequest } from 'requests/helpers';
import { getAuthMobileIdUrl, getAuthMobileIdStatusUrl, checkAuthStatusUrl } from './constants';
import { AUTH_MOBILE_ID_STATUSES, AUTH_ERRORS } from 'constants';

export const authMobileIdRequest = ({ phone }) => axiosBaseRequest({
  url: getAuthMobileIdUrl(),
  method: 'POST',
  data: new URLSearchParams({ phone, agreement: 'true' }),
})
  .then(({ data }) => data);

export const getAuthMobileIdStatusRequest = ({ operationId }) => axiosBaseRequest({
  url: getAuthMobileIdStatusUrl(),
  params: { operationId },
})
  .then((res) => {
    if (res.status === 200) return AUTH_MOBILE_ID_STATUSES.success;
    if (res.status === 204) return AUTH_MOBILE_ID_STATUSES.retry;

    return AUTH_MOBILE_ID_STATUSES.error;
  })
  .catch(({ response }) => {
    if (response.data.error === AUTH_ERRORS.pushDenied) return AUTH_MOBILE_ID_STATUSES.pushDenied;

    return AUTH_MOBILE_ID_STATUSES.error;
  });

export const checkAuthStatus = () => axiosBaseRequest({ url: checkAuthStatusUrl })
  .then(() => ({ isEmployee: true, isAuthenticated: true }))
  .catch((err) => ({ isEmployee: false, isAuthenticated: err?.response?.status === 400 }));
