import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Arrow from './arrow.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Controls = ({ setPrevFrame, setNextFrame }) => (
  <>
    <button className={cx('button', 'prev')} onClick={setPrevFrame}>
      <Arrow />
    </button>
    <button className={cx('button', 'next')} onClick={setNextFrame}>
      <Arrow />
    </button>
  </>
);

Controls.propTypes = {
  setPrevFrame: PropTypes.func,
  setNextFrame: PropTypes.func,
};
