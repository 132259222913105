import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { Text } from 'components/Text';
import { Button } from 'components/Button';
import commonStyles from 'styles/common.pcss';

import AttentionIcon from '../../assets/attention.svg';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const Error = ({
  className,
  onClick,
  heading,
  subTitle,
  btnText,
}) => (
  <div className={cx('wrap', className)}>
    <AttentionIcon />
    <Text isTitle isLarge className={cx('mart-xxs')}>
      {heading}
    </Text>
    {subTitle && (
      <Text isSmall className={cx('mart-xxs')}>
        {subTitle}
      </Text>
    )}
    {onClick && (
      <Button isWide isOnlyText className={cx('mart-s')} onClick={onClick}>
        {btnText}
      </Button>
    )}
  </div>
);

Error.propTypes = {
  className: PropTypes.any,
  onClick: PropTypes.func,
  heading: PropTypes.string,
  subTitle: PropTypes.string,
  btnText: PropTypes.string,
};

export default Error;
