import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { SERVICE_TITLE } from 'constants';
import commonStyles from 'styles/common.pcss';
import { formatPhone } from 'utils/formatting';
import { hideExampleAuthModal } from 'store/common/exampleAuth/mainPage/actions';
import {
  getExampleAuthCallback, getExampleAuthErrorHeading, getExampleAuthErrorMessage, getExampleAuthModalPatronymicLabel,
  getExampleAuthModalDescription, getExampleAuthModalMiddleNameLabel, getExampleAuthModalNameLabel,
} from 'store/common/exampleAuth/mainPage/selectors';
import { BasePopupStateless, TextInput, Text } from 'components';
import { RHeading, RSubHeading, RText } from '../Connected';
import { ExampleAuthModalButton, ExampleAuthModalHeading, ExampleAuthModalText } from './components/Connected';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


const ExampleAuthModal = ({
  ctn,
  name,
  patronymic,
  error,
  nameLabel,
  middleNameLabel,
  patronymicLabel,
  errorHeading,
  errorMessage,
  onClose,
  description,
}) => {
  useEffect(() => {
    if (error) return;

    sessionStorage.removeItem(SERVICE_TITLE);
  }, []);

  return (
    <BasePopupStateless isOpen onClose={onClose} className={cx('modal')}>
      {error && (
        <div className={cx('content')}>
          <RHeading className={cx('marb-s')}>{errorHeading}</RHeading>
          <RText className={cx('marb-l')}>
            {errorMessage}
          </RText>
          <ExampleAuthModalButton />
        </div>
      ) || name && (
        <div className={cx('content')}>
          <div className={cx('form')}>
            <TextInput value={name} label={nameLabel} editable={false} isColumn className={cx('input', 'marb-m')} />
            {patronymic && (
              <TextInput
                value={patronymic}
                label={middleNameLabel || patronymicLabel}
                editable={false}
                isColumn
                className={cx('input')}
              />
            )}
            <Text isSmall isBold700 className={cx('mart-m')}>
              {description}
            </Text>
          </div>
        </div>
      ) || (
        <div className={cx('content')}>
          <ExampleAuthModalHeading className={cx('marb-l')} />
          <ExampleAuthModalText className={cx('marb-xxs')} />
          <RSubHeading className={cx('marb-l')}>
            {formatPhone(ctn.slice(1), '+7 (___) ___-__-__')}
          </RSubHeading>
          <ExampleAuthModalButton />
        </div>
      )}
    </BasePopupStateless>
  );
};

ExampleAuthModal.propTypes = {
  name: PropTypes.string,
  patronymic: PropTypes.string,
  ctn: PropTypes.string,
  nameLabel: PropTypes.node,
  middleNameLabel: PropTypes.node,
  patronymicLabel: PropTypes.string,
  error: PropTypes.string,
  errorHeading: PropTypes.node,
  errorMessage: PropTypes.node,
  onClose: PropTypes.func,
  description: PropTypes.string,
};

const mapStateToProps = state => {
  const { ctn, patronymic, name, error } = getExampleAuthCallback(state);
  return {
    ctn,
    patronymic,
    name,
    nameLabel: getExampleAuthModalNameLabel(state),
    description: getExampleAuthModalDescription(state),
    middleNameLabel: getExampleAuthModalMiddleNameLabel(state),
    patronymicLabel: getExampleAuthModalPatronymicLabel(state),
    error,
    errorHeading: getExampleAuthErrorHeading(state),
    errorMessage: getExampleAuthErrorMessage(state),
  };
};

const mapDispatchToProps = ({
  onClose: hideExampleAuthModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(ExampleAuthModal);
