import React from 'react';

import { useEventListener } from 'hooks';


/**
 * HOC has one tiny purpose - it disables click event that happens after ScrollContainer has been dragged
 * Reason: raw event listeners are required to stop nativeEvent propagation
 */
export default function withScrollClickSuppression(WrappedScrollContainer) {
  function ScrollClickSuppressor(props) {
    const { domNodeRef } = props;
    let isScroll = false;

    const handleClick = (event) => {
      if (isScroll) {
        event.stopPropagation();
        event.preventDefault();
      }
    };

    const handleScrollEnd = (...args) => {
      setTimeout(() => { isScroll = false; });
      if (props.UNSAFE_onScrollEnd) props.UNSAFE_onScrollEnd(...args);
    };

    const handleScroll = (opts) => {
      const { movement } = opts;
      if (Math.abs(movement[0]) > 2 || Math.abs(movement[1] > 2)) isScroll = true;
      if (props.UNSAFE_onScroll) props.UNSAFE_onScroll(opts);
    };

    useEventListener('click', handleClick, domNodeRef);

    return (
      <WrappedScrollContainer
        {...props}
        UNSAFE_onScroll={handleScroll}
        UNSAFE_onScrollEnd={handleScrollEnd}
      />
    );
  }

  ScrollClickSuppressor.propTypes = {
    ...WrappedScrollContainer.propTypes,
  };

  if (process.env.NODE_ENV !== 'production') {
    ScrollClickSuppressor.displayName = 'ScrollClickSuppressor';
  }

  return ScrollClickSuppressor;
}
