import { AuthType } from 'store/common/exampleAuth/mainPage/constants';
import { getAuthMethodBySlug } from './helpers';
import { getReturnUrlBySlug } from '../constants';

let B2B_API_URL = '/b2b/api/v1';
if (/localhost/.test(window.location.hostname)) B2B_API_URL = '/api/v1';


const getApiUrlPair = () => {
  if (/localhost/.test(window.location.hostname)) return ['/api', '/api'];
  if (/beelinetst/.test(window.location.hostname)) {
    return ['http://apipromo.beelinetst.ru', 'http://mobileid-auth.beelinetst.ru/api/v1'];
  }
  if (/test.beedgtl/.test(window.location.hostname)) {
    return ['http://apipromo.beelinetst.ru', 'http://mobileid-auth.test.beedgtl.ru/api/v1'];
  }
  return ['https://apipromo.beeline.ru', 'https://mobileid-auth.beeline.ru/api/v1'];
};

export const getUrlForRedirectToB2B = () => {
  if (/localhost/.test(window.location.hostname)) {
    return 'http://localhost:8080';
  }
  if (/beelinetst/.test(window.location.hostname)) {
    return 'http://mobileid.beelinetst.ru/b2b/';
  }
  if (/mobileiduat/.test(window.location.hostname)) {
    return 'https://mobileiduat.beeline.ru/b2b/';
  }
  if (/b2blanding\.prod\.beedgtl/.test(window.location.hostname)) {
    return 'http://b2blanding.prod.beedgtl.ru/b2b/';
  }
  if (/beedgtl/.test(window.location.hostname)) {
    return 'http://b2blanding.test.beedgtl.ru/b2b/';
  }

  return 'https://mobileid.beeline.ru/b2b/';
};

export const [PROMO_API_URL, API_URL] = getApiUrlPair();

export const composeStartScenarioUrl = () => `${PROMO_API_URL}/mobileid/start?alias=mobileid`;

export const composeSaveDataScenarioUrl = ({ workflowId, name, email, phone, company, siteAddress, captcha }) => (
  `${PROMO_API_URL}/mobileid/saveData?alias=mobileid&workflowId=${workflowId}` +
  `${(name ? `&name=${name}` : '')}` +
  `${(email ? `&email=${email}` : '')}` +
  `${(phone ? `&phone=${phone}` : '')}` +
  `${(company ? `&company=${company}` : '')}` +
  `${(siteAddress ? `&siteAddress=${siteAddress}` : '')}` +
  `${(captcha ? `&captchaKey=familySafety&captcha=${captcha}` : '')}`
);

export const composeCaptchaUrl = () => `${PROMO_API_URL}/captcha.ashx?w=183&h=54&key=mobileid&r=${Math.random()}`;

export const composeMobileIDAuthFrameUrl = ({ slug, locale }) =>
  `${API_URL}/saleskit?redirectUrl=${encodeURIComponent(getReturnUrlBySlug(slug))}` +
  `&lang=${locale}&type=${getAuthMethodBySlug(slug)}` +
  `${(slug === AuthType.FILL ? '&fields=name&fields=patronymic' : '')}`;

export const getAuthMobileIdUrl = () => `${B2B_API_URL}/auth/mobileid/`;
export const getAuthMobileIdStatusUrl = () => `${B2B_API_URL}/auth/mobileid/result/`;
export const checkAuthStatusUrl = `${B2B_API_URL}/auth/isemployee/`;
export const telesaleUrl = `${B2B_API_URL}/telesale`;
export const getReactModelUrl = () => `${B2B_API_URL}/contents/reactModel`;
