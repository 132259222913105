import { createSelector } from '@reduxjs/toolkit';
import { getIsAuth } from '../../options/selectors';
import { getAppCommonContent, getAppCommons } from '../../selectors';

export const getB2BPopupData = state => getAppCommonContent(state).b2bAuth;
export const isPopupOpen = state => getAppCommons(state).b2bAuth?.isOpen;

export const getHeadingText = state => getB2BPopupData(state)?.heading;
export const getInputLabel = state => getB2BPopupData(state)?.inputLabel;
export const getProfileLoginText = state => getB2BPopupData(state)?.profileLogin;
export const getButtonText = state => getB2BPopupData(state)?.buttonText;
export const getAuthLoaderText = state => getB2BPopupData(state)?.authLoaderText;
export const getAuthTryAgainText = state => getB2BPopupData(state)?.tryAgainText;
export const getAgreementText = state => getB2BPopupData(state)?.dataProcessingAgreement;
export const getTooltipText = state => getB2BPopupData(state)?.tooltipMessage;

export const getLoginText = createSelector(
  [getIsAuth, getButtonText, getProfileLoginText],
  (isAuth, buttonText, loginText) => (isAuth ? loginText : buttonText),
);
