import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { getIsSegmentsMenuOpen } from 'store/common/menu';
import { getSharedComponentsReady } from 'store/common/selectors';

import { withRenderFlag } from 'enhancers';

import { Wrapper } from 'components/Wrapper';
import { Segments } from '../Segments';
import { Logos, LoginButton } from './components';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const Header = ({ isMobile, isMenuOpen, className }) => (
  <div className={cx('component', className, { 'menu-open': isMenuOpen, mobile: isMobile })}>
    <Wrapper className={cx('container', 'padt-m', 'padb-xs')}>
      <div className={cx('partial-container')}>
        <Logos />

        {!isMobile && <Segments className={cx('segments')} />}
      </div>
      <div className={cx('partial-container')}>
        {isMobile && <Segments className={cx('segments')} />}

        <LoginButton />
      </div>
    </Wrapper>
  </div>
);

Header.propTypes = {
  isMobile: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  isMobile: isScreenBelow800(state),
  isMenuOpen: getIsSegmentsMenuOpen(state),
  shouldRender: getSharedComponentsReady(state),
});

export default compose(
  connect(mapStateToProps),
  withRenderFlag,
)(Header);
