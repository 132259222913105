import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function Flex({ children, isInline, isColumn, align, isDoubleSpacing, className }) {
  return (
    <div
      className={cx('container', `${align}Align`, className, {
        inline: isInline, doubleSpacing: isDoubleSpacing, column: isColumn,
      })}
    >
      {React.Children.map(children, (child, index) => (
        <div key={+index} className={cx('item')}>{child}</div>
      ))}
    </div>
  );
}

Flex.propTypes = {
  children: PropTypes.node,
  isInline: PropTypes.bool,
  isColumn: PropTypes.bool,
  isDoubleSpacing: PropTypes.bool,
  align: PropTypes.oneOf(['top', 'bottom']),
  className: PropTypes.string,
};
