import { connect } from 'react-redux';

import { getHeadingText, getInputLabel, getButtonText, getAuthLoaderText } from 'store/common/b2bAuth/selectors';
import { RButton, RHeading, RText } from '../../../Connected';

export const ModalHeading = connect(state => ({
  children: getHeadingText(state),
}))(RHeading);

export const InputLabel = connect(state => ({
  children: getInputLabel(state),
}))(RText);

export const ModalButton = connect(state => ({
  isOnlyText: true,
  children: getButtonText(state),
}))(RButton);

export const AuthLoaderText = connect(state => ({
  children: getAuthLoaderText(state),
}))(RText);
