export const BlockSlugs = {
  BENEFITS: 'benefits',
  BENEFITS_CLIENT: 'benefitsClient',
  BENEFITS_BUSINESS: 'benefitsBusiness',
  CORPORATE_AD: 'corporateAd',
  EXAMPLES: 'examples',
  TARIFFS: 'tariffs',
  HOW_TO: 'howTo',
  DOCS: 'docs',
  USED_BY: 'usedBy',
  FAQ: 'faq',
};
export const BlockSlugList = [
  'benefits',
  // 'benefitsClient',
  // 'benefitsBusiness',
  'corporateAd',
  'examples',
  /* https://task.beedgtl.ru/browse/MOBID-1426 p.1 */
  // 'docs',
  'howTo',
  'tariffs',
  'usedBy',
  'faq',
];
