import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { getLocale } from 'requests/helpers';

const defaultTitle = getLocale() === 'en'
  ? 'Mobile ID - secure and convenient way to authorize and autofill forms'
  : 'Мобильный ID для безопасной и простой авторизации и автозаполнения форм';

export const Meta = ({ title, canonical, children }) => (
  <Helmet defaultTitle={defaultTitle}>
    {title && <title>{title}</title>}
    <link rel="canonical" href={canonical || window.location.href} />
    {children}
  </Helmet>
);

Meta.propTypes = {
  title: PropTypes.string,
  canonical: PropTypes.string,
  children: PropTypes.any,
};
