import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { Wrapper } from 'components/Wrapper';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });


const MainBannerLayout = ({ children, top, desktopBg, isMobile, className }) => (
  <div className={cx('container', className)}>
    <div className={cx('top')}>
      {top}
    </div>
    <div className={cx('content', 'padt-xl')}>
      {!isMobile && desktopBg}
      <Wrapper>
        <div className={cx('contentIn')}>
          {children}
        </div>
      </Wrapper>
    </div>
  </div>
);

MainBannerLayout.propTypes = {
  children: PropTypes.node,
  top: PropTypes.node,
  isMobile: PropTypes.bool,
  desktopBg: PropTypes.node,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isMobile: isScreenBelow800(state),
});

export const MainBanner = connect(mapStateToProps)(MainBannerLayout);
