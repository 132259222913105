import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ListItem = ({ iconSrc, text, isMobile, className }) => (
  <li className={cx('component', className)}>
    <div className={cx('icon')}>
      <img
        width={isMobile ? '46px' : '63px'}
        height={isMobile ? '46px' : '63px'}
        src={iconSrc}
        alt=""
      />
    </div>

    <Text>
      {text}
    </Text>
  </li>
);

ListItem.propTypes = {
  iconSrc: PropTypes.string,
  text: PropTypes.string,
  isMobile: PropTypes.bool,
  className: PropTypes.any,
};
