import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ScrollWrapper } from 'shared';
import { Heading, Wrapper } from 'components';
import { Benefit } from './Benefit';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

export const BenefitsComponent = ({ heading, list, isMobile, className }) => (
  <Wrapper className={className}>
    <Heading className={cx('marb-l')} isMobile={isMobile}>
      {heading}
    </Heading>
    <ScrollWrapper removeWrapper={false}>
      {list?.map((el, idx) => (
        <Benefit
          key={idx.toString()}
          {...el}
          isMobile={isMobile}
          className={cx('benefit')}
        />
      ))}
    </ScrollWrapper>
  </Wrapper>
);

BenefitsComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  list: PropTypes.any,
  heading: PropTypes.string,
  isMobile: PropTypes.bool,
};

