import React from 'react';
import PropTypes from 'prop-types';

import { useExistingRef } from 'hooks';
import { TabsConsumer, TabsProvider } from '../context';


/**
 * HOC for TabsItem to bind it to TabsList
 * Passes all the container props, except for `children` and `className` down to TabsItems
 * @returns same array extended with context
 */
export default function withTabContext([WrappedTabsList, WrappedTabsItem]) {
  function ProviderTabsList({ children, className, ...otherProps }) {
    const itemsRef = useExistingRef(otherProps.itemsRef, {});

    return (
      <TabsProvider value={{ itemsRef, ...otherProps }}>
        <WrappedTabsList {...otherProps} className={className}>{children}</WrappedTabsList>
      </TabsProvider>
    );
  }

  ProviderTabsList.propTypes = {
    type: PropTypes.string,
    overflow: PropTypes.string,
    activeId: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    // TODO: add more prop types
  };

  /**
   * Override component name by enclosing in `withContext(~)`
   * to make it look nice, for example: `withContext(TabsList)`
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedTabsList.displayName || WrappedTabsList.name || 'TabsList';
    ProviderTabsList.displayName = `withContext(${WrappedComponentName})`;
  }

  /**
   * Flexible context prop passing, container props are used and overridden by item props,
   * except for `activeId` and `onChange`, these props used to generate to onClick and isActive
   */
  function ConnectedTabsItem({ id, href, isActive, ...inheritedProps }) {
    /**
      * if isActive is passed we use it to determine activity,
      * otherwise activity is determined by context
      */
    const isTabActive = (activeId) => {
      if (typeof isActive === 'boolean') return isActive;
      if (typeof activeId !== 'undefined') return activeId === id;
      return false;
    };
    return (
      <TabsConsumer>
        {({ activeId, onChange, itemsRef, ...contextProps }) => {
          console.assert(
            (typeof activeId === 'undefined') || (typeof isActive === 'undefined'),
            'Only one activity controller can be used, either use isActive on tabs or activeId on tab container',
          );
          return (
            <WrappedTabsItem
              {...contextProps}
              {...inheritedProps}
              {...{ href }}
              onClick={(e) => {
                e.preventDefault();
                onChange(id, href);
              }}
              domNodeRef={(el) => { itemsRef.current[id] = el; }}
              isActive={isTabActive(activeId)}
            />
          );
        }}
      </TabsConsumer>
    );
  }

  ConnectedTabsItem.propTypes = {
    id: PropTypes.any,
    href: PropTypes.string,
    isActive: PropTypes.bool,
    // TODO: add more prop types
  };

  /**
   * Override component name by enclosing in `withContext(~)`
   * to make it look nice, for example: `withContext(TabsItem)`
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedTabsItem.displayName || WrappedTabsItem.name || 'TabsItem';
    ConnectedTabsItem.displayName = `withContext(${WrappedComponentName})`;
  }

  return [
    ProviderTabsList,
    ConnectedTabsItem,
  ];
}
