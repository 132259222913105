import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import * as BlockItem from 'store/page/blockItems/selectors';
import { RCol, ScrollWrapper } from 'shared';
import { BenefitItem } from '../BenefitItem';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

function Component({ title, list }) {
  return (
    <div className={cx('items')}>
      {title}
      <ScrollWrapper>
        {list?.map((el, idx) => (
          <RCol isFull key={idx.toString()}>
            <BenefitItem isMobile {...el} />
          </RCol>
        ))}
      </ScrollWrapper>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  list: PropTypes.any,
  title: PropTypes.node,
  renderWrapper: PropTypes.func,
};

const mapStateToProps = (state) => ({
  list: BlockItem.getList(state),
});


export const BenefitsContainer = BlockItem.connect(mapStateToProps)(Component);
