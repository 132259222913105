import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getMainBannerBg } from 'store/page/selectors';

import { Meta } from 'shared/Meta';

export const withPreloadMedia = (WrappedComponent) => {
  const Component = ({ bgUrl, ...restProps }) => (
    <>
      <Meta>
        <link
          rel="preload"
          as="image"
          href={bgUrl}
        />
      </Meta>

      <WrappedComponent {...restProps} />
    </>
  );

  const mapStateToProps = (state) => ({
    bgUrl: getMainBannerBg(state),
  });

  Component.propTypes = {
    bgUrl: PropTypes.string,
  };

  return connect(mapStateToProps)(Component);
};
