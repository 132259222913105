import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function SubHeading({
  children, isPrimary, isSecondary,
  isLarge, isMedium, isInline, isInverse, className,
}) {
  const Tag = isInline ? 'span' : 'div';
  return (
    <Tag
      className={cx('component', className, {
        primary: isPrimary,
        secondary: isSecondary,
        small: isLarge,
        inverse: isInverse,
        medium: isMedium,
      })}
      {...(typeof children !== 'string' ? { children } : {
        dangerouslySetInnerHTML: { __html: children },
      })}
    />
  );
}

SubHeading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isLarge: PropTypes.bool,
  isInline: PropTypes.bool,
  isMedium: PropTypes.bool,
  isInverse: PropTypes.bool,
  className: PropTypes.string,
};
