import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const usePagination = ({ list }) => {
  // Номер текущей страницы
  const [currentPage, setCurrentPage] = useState(1);
  // Видимые элементы
  const [visibleItems, setVisibleItems] = useState(list[currentPage - 1]);
  // Количество оставшихся элементов
  const amountOfItemsLeft = useMemo(() => list[currentPage]?.length || 0, [list, currentPage]);
  const showMoreClick = useRef(false);

  useEffect(() => {
    if (showMoreClick.current) {
      setVisibleItems((prev) => [...prev, ...list[currentPage - 1]]);
      showMoreClick.current = false;
      return;
    }

    if (!list[currentPage - 1]) {
      setCurrentPage(1);
      return;
    }

    setVisibleItems(list[currentPage - 1]);
  }, [list, currentPage, showMoreClick]);

  const setNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleShowMoreClick = useCallback(() => {
    showMoreClick.current = true;
    setNextPage();
  }, []);

  const handlePrevClick = useCallback(() => {
    setCurrentPage((prev) => prev - 1);
  }, []);

  const handleNextClick = useCallback(() => {
    setNextPage();
  }, []);

  const handlePageClick = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  return {
    visibleItems,
    amountOfItemsLeft,
    handleShowMoreClick,
    handlePrevClick,
    handleNextClick,
    handlePageClick,
    currentPage,
  };
};
