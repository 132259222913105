import { createSelector } from 'reselect';
import { blankArr } from 'utils/fn';
import { composeContextSelectorGroup } from 'utils/redux';
import { BlockItemContext } from './context';
import * as Blocks from '../blocks/selectors';
import { getExampleAuthSlug } from '../../common/exampleAuth/mainPage/selectors';
import { BlockSlugs } from '../../constants';
import { getExampleCorporateAuthSlug } from '../../common/exampleAuth/corporateAuth';
import { getExampleSegmentAuthSlug } from '../../common/exampleAuth/segmentPage';


export const { connect, getReduced } = composeContextSelectorGroup(
  BlockItemContext,
  Blocks.getItems,
  'slug',
);

export const getSlug = getReduced(data => data.slug);
export const getTitle = getReduced(data => data.title);
export const getText = getReduced(data => data.text);
export const getImageUrl = getReduced(data => data.imageUrl);
export const getPrice = getReduced(data => data.price);
export const getExtra = getReduced(data => data.extra);
export const getType = getReduced(data => data.type);
export const getAttachmentUrl = getReduced(data => data.attachmentUrl);
export const getSteps = getReduced(data => data.steps || blankArr);
export const getList = getReduced(data => data.list || []);


export const getExampleAuthStepsCount = createSelector(
  getSteps,
  getExampleAuthSlug,
  (blockTabStepsDict, slug) => (blockTabStepsDict[BlockSlugs.EXAMPLES][slug] || []).length,
);

export const getExampleCorporateAuthStepsCount = createSelector(
  getSteps,
  getExampleCorporateAuthSlug,
  (blockTabStepsDict, slug) => (blockTabStepsDict[BlockSlugs.EXAMPLES][slug] || []).length,
);

export const getExampleSegmentAuthStepsCount = createSelector(
  getSteps,
  getExampleSegmentAuthSlug,
  (blockTabStepsDict, slug) => (blockTabStepsDict[BlockSlugs.EXAMPLES][slug] || []).length,
);
