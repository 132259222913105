export const decodeAuthCallback = (query) => {
  if (!query) return null;
  try {
    const search = decodeURIComponent(escape(window.atob(query)));
    const urlParams = new URLSearchParams(search);

    return {
      ctn: urlParams.get('ctn') || '',
      name: urlParams.get('name'),
      patronymic: urlParams.get('patronymic'),
      error: urlParams.get('error'),
      error_description: urlParams.get('error_description'),
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};
