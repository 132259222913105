import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { withRenderFlag } from 'enhancers';

import { isPopupOpen } from 'store/common/telesalesResult/selectors';
import { closeTelesalesPopup } from 'store/common/telesalesResult/actions';

import { BasePopupStateless } from 'components';
import { ModalHeading, ModalButton, ModalText } from './Connected';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const TelesalesResultModal = ({ onClose }) => (
  <BasePopupStateless isOpen onClose={onClose} className={cx('modal')}>
    <div className={cx('content')}>
      <ModalHeading className={cx('text')} />
      <ModalText className={cx('text')} />
      <ModalButton isWide onClick={onClose} />
    </div>
  </BasePopupStateless>
);

const mapStateToProps = (state) => ({
  shouldRender: isPopupOpen(state),
});

const mapDispatchToProps = {
  onClose: closeTelesalesPopup,
};

TelesalesResultModal.propTypes = {
  shouldRender: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRenderFlag(TelesalesResultModal));
