import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function Text({
  children, isLegal, isItalic, isPrimary, isSecondary,
  isBold500, isBold700, isBolder, isMedium, dispatch,
  isSmall, isSmaller, isLarge, isInline, isInverse, className,
  __nestedContextAt0,
  ...otherAttributes
}) {
  const Tag = isInline ? 'span' : 'div';
  if (isItalic) throw new Error('Italic font-style is not supported');
  return (
    <Tag
      className={cx('component', className, {
        legal: isLegal,
        primary: isPrimary,
        secondary: isSecondary,
        large: isLarge,
        medium: isMedium,
        small: isSmall,
        smaller: isSmaller,
        italic: isItalic,
        inverse: isInverse,
        bold500: isBold500,
        bold700: isBold700,
        bolder: isBolder,
      })}
      {...(typeof children !== 'string' ? { children } : {
        dangerouslySetInnerHTML: { __html: children },
      })}
      {...otherAttributes}
    />
  );
}

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isLegal: PropTypes.bool,
  isItalic: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSmaller: PropTypes.bool,
  isMedium: PropTypes.bool,
  isLarge: PropTypes.bool,
  isInline: PropTypes.bool,
  isInverse: PropTypes.bool,
  className: PropTypes.string,
  isBold500: PropTypes.bool,
  isBold700: PropTypes.bool,
  isBolder: PropTypes.bool,
  // TODO: докопаться до истоков этих пропсов
  dispatch: PropTypes.func,
  __nestedContextAt0: PropTypes.any,
};
