import { createReducer } from '@reduxjs/toolkit';
import { setAuthModalSource, setIsAuth, setIsEmployee } from './actions';

const initialState = {
  isAuthenticated: null,
  isEmployee: null,
  authModalSource: null,
};

export const optionsReducer = createReducer(initialState, {
  [setIsAuth.type]: (state, action) => { state.isAuthenticated = action.payload; },
  [setIsEmployee.type]: (state, action) => { state.isEmployee = action.payload; },
  [setAuthModalSource.type]: (state, action) => { state.authModalSource = action.payload; },
});
