import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Error } from './Error';

export const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary fallback={<Error />}>
    {children}
  </Sentry.ErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};
