import React from 'react';
import classNames from 'classnames/bind';

import { BasePopupStateless, Text, Heading } from 'components';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

export const ErrorModal = () => {
  const handleClose = () => { window.location.reload(); };

  return (
    <BasePopupStateless isOpen onClose={handleClose} className={cx('popup')}>
      <Heading size={3} className={cx('marb-s')}>
        Что-то пошло не так
      </Heading>
      <Text>
        Повторите попытку позже
      </Text>
    </BasePopupStateless>
  );
};
