import { createReducer } from '@reduxjs/toolkit';
import { toggleSegmentsMenu } from './actions';

const initialState = {
  isOpen: false,
};

export const segmentsMenuReducer = createReducer(initialState, {
  [toggleSegmentsMenu.type]: (state) => { state.isOpen = !state.isOpen; },
});
