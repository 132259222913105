import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import CustomPropTypes from 'utils/prop-types';
import { ignoreState } from 'utils/decorators';
import { composeFormValidator } from 'utils/field-validators';
import { flatten, filter, shallowEqual } from 'utils/fn';


export class FinalForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: props.values,
      isInitialValueChanged: false,
    };
    this.memory = { valid: null };
  }

  /**
   * `gdsfp` and `isInitialValueChanged` are required
   *  in order to skip internal changes backfiring into the system
   *  and only react to external changes
   */
  static getDerivedStateFromProps = ignoreState((nextProps, prevState) => {
    if (!shallowEqual(nextProps.values, prevState.values)) {
      return {
        values: nextProps.values,
        isInitialValueChanged: true,
      };
    }
    return { isInitialValueChanged: false };
  });

  handleChange = ({ values }) => {
    const { onChange } = this.props;
    if (!shallowEqual(values, this.state.values)) {
      this.setState({ values }, () => onChange(values));
    }
  };

  handleValidChange = ({ hasValidationErrors }) => {
    const { onValidChange } = this.props;
    const valid = !hasValidationErrors;
    if (valid !== this.memory.valid) setTimeout(() => onValidChange(valid));
    this.memory.valid = valid;
  };

  handleSubmit = (values) => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      return onSubmit(values)
        .then(() => null)
        .catch(errors => this.handleErrors(errors));
    }
    return null;
  };

  /**
   * Separate validation Errors from global errors
   * @returns {[{object validationErrors}, {array globalErrors}]}
   */
  separateValidationErrors = (errors) => {
    const { getValidators } = this.props;
    const validators = getValidators && getValidators(this.state.values) || {};

    const validationErrors = filter(errors, (value, key) => typeof validators[key] !== 'undefined');
    const globalErrors = filter(errors, (value, key) => typeof validators[key] === 'undefined');

    return [validationErrors, flatten(Object.values(globalErrors))];
  };

  /**
   * There are two types of errors:
   * - validation errors - that are listed in getValidators are returned under field name and passed into payFormFields
   * - all the other errors, passed into `errors` field and thrown by FinalForm onError callback
   */
  handleErrors = (errors) => {
    const { onError } = this.props;
    if (typeof errors === 'object' && errors !== null) {
      const [fieldValidationErrors, otherGlobalErrors] = this.separateValidationErrors(errors);

      if (onError && otherGlobalErrors.length) {
        onError(otherGlobalErrors);
      }
      return {
        ...fieldValidationErrors,
        errors: otherGlobalErrors,
      };
    }
    return null;
  };

  render() {
    const {
      onChange,
      onValidChange,
      children,
      className,
      getValidators,
      domNodeRef,
      id,
      values: _omitValues,
      ...otherFormProps
    } = this.props;
    const { values, isInitialValueChanged } = this.state;
    return (
      <Form
        {...otherFormProps}
        initialValues={values}
        validate={getValidators ? composeFormValidator(getValidators) : null}
        initialValuesEqual={() => !isInitialValueChanged}
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, name, ...formApi }) => (
          <form id={id} name={name} onSubmit={handleSubmit} className={className} noValidate ref={domNodeRef}>
            {onChange && <FormSpy subscription={{ values: true }} onChange={this.handleChange} />}
            {onValidChange && (
              <FormSpy
                subscription={{ hasValidationErrors: true }}
                onChange={this.handleValidChange}
              />
            )}
            {typeof children === 'function' ? children({ handleSubmit, ...formApi }) : children}
          </form>
        )}
      />
    );
  }
}

FinalForm.propTypes = {
  values: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onChange: PropTypes.func,
  id: PropTypes.string,
  /** fires on form render if form is initially invalid */
  onValidChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  domNodeRef: CustomPropTypes.ref,
  getValidators: PropTypes.func,
  className: PropTypes.string,
};
