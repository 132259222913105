import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { RGrid } from '../Connected';
import { RemoveWrapper, ScrollContainer, Wrapper } from 'components';

export const ScrollWrapper = ({ removeWrapper, children }) => {
  const Component = removeWrapper ? RemoveWrapper : Fragment;

  return (
    <Component>
      <ScrollContainer>
        <Wrapper>
          <RGrid noWrap>
            {children}
          </RGrid>
        </Wrapper>
      </ScrollContainer>
    </Component>
  );
};

ScrollWrapper.propTypes = {
  removeWrapper: PropTypes.bool,
  children: PropTypes.node,
};

ScrollWrapper.defaultProps = {
  removeWrapper: true,
};
