import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from 'components';

import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Overlay = ({ name, onClick, className }) => (
  <>
    <div className={cx('overlay', className)} />

    {name && (
      <Heading size={3} className={cx('name')}>
        {name}
      </Heading>
    )}

    <button className={cx('play-button')} onClick={onClick} />
  </>
);

Overlay.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
