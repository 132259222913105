import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { useSwipeable } from 'react-swipeable';

import commonStyles from 'styles/common.pcss';
import { incrementExampleAuthStep } from 'store/common/exampleAuth/mainPage/actions';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { Wrapper } from 'components/Wrapper';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


function ExampleContainerLayout({ children, tabs, media, isMobile, onIncrementStep, className }) {
  const handlers = useSwipeable({
    preventDefaultTouchmoveEvent: true,
    onSwipedLeft: () => onIncrementStep(1),
    onSwipedRight: () => onIncrementStep(-1),
  });

  return (
    <div className={cx('container', { mobile: isMobile }, className)}>
      <div className={cx('content')}>
        <Wrapper>
          <div className={cx('contentIn')}>
            <div className={cx('contentText')}>
              <div className={cx('tabs', 'marb-m')}>{tabs}</div>
              {isMobile && (
                <div className={cx('background')} {...handlers}>{media}</div>
              )}
              {children}
            </div>
            {!isMobile && <div className={cx('background')}>{media}</div>}
          </div>
        </Wrapper>
      </div>
    </div>
  );
}

ExampleContainerLayout.propTypes = {
  children: PropTypes.node,
  tabs: PropTypes.node,
  media: PropTypes.node,
  isMobile: PropTypes.bool,
  onIncrementStep: PropTypes.func,
  className: PropTypes.string,
};


const mapStateToProps = (state) => ({
  isMobile: isScreenBelow800(state),
});

const mapDispatchToProps = {
  onIncrementStep: incrementExampleAuthStep,
};

export const ExampleContainer = connect(mapStateToProps, mapDispatchToProps)(ExampleContainerLayout);
