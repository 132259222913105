import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { sendFieldAnalytics } from 'analytics';
import { EA_ERROR, EA_FOCUS, EA_SUCCESS } from 'analytics/constants';

import CustomPropTypes from 'utils/prop-types';


export function withAnalytics(WrappedInput) {
  function InputWithAnalytics({ invalid, eventLabel, name, ...otherProps }) {
    const handleAnalytics = (eventAction, e) => {
      sendFieldAnalytics({
        formName: e.target.form.name,
        event: {
          eventLabel: eventLabel || name,
          eventAction,
        },
      });
    };

    const handleFocus = (v, e) => handleAnalytics(EA_FOCUS, e);

    const handleBlur = useCallback((v, e) => {
      handleAnalytics(invalid ? EA_ERROR : EA_SUCCESS, e);
    }, [invalid]);

    return (
      <WrappedInput
        {...otherProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    );
  }

  InputWithAnalytics.propTypes = {
    ...WrappedInput.propTypes,

    /** added by the HOC */
    invalid: PropTypes.bool,
    eventLabel: PropTypes.string,
    name: PropTypes.string,

    /** is not an actual prop */
    domNodeRef: CustomPropTypes.ref,
  };

  InputWithAnalytics.defaultProps = {
    ...WrappedInput.defaultProps,
  };


  /**
   * Override component name by prepending `Contained~`
   * to make it look nice, for example: `ContainedTextInput`
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedInput.displayName || WrappedInput.name || 'Input';
    InputWithAnalytics.displayName = `Contained${WrappedComponentName}`;
  }

  return InputWithAnalytics;
}
