import { getExampleCorporateAuthData } from '../../selectors';
import { createSelector } from '@reduxjs/toolkit';
import { getExamplesList } from '../../../content/selectors';

export const getExampleCorporateAuthSlug = state => getExampleCorporateAuthData(state).slug;
export const getExampleCorporateAuthStep = state => getExampleCorporateAuthData(state).step;

export const getActiveExample = createSelector(
  [getExampleCorporateAuthSlug, getExamplesList],
  (activeSlug, list) => list.find((item) => item.slug === activeSlug) || list[0],
);

export const getActiveSteps = (state) => getActiveExample(state).steps;
