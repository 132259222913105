import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { withRenderFlag } from 'enhancers';

import { ShowMoreButton, PrevNextButton, PageButton } from './components';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const PaginationComponent = ({
  currentPage,
  pagesNumbers,
  handlePrevClick,
  handleNextClick,
  amountOfItemsLeft,
  handleShowMoreClick,
  handlePageClick,
  className,
}) => {
  const nextButtonDisabled = useMemo(
    () => currentPage >= pagesNumbers[pagesNumbers.length - 1],
    [currentPage, pagesNumbers],
  );
  const prevButtonDisabled = useMemo(() => currentPage <= pagesNumbers[0], [currentPage, pagesNumbers]);

  return (
    <div className={className}>
      <ShowMoreButton
        className={cx('marb-s')}
        onClick={handleShowMoreClick}
        amountOfItemsLeft={amountOfItemsLeft}
        shouldRender={amountOfItemsLeft > 0}
      />

      <div className={cx('buttons')}>
        <PrevNextButton
          onClick={handlePrevClick}
          disabled={prevButtonDisabled}
        />

        <div className={cx('pages')}>
          {pagesNumbers.map((page, index) => (
            <PageButton
              className={cx('pageButton')}
              key={index.toString()}
              isActive={page === currentPage}
              onClick={() => handlePageClick(page)}
            >
              {page}
            </PageButton>
          ))}
        </div>

        <PrevNextButton
          isForward
          disabled={nextButtonDisabled}
          onClick={handleNextClick}
        />
      </div>
    </div>
  );
};

PaginationComponent.propTypes = {
  amountOfItemsLeft: PropTypes.number,
  currentPage: PropTypes.number,
  pagesNumbers: PropTypes.array,
  handlePageClick: PropTypes.func,
  handlePrevClick: PropTypes.func,
  handleNextClick: PropTypes.func,
  handleShowMoreClick: PropTypes.func,
  className: classNamePropTypes,
};

export const Pagination = withRenderFlag(PaginationComponent);
