import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ScrollWrapper } from 'shared';
import { Heading, Text, Wrapper } from 'components';
import { Benefit } from './Benefit';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

export const BenefitsComponent = ({ heading, description, list, isMobile, className }) => (
  <Wrapper className={className}>
    <Heading className={cx('marb-xs')} isMobile={isMobile}>
      {heading}
    </Heading>
    <Text isMedium={!isMobile} className={cx(isMobile ? 'marb-xs' : 'marb-l')}>
      {description}
    </Text>
    <ScrollWrapper>
      {list?.map((el, idx) => <Benefit key={idx.toString()} {...el} className={cx('benefit')} />)}
    </ScrollWrapper>
  </Wrapper>
);

BenefitsComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  list: PropTypes.any,
  heading: PropTypes.string,
  description: PropTypes.string,
  isMobile: PropTypes.bool,
};

