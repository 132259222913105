export function removeFirstOccurrence(arr, value) {
  const index = arr.indexOf(value);
  if (index === -1) return arr;
  return [
    ...arr.slice(0, index),
    ...arr.slice(index + 1),
  ];
}


/**
 * A missing rest spread helper for last arguments
 * Desired spread:
 * (...args, lastArg) => { ... }
 * (...args, lastArg1, lastARg2) => { ... }
 *
 * How to:
 * spreadLast((args, lastArg) => { ... })
 * spreadLast((args, lastArg1, lastArg2) => { ... }, 2)
 */
export const spreadLast = (wrappedFn, count = 1) =>
  (...args) => {
    const restArgs = args.slice();
    const lastArgs = restArgs.splice(-count, count);
    return wrappedFn(restArgs, ...lastArgs);
  };
