import { OPEN_TELESALES_POPUP, CLOSE_TELESALES_POPUP } from './constants';

const initialState = {
  isOpen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_TELESALES_POPUP: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case CLOSE_TELESALES_POPUP: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default:
      return state;
  }
}
