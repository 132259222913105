import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { BasePopupStateless } from 'components/Popup/components/BasePopup';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export class Tooltip extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isContentOpen: false,
    };
  }

  openContent = () => {
    this.setState({ isContentOpen: true });
  };

  hideContent = () => {
    this.setState({ isContentOpen: false });
  };

  render() {
    const { children, className, isMobile } = this.props;
    const { isContentOpen } = this.state;
    return (
      <div
        className={cx('component', className)}
        onMouseOver={isMobile ? null : this.openContent}
        onMouseOut={isMobile ? null : this.hideContent}
      >
        <div className={cx('inner')}>
          <div className={cx('icon')} onClick={isMobile ? this.openContent : null}>i</div>
          {!isMobile &&
            <div className={cx('tip', isContentOpen && 'open')}>
              <div className={cx('tipContent', 'content')}>
                {children}
              </div>
            </div>
          }
        </div>

        {isMobile &&
          <BasePopupStateless
            isOpen={isContentOpen}
            onClose={this.hideContent}
            className={cx('popupWrap')}
          >
            <div className={cx('popup', 'content')}>
              {children}
            </div>
          </BasePopupStateless>
        }
      </div>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};
