import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getNodeCenterOffset } from 'utils/dom-helpers';

import { ScrollContainer } from 'components';
import { Container } from './Container';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const ExampleAuthTabsComponent = ({ tabs, activeExampleSlug, onActiveChange, className }) => {
  const scrollControls = React.useRef(null);
  const containerRef = React.useRef(null);
  const activeTabRef = React.useRef(null);

  const handleActiveChange = ({ e, slug }) => {
    onActiveChange(slug);

    const { scrollTo, getScroll } = scrollControls.current;
    const offset = getNodeCenterOffset(e.target) - getNodeCenterOffset(containerRef.current);

    scrollTo(getScroll() + offset, { config: { tension: 170, friction: 26 } });
  };

  return (
    <div className={cx('component')}>
      <ScrollContainer className={cx('scroller', className)} controlsRef={scrollControls} domNodeRef={containerRef}>
        <Container
          tabs={tabs}
          onClick={handleActiveChange}
          activeTabRef={activeTabRef}
          activeTabSlug={activeExampleSlug}
        />
      </ScrollContainer>
    </div>
  );
};

ExampleAuthTabsComponent.propTypes = {
  activeExampleSlug: PropTypes.string,
  tabs: PropTypes.array,
  onActiveChange: PropTypes.func,
  className: PropTypes.string,
};
