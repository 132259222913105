import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { withRenderFlag } from 'enhancers';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ContentComponent = ({ content, children, className }) => (
  <ol className={cx('component', className)}>
    {content.map((item, index) => (
      <li key={index.toString()} className={cx('item')}>
        {children(item)}
      </li>
    ))}
  </ol>
);

ContentComponent.propTypes = {
  content: PropTypes.array,
  className: classNamePropTypes,
  children: PropTypes.any,
};

export const Content = withRenderFlag(ContentComponent);
