import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getFilters, onAddFilter } from 'store/filters';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { classNamePropTypes, USE_CASES_BLOCK } from 'constants';

import { scrollTo } from 'utils/dom-helpers';

import { Heading, Text } from 'components';
import { Button } from '../Accordion/components/Button';
import { Content } from '../Accordion/components/Content';
import { Filters } from '../Filters';
import { FilterButton } from '../FilterButton';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const ItemComponent = ({ item, currentFilters, onFilterClick, isMobile, className }) => {
  const [isOpen, setOpen] = useState(false);
  const { heading, filters, content } = item;

  const toggle = useCallback(() => { setOpen((prev) => !prev); }, []);

  const handleFilterClick = useCallback((filter) => {
    scrollTo(USE_CASES_BLOCK);
    onFilterClick(filter);
  }, [currentFilters]);

  return (
    <div className={cx('component', className)} onClick={toggle}>
      <Button isOpen={isOpen} />

      <Filters className={cx('filters')} content={filters}>
        {(filter, index) => (
          <FilterButton
            key={index.toString()}
            isMobile={isMobile}
            filter={filter}
            onClick={handleFilterClick}
            disabled={currentFilters.indexOf(filter) !== -1}
          />
        )}
      </Filters>

      <Heading isMobile={isMobile} size={3}>
        {heading}
      </Heading>

      <Content
        className={cx('content')}
        content={content}
        isMobile={isMobile}
        shouldRender={isOpen}
      >
        {({ title, description }) => (
          <>
            <Heading className={cx('heading')} size={4} isMobile={isMobile}>
              {title}
            </Heading>
            <Text isSecondary>
              {description}
            </Text>
          </>
        )}
      </Content>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentFilters: getFilters(state),
  isMobile: isScreenBelow800(state),
});

const mapDispatchToProps = {
  onFilterClick: onAddFilter,
};

ItemComponent.propTypes = {
  item: PropTypes.shape({
    heading: PropTypes.string,
    filters: PropTypes.array,
    content: PropTypes.array,
  }),
  currentFilters: PropTypes.array,
  onFilterClick: PropTypes.func,
  isMobile: PropTypes.bool,
  className: classNamePropTypes,
};

export const Item = connect(mapStateToProps, mapDispatchToProps)(ItemComponent);
