import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import {
  getCorporateAdTitle,
  getCorporateAdImage,
  getCorporateAdText,
} from 'store/content';

import { Heading, SubHeading } from 'components';
import { AdButton } from '../Connected';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const CorporateAdComponent = ({ title, text, image, isMobile, className }) => (
  <div className={cx('component', className)}>
    <div>
      <Heading className={cx(isMobile ? 'marb-xxs' : 'marb-xs')} isMobile={isMobile}>
        {title}
      </Heading>

      <SubHeading className={cx(isMobile ? 'marb-xs' : 'marb-l')} isMedium>
        {text}
      </SubHeading>

      {!isMobile && <AdButton />}
    </div>

    <img
      className={cx(isMobile && 'marb-m')}
      width={isMobile ? 'auto' : '418px'}
      height={isMobile ? 'auto' : '398px'}
      src={image}
      alt=""
    />

    {isMobile && <AdButton />}
  </div>
);

const mapStateToProps = (state) => ({
  title: getCorporateAdTitle(state),
  text: getCorporateAdText(state),
  image: getCorporateAdImage(state),
});

CorporateAdComponent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  isMobile: PropTypes.bool,
};

export const CorporateAd = connect(mapStateToProps)(CorporateAdComponent);
