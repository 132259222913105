import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

export const StepsComponent = ({ steps, activeStepIndex, isTiny, children, className }) => (
  <div className={cx('component', className)}>
    <div className={cx('steps')}>
      {steps?.map((step, index) => {
        // eslint-disable-next-line react/no-danger
        const textFormatted = <span dangerouslySetInnerHTML={{ __html: step.text }} />;

        return (
          <Fragment key={index.toString()}>
            {children({
              icon: index + 1,
              step: index,
              text: step.text,
              children: isTiny ? null : textFormatted,
            })}
          </Fragment>
        );
      })}
    </div>
    {isTiny && (
      <Text>
        {steps?.[activeStepIndex]?.text}
      </Text>
    )}
  </div>
);

StepsComponent.propTypes = {
  steps: PropTypes.array,
  activeStepIndex: PropTypes.number,
  isTiny: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.func,
};
