import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Dots = ({ dots, activeFrame, width, setOffset, setActiveFrame }) => {
  const handleDotClick = (id) => {
    const framePosition = id * width;

    if (activeFrame === id) {
      return;
    }

    setOffset?.((prevOffset) => {
      if (prevOffset < framePosition || activeFrame < id) {
        return -framePosition;
      }

      return framePosition;
    });

    setActiveFrame?.(id);
  };

  return (
    <div className={cx('component')}>
      {dots?.map?.((child, index) => (
        <button
          key={index.toString()}
          className={cx('button', index === activeFrame && 'active')}
          onClick={() => handleDotClick(index)}
        />
      ))}
    </div>
  );
};

Dots.propTypes = {
  dots: PropTypes.array,
  activeFrame: PropTypes.number,
  width: PropTypes.number,
  setOffset: PropTypes.func,
  setActiveFrame: PropTypes.func,
};
