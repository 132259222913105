import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Wrapper } from 'components/Wrapper';
import { Text, Heading } from 'components';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

export const PricesComponent = ({ prices, isMobile, className }) => (
  <Wrapper className={cx(className)}>
    <div className={cx('component')}>
      {prices.map((item, index) => (
        <div key={index.toString()} className={cx('price')}>
          <Heading className={cx(isMobile ? 'marb-xs' : 'marb-m')} isMobile={isMobile}>
            {item.heading}
          </Heading>

          <div className={cx('content')}>
            <img
              className={cx('emoji')}
              width="28"
              height="28"
              src={item.icon}
              alt=""
            />
            <Text>
              {item.text}
            </Text>
          </div>
        </div>
      ))}
    </div>
  </Wrapper>
);

PricesComponent.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
  })),
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};
