import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { classNamePropTypes } from 'constants';

import { Text } from 'components';
import { ExampleOfferCheckbox, ExampleSmallText } from '../../Connected';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

export const AutoFillStep = ({ text, className }) => (
  <div className={cx('component', className)}>
    <Text>
      {text}
    </Text>

    <ExampleOfferCheckbox className={cx('mart-l')} />
    <ExampleSmallText className={cx('mart-m')} />
  </div>
);

AutoFillStep.propTypes = {
  text: PropTypes.string,
  className: classNamePropTypes,
};
