import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { classNamePropTypes } from 'constants';

import { getExamplesHeading } from 'store/content/selectors';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { getIsFillStep, getExamplesFillText, getExamplesButtonText } from 'store/common/exampleAuth/segmentPage';
import { setInitialExampleSegmentAuthSlug } from 'store/common/exampleAuth/segmentPage/actions';

import { Wrapper, Heading } from 'components';
import { AutoFillStep } from 'shared/ExamplesComponents';
import { Image, Steps, ExampleAuthTabs, ExampleStep } from '../Connected';
import { ExampleButtonInfo, TryExampleButton } from 'shared/Connected';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const ExamplesComponent = ({
  heading,
  text,
  isFillStep,
  isMobile,
  onSetExampleSlug,
  shouldRenderButton,
  className,
}) => {
  useEffect(() => { onSetExampleSlug(); }, []);

  return (
    <Wrapper className={cx('wrapper', className)}>
      <div className={cx('blur')} />

      <Heading className={cx('marb-m', 'heading')} isMobile={isMobile}>
        {heading}
      </Heading>

      <div className={cx('component')}>
        <div className={cx('content', 'marr-xs')}>
          <div className={cx('tabs')}>
            <ExampleAuthTabs />
          </div>

          {isMobile && <Image className={cx('image')} />}

          {isFillStep ? (
            <AutoFillStep text={text} />
          ) : (
            <Steps>
              {(props) => (
                <ExampleStep className={cx('step')} {...props} />
              )}
            </Steps>
          )}

          {shouldRenderButton && (
            <div className={cx('container', 'mart-m')}>
              <TryExampleButton />
              <ExampleButtonInfo />
            </div>
          )}
        </div>

        {!isMobile && <Image className={cx('image')} />}
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  heading: getExamplesHeading(state),
  isMobile: isScreenBelow800(state),
  isFillStep: getIsFillStep(state),
  text: getExamplesFillText(state),
  shouldRenderButton: getExamplesButtonText(state),
});

const mapDispatchToProps = {
  onSetExampleSlug: setInitialExampleSegmentAuthSlug,
};

ExamplesComponent.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  isMobile: PropTypes.bool,
  isFillStep: PropTypes.bool,
  shouldRenderButton: PropTypes.bool,
  onSetExampleSlug: PropTypes.func,
  className: classNamePropTypes,
};

export const Examples = connect(mapStateToProps, mapDispatchToProps)(ExamplesComponent);
