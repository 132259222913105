import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { mediaUrl } from 'requests/constants';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function Button({
  children,
  href,
  onClick,
  isMobileId,
  isLight,
  isWide,
  isDisabled,
  isSubmit,
  className,
  isOnlyText,
  isDark,
  dispatch,
  __nestedContextAt0,
  ...otherAttributes
}) {
  const TagName = isSubmit || !href ? 'button' : 'a';
  return (
    <TagName
      href={isSubmit ? null : href} onClick={onClick} type={isSubmit ? 'submit' : null} {...otherAttributes}
      className={cx('component', { wide: isWide, light: isLight, disabled: isDisabled, dark: isDark }, className)}
    >
      <div className={cx('content')}>
        {isMobileId && !isOnlyText && <img src={mediaUrl('mobileid.svg')} alt="логотип Мобильный ID" />}
        <span className={cx({ 'no-arrow-text': isMobileId, 'only-text': isOnlyText })}>{children}</span>
        {!isMobileId && !isOnlyText && <img src={mediaUrl('arrow.svg')} alt="→" />}
      </div>
    </TagName>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  dispatch: PropTypes.func,
  isMobileId: PropTypes.bool,
  isLight: PropTypes.bool,
  isDark: PropTypes.bool,
  isWide: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSubmit: PropTypes.bool,
  className: PropTypes.string,
  isOnlyText: PropTypes.bool,
  // TODO: докопаться до истоков этих пропсов
  __nestedContextAt0: PropTypes.any,
};
