import { createAction } from '@reduxjs/toolkit';
import { checkAuthStatus } from 'requests/api/auth';

export const setIsAuth = createAction('SET_IS_AUTH');
export const setIsEmployee = createAction('SET_IS_EMPLOYEE');
export const setAuthModalSource = createAction('SET_AUTH_MODAL_SOURCE');
export const checkAuthStatusAction = () => async (dispatch) => {
  const { isEmployee, isAuthenticated } = await checkAuthStatus();
  dispatch(setIsAuth(isAuthenticated));
  dispatch(setIsEmployee(isEmployee));
};
