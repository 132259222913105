import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { getFooterCopyright } from 'store/common/selectors';
import { Wrapper } from 'components/Wrapper';

import { templateParser } from 'utils/formatting';

import styles from './styles.pcss';
const cx = classNames.bind({ ...styles });


function Footer({ children, isMobile }) {
  return (
    <div className={cx('container', isMobile && 'mobile')}>
      <Wrapper className={cx('content')}>
        {templateParser(children, { date: new Date().getFullYear() })}
      </Wrapper>
    </div>
  );
}

Footer.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool,
};

const mapStateToProps = state => ({
  children: getFooterCopyright(state),
  isMobile: isScreenBelow800(state),
});

export default connect(mapStateToProps)(Footer);
