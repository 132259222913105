import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { SuperRetinaImage, ContentSwitcher } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ImageComponent = ({ activeStep, activeExampleSlug, className }) => {
  const imageSrc = `https://static.beeline.ru/upload/MobileID/images/b2b-corporate-landing/examples/${activeExampleSlug}/screen-${activeStep + 1}.webp`;

  return (
    <div className={cx('component', className)}>
      <ContentSwitcher secondaryIndex={activeStep} activeSlug={activeExampleSlug}>
        <SuperRetinaImage className={cx('img', className)} src={imageSrc} loading="lazy" />
      </ContentSwitcher>
    </div>
  );
};

ImageComponent.propTypes = {
  activeStep: PropTypes.number,
  activeExampleSlug: PropTypes.string,
  className: PropTypes.string,
};
