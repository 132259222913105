import React from 'react';
import PropTypes from 'prop-types';

import { BlockItemContext } from 'store/page/blockItems/context';
import * as Blocks from 'store/page/blocks/selectors';
import { getExampleAuthSlug } from 'store/common/exampleAuth/mainPage/selectors';


function StepProvider({ slug, children }) {
  return (
    <BlockItemContext.Provider value={slug} key={slug}>
      {typeof children === 'function' ? children(slug) : children}
    </BlockItemContext.Provider>
  );
}

StepProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  slug: PropTypes.string,
};

const mapStateToProps = (state) => ({
  slug: getExampleAuthSlug(state),
});

export const ExampleActiveStepProvider = Blocks.connect(mapStateToProps)(StepProvider);
