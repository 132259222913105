import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { BasePopupStateless } from 'components/Popup/components/BasePopup';
import { useEventListener } from 'hooks';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TextTooltip = ({ children, className, isMobile, tooltipMessage, tooltipPosition }) => {
  const tooltipRef = useRef(null);
  const wrapperRef = useRef(null);
  const [isContentOpen, setContentOpen] = useState(!isMobile);
  const tooltipMessageParameters = useRef({
    width: 0,
    height: 0,
  });
  const [tooltipMessagePosition, setTooltipMessagePosition] = useState(tooltipPosition);

  useEffect(() => {
    if (!isMobile) {
      tooltipMessageParameters.current = {
        width: tooltipRef.current.offsetWidth,
        height: tooltipRef.current.offsetHeight,
      };
      setTooltipMessageStyles();
      setContentOpen(false);
    }
  }, []);

  const setTooltipMessageStyles = () => {
    const { bottom, left } = wrapperRef.current.getBoundingClientRect();
    const { height, width } = tooltipMessageParameters.current;

    setTooltipMessagePosition({
      bottom: window.innerHeight - bottom >= height,
      right: window.innerWidth - left <= width,
    });
  };

  useEventListener('resize', setTooltipMessageStyles, window);

  const openContent = () => {
    setContentOpen(true);
  };

  const hideContent = () => {
    setContentOpen(false);
  };

  return (
    <div
      className={cx('component', className)}
      onMouseOver={isMobile ? null : openContent}
      onMouseOut={isMobile ? null : hideContent}
    >
      <div className={cx('inner')}>
        <div
          ref={wrapperRef}
          className={cx('wrapper')}
          onClick={isMobile ? openContent : null}
        >
          {children}
        </div>
        {isMobile ? (
          <BasePopupStateless
            isOpen={isContentOpen}
            onClose={hideContent}
            className={cx('popupWrap')}
          >
            <div className={cx('popup', 'content')}>
              {tooltipMessage}
            </div>
          </BasePopupStateless>
        ) : (
          <div
            ref={tooltipRef}
            className={cx(
              'tip',
              isContentOpen && 'open',
              tooltipMessagePosition.bottom ? 'bottom' : 'top',
              tooltipMessagePosition.right ? 'right' : 'left',
            )}
          >
            <div className={cx('tipContent', 'content')}>
              {tooltipMessage}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TextTooltip.defaultProps = {
  tooltipPosition: {
    bottom: true,
    right: true,
  },
};

TextTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  tooltipMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]).isRequired,
  tooltipPosition: PropTypes.shape({
    bottom: PropTypes.bool,
    right: PropTypes.bool,
  }),
};
