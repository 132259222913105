import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { getModalLaunchPendingFlag, getSaveDataPendingFlag } from 'store/common/scenario/selectors';
import { FixedOverlay, Preloader } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


function GlobalPreloader({ isOpen, className }) {
  const [isTiredOfWaiting, setTiredOfWaiting] = React.useState(false);
  const timerRef = React.useRef(0);

  React.useEffect(() => {
    if (isOpen) {
      timerRef.current = setTimeout(() => setTiredOfWaiting(true), 450);
    } else {
      clearTimeout(timerRef.current);
      setTiredOfWaiting(false);
    }
  }, [isOpen]);
  return (
    <FixedOverlay isOpen={isOpen} onClose={() => null} className={cx('container', className)}>
      {isTiredOfWaiting && <Preloader />}
    </FixedOverlay>
  );
}

GlobalPreloader.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isOpen: getModalLaunchPendingFlag(state) || getSaveDataPendingFlag(state),
});

export default connect(mapStateToProps)(GlobalPreloader);
