import { createSelector } from '@reduxjs/toolkit';

import { blankObj } from 'utils/fn';
import { AuthType } from './constants';

import { getExampleSegmentAuthData } from '../../selectors';
import { getExamples, getExamplesList } from '../../../content/selectors';

export const getExampleSegmentAuthSlug = state => getExampleSegmentAuthData(state).slug;
export const getExampleSegmentAuthStep = state => getExampleSegmentAuthData(state).step;
export const getIsFillStep = state => getExampleSegmentAuthSlug(state) === AuthType.FILL;
export const getExamplesFill = state => getExamples(state).list?.find(({ slug }) => slug === AuthType.FILL) || blankObj;
export const getExamplesInitialStep = state => getExamples(state).list?.[0]?.slug || AuthType.FILL;
export const getExamplesFillText = state => getExamplesFill(state).text;
export const getExamplesButtonText = state => getExamples(state).buttonText;
export const getActiveExample = createSelector(
  [getExampleSegmentAuthSlug, getExamplesList],
  (activeSlug, list) => list.find((item) => item.slug === activeSlug) || list[0],
);

export const getActiveSteps = (state) => getActiveExample(state).steps;

export const getExampleSegmentAuthOfferAccepted = state => getExampleSegmentAuthData(state).offerAccepted;
export const getExampleSegmentAuthConditionsMet = createSelector(
  getExampleSegmentAuthSlug,
  getExampleSegmentAuthOfferAccepted,
  (slug, isAccepted) => slug !== AuthType.FILL || isAccepted,
);

const getExampleSegmentAuth = createSelector(
  [getExamplesList, getExampleSegmentAuthSlug],
  (list, authSlug) => list.find(({ slug }) => slug === authSlug),
);

export const getImageUrl = createSelector([
  getExampleSegmentAuth,
  getExampleSegmentAuthStep,
], (state, step) => (state?.steps ? state.steps?.[step]?.imageUrl : null));
