import React from 'react';
import PropTypes from 'prop-types';

import { postfixImageName } from 'utils/formatting';

export function SuperRetinaImage({ src, alt, ...otherProps }) {
  return (
    <img
      src={src}
      alt={alt}
      srcSet={`${postfixImageName(src, '@2')} 2x, ${postfixImageName(src, '@3')} 3x`}
      {...otherProps}
    />
  );
}

SuperRetinaImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};
