import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Heading, Wrapper } from 'components';
import { Item } from './Item';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

export const HowToComponent = ({ heading, list, isMobile, className, children }) => (
  <Wrapper className={className}>
    <Heading className={cx('marb-m')} isMobile={isMobile}>
      {heading}
    </Heading>
    <ul className={cx('component', isMobile ? 'marb-m' : 'marb-l')}>
      {list?.map((el, idx) => <Item key={idx.toString()} {...el} className={cx('item')} />)}
    </ul>

    {children}
  </Wrapper>
);

HowToComponent.propTypes = {
  className: PropTypes.string,
  list: PropTypes.any,
  heading: PropTypes.string,
  isMobile: PropTypes.bool,
};

