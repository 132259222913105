import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const LinkBlock = ({ href, onClick, children, className, ...otherProps }) => {
  let TagName = 'div';
  const isClickableElement = !!(href || onClick);
  const externalProps = {
    div: { },
    button: {
      onClick,
      type: 'button',
    },
    a: {
      href,
      target: '_blank',
      rel: 'noreferrer',
    },
  };

  if (isClickableElement) {
    TagName = onClick ? 'button' : 'a';
  }

  return (
    <TagName
      {...otherProps}
      {...externalProps[TagName]}
      className={cx('component', className)}
    >
      {children}
    </TagName>
  );
};

LinkBlock.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};
