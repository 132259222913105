import { createSelector } from 'reselect';

import { getExampleAuthData, getExampleAuthModalData } from '../../selectors';
import { AuthType } from './constants';
import { decodeAuthCallback } from '../../../../pages/App/helpers';


export const getExampleAuthModalHeading = state => getExampleAuthModalData(state).heading;
export const getExampleAuthModalText = state => getExampleAuthModalData(state).text;
export const getExampleAuthModalButtonText = state => getExampleAuthModalData(state).buttonText;
export const getExampleAuthModalNameLabel = state => getExampleAuthModalData(state).nameLabel;
export const getExampleAuthModalDescription = state => getExampleAuthModalData(state).description;
export const getExampleAuthModalMiddleNameLabel = state => getExampleAuthModalData(state).middleNameLabel;
export const getExampleAuthModalPatronymicLabel = state => getExampleAuthModalData(state).patronymicLabel;
export const getExampleAuthErrorHeading = state => getExampleAuthModalData(state).errorHeading;
export const getExampleAuthErrorMessage = state => getExampleAuthModalData(state).errorMessage;
export const getExampleAuthCallback = () => decodeAuthCallback(window.location.search.substring(1));
export const getExampleAuthOpenFlag = state => !!getExampleAuthCallback(state);

export const getExampleAuthSlug = state => getExampleAuthData(state).slug;
export const getExampleAuthStep = state => getExampleAuthData(state).step;
export const getExampleAuthOfferAccepted = state => getExampleAuthData(state).offerAccepted;
export const getExampleAuthConditionsMet = createSelector(
  getExampleAuthSlug,
  getExampleAuthOfferAccepted,
  (slug, isAccepted) => slug !== AuthType.FILL || isAccepted,
);
