import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { withRenderFlag } from 'enhancers';
import { isIE11 } from 'utils/fn';
import { ALREADY_VIEWED_ABOUT_IE } from './constants';

import { BasePopupStateless } from 'components';
import { IEWarningPopupButton, IEWarningPopupText } from '../Connected';
import WarningIcon from './warning.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const IEWarningPopupLayout = () => {
  const [isViewed, setIsViewed] = useState(false);
  const handleClose = () => {
    sessionStorage.setItem(ALREADY_VIEWED_ABOUT_IE, 'true');
    setIsViewed(true);
  };

  useEffect(() => {
    if (sessionStorage.getItem(ALREADY_VIEWED_ABOUT_IE)) {
      setIsViewed(true);
    }
  }, []);

  return (
    <BasePopupStateless
      isOpen={!isViewed}
      onClose={handleClose}
      className={cx('component')}
    >
      <div className={cx('inner')}>
        <WarningIcon className={cx('icon')} />
        <IEWarningPopupText className={cx('text')} />
        <IEWarningPopupButton onClick={handleClose} />
      </div>
    </BasePopupStateless>
  );
};


export const IEWarningPopup = connect(() => ({
  shouldRender: isIE11(),
}))(withRenderFlag(IEWarningPopupLayout));
