import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';
import { Text } from 'components/Text';

import styles from './parts/withLabel/styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


export function withLabel(WrappedInput) {
  function InputWithLabel({ label, isColumn, className, ...otherProps }) {
    return (
      <div className={cx('component', { col: isColumn }, className)}>
        <Text className={cx('label', { 'marb-xxs': isColumn })}>{label}</Text>
        <WrappedInput {...otherProps} className={cx('input')} />
      </div>
    );
  }

  InputWithLabel.propTypes = {
    label: PropTypes.node,
    isColumn: PropTypes.bool,
    ...WrappedInput.propTypes,
  };

  /**
   * Override component name by prepending `Label~`
   * to make it look nice, for example: `LabelTextInput`
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedInput.displayName || WrappedInput.name || 'Input';
    InputWithLabel.displayName = `Label${WrappedComponentName}`;
  }

  return InputWithLabel;
}
