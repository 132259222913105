import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { getNodeCenterOffset } from 'utils/dom-helpers';
import { getExampleAuthSlug } from 'store/common/exampleAuth/mainPage/selectors';
import { setExampleAuthSlug } from 'store/common/exampleAuth/mainPage/actions';
import { ScrollContainer, TabsList } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


function ExampleAuthTabs({ activeId, children, onActiveChange, className }) {
  const scrollControls = React.useRef(null);
  const containerRef = React.useRef(null);
  const itemsRef = React.useRef({});

  const handleActiveChange = (id) => {
    onActiveChange(id);

    const { scrollTo, getScroll } = scrollControls.current;
    const offset = getNodeCenterOffset(itemsRef.current[id]) - getNodeCenterOffset(containerRef.current);

    scrollTo(getScroll() + offset, { config: { tension: 170, friction: 26 } });
  };

  /** flush children */
  itemsRef.current = {};
  return (
    <ScrollContainer className={cx('scroller', className)} controlsRef={scrollControls} domNodeRef={containerRef}>
      <TabsList className={cx('row')} activeId={activeId} onChange={handleActiveChange} itemsRef={itemsRef}>
        {children}
      </TabsList>
    </ScrollContainer>
  );
}

ExampleAuthTabs.propTypes = {
  children: PropTypes.node,
  onActiveChange: PropTypes.func,
  activeId: PropTypes.node,
  className: PropTypes.string,
};


const mapStateToProps = (state) => ({
  activeId: getExampleAuthSlug(state),
});

const mapDispatchToProps = {
  onActiveChange: setExampleAuthSlug,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExampleAuthTabs);
