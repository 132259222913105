import { composeMobileIDAuthFrameUrl } from 'requests/api/constants';
import { getLocaleAlways } from 'requests/helpers';
import { HIDE_EXAMPLE_AUTH_POPUP, SET_EXAMPLE_SLUG, SET_EXAMPLE_STEP, TOGGLE_OFFER_CHECK } from './constants';
import { browserHistory } from '../../../history';
import { getExampleAuthOfferAccepted, getExampleAuthSlug, getExampleAuthStep } from './selectors';
import { getExampleAuthStepsCount } from '../../../page/blockItems/selectors';
import { getTitle } from '../../../page/examples/selectors';
import { SERVICE_TITLE } from 'constants';

export const hideExampleAuthModal = () =>
  (dispatch) => {
    browserHistory.push(window.location.pathname);
    dispatch({ type: HIDE_EXAMPLE_AUTH_POPUP });
  };

export const triggerMobileIdAuth = () =>
  (dispatch, getState) => {
    const state = getState();
    const authType = getExampleAuthSlug(state);
    const locale = getLocaleAlways();
    const title = getTitle(state);

    sessionStorage.setItem(SERVICE_TITLE, title);

    window.location.href = composeMobileIDAuthFrameUrl({ slug: authType, locale });
  };

export const toggleExampleOffer = () =>
  (dispatch, getState) => {
    const state = getState();
    const offerAccepted = getExampleAuthOfferAccepted(state);
    dispatch({
      type: TOGGLE_OFFER_CHECK,
      payload: !offerAccepted,
    });
  };

export const setExampleAuthSlug = (payload) => ({
  type: SET_EXAMPLE_SLUG,
  payload,
});

export const setExampleAuthStep = (payload) => ({
  type: SET_EXAMPLE_STEP,
  payload,
});

export const incrementExampleAuthStep = (offset) =>
  (dispatch, getState) => {
    const state = getState();
    const countSteps = getExampleAuthStepsCount(state);
    const step = getExampleAuthStep(state);
    dispatch({
      type: SET_EXAMPLE_STEP,
      payload: Math.min(countSteps - 1, Math.max(0, step + offset)),
    });
  };
