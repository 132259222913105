import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


export function Wrapper({ children, id, className }) {
  return (
    <div id={id} className={cx('container', 'padh-s', className)}>
      {children}
    </div>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
};
