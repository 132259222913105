import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { Text } from '../../../Text';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PageButton = ({ children, onClick, isActive, className }) => (
  <button
    type="button"
    className={cx('component', { active: isActive }, className)}
    onClick={onClick}
  >
    <Text isInverse={isActive}>
      {children}
    </Text>
  </button>
);

PageButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  className: classNamePropTypes,
};
