import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { createStatefulReducer } from 'utils/redux-stateful';
import breakpointReducer from './common/breakpoint/reducers';
import scenarioReducer from './common/scenario/reducers';
import exampleAuthReducer from './common/exampleAuth/mainPage/reducers';
import exampleCorporateAuthReducer from './common/exampleAuth/corporateAuth/reducers';
import exampleSegmentAuthReducer from './common/exampleAuth/segmentPage/reducers';
import orderFormReducer from './common/orderForm/reducers';
import b2bAuthReducer from './common/b2bAuth/reducers';
import telesalesResultReducer from './common/telesalesResult/reducers';
import { contentReducer } from './content';
import { filtersReducer } from './filters';

import { consultationReducer } from './common/consultation/reducer';
import { optionsReducer } from './options/reducer';
import { segmentsMenuReducer } from './common/menu/reducer';

import { START_SCENARIO, SAVE_DATA } from './common/scenario/constants';

const scenarioRootReducer = combineReducers({
  launchState: createStatefulReducer(START_SCENARIO),
  saveDataState: createStatefulReducer(SAVE_DATA),
  other: scenarioReducer,
});

export const createRootReducer = (history) => (state = { common: {} }, action) => ({
  ...state,
  common: {
    ...state.common,
    orderModal: orderFormReducer(state.common.orderModal, action),
    breakpoint: breakpointReducer(state.common.breakpoint, action),
    scenario: scenarioRootReducer(state.common.scenario, action),
    exampleAuth: exampleAuthReducer(state.common.exampleAuth, action),
    exampleCorporateAuth: exampleCorporateAuthReducer(state.common.exampleCorporateAuth, action),
    exampleSegmentAuth: exampleSegmentAuthReducer(state.common.exampleSegmentAuth, action),
    b2bAuth: b2bAuthReducer(state.common.b2bAuth, action),
    consultationModal: consultationReducer(state.common.consultationModal, action),
    segmentsMenu: segmentsMenuReducer(state.common.segmentsMenu, action),
    telesalesResult: telesalesResultReducer(state.common.telesalesResult, action),
  },
  content: contentReducer(state.content, action),
  router: connectRouter(history)(state.router, action),
  options: optionsReducer(state.options, action),
  filters: filtersReducer(state.filters, action),
});
