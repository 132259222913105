import { composeMobileIDAuthFrameUrl } from 'requests/api/constants';
import { getLocaleAlways } from 'requests/helpers';
import { SERVICE_TITLE } from 'constants';
import { SET_EXAMPLE_SLUG, SET_EXAMPLE_STEP, TOGGLE_OFFER_CHECK, AuthType} from './constants';
import {
  getExampleSegmentAuthOfferAccepted,
  getExampleSegmentAuthSlug,
  getExampleSegmentAuthStep,
  getExamplesInitialStep
} from './selectors';
import { getExampleSegmentAuthStepsCount } from '../../../page/blockItems/selectors';
import { getTitle } from '../../../page/examples/selectors';


export const setExampleSegmentAuthSlug = (payload) => ({
  type: SET_EXAMPLE_SLUG,
  payload,
});

export const setInitialExampleSegmentAuthSlug = () =>
  (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: SET_EXAMPLE_SLUG,
      payload: getExamplesInitialStep(state),
    });
  };

export const setExampleSegmentAuthStep = (payload) => ({
  type: SET_EXAMPLE_STEP,
  payload,
});

export const incrementExampleSegmentAuthStep = (offset) =>
  (dispatch, getState) => {
    const state = getState();
    const countSteps = getExampleSegmentAuthStepsCount(state);
    const step = getExampleSegmentAuthStep(state);
    dispatch({
      type: SET_EXAMPLE_STEP,
      payload: Math.min(countSteps - 1, Math.max(0, step + offset)),
    });
  };

export const toggleSegmentExampleOffer = () =>
  (dispatch, getState) => {
    const state = getState();
    const offerAccepted = getExampleSegmentAuthOfferAccepted(state);
    dispatch({
      type: TOGGLE_OFFER_CHECK,
      payload: !offerAccepted,
    });
  };

export const triggerMobileIdAuthSegment = () =>
  (dispatch, getState) => {
    const state = getState();
    const authType = getExampleSegmentAuthSlug(state);
    const locale = getLocaleAlways();
    const title = getTitle(state);

    sessionStorage.setItem(SERVICE_TITLE, title);

    window.location.href = composeMobileIDAuthFrameUrl({ slug: authType, locale });
  };
