import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { EL } from 'analytics/constants';

import { formatRub, postfixImageName } from 'utils/formatting';
import { mediaUrl } from 'requests/constants';
import { getLocale } from 'requests/helpers';
import { withRenderFlag } from 'enhancers';
import { isScreenBelow502, isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { setExampleAuthStep, toggleExampleOffer, triggerMobileIdAuth } from 'store/common/exampleAuth/mainPage/actions';
import { openPopup as openB2BModal } from 'store/common/b2bAuth/actions';
import { openConsultationPopup } from 'store/common/consultation/actions';
import { AuthType } from 'store/common/exampleAuth/mainPage/constants';
import { setAuthModalSource } from 'store/options/actions';

import { getIsAuth } from 'store/options/selectors';
import { getMainBannerHeading, getMainBannerText } from 'store/page/selectors';
import {
  getActionButtonText,
  getCorporateAdButtonText,
} from 'store/content';
import {
  getConsultationButtonText,
  getLinkButtonText,
  getInstructionButtonText,
  getInstructionLink,
} from 'store/common/selectors';

import {
  getExampleAuthConditionsMet, getExampleAuthOfferAccepted, getExampleAuthSlug, getExampleAuthStep,
} from 'store/common/exampleAuth/mainPage/selectors';
import * as Block from 'store/page/blocks/selectors';
import * as BlockItem from 'store/page/blockItems/selectors';
import { getTitle, getImageUrl } from 'store/page/examples/selectors';

import { ExampleBgLayout } from 'shared';
import { RHeading, RSubHeading, RText, RButton } from 'shared/Connected';
import { StatefulFaqItem, Benefit, Step, Checkbox } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export { default as PdfIcon } from './assets/pdf-icon.svg';

export const MainBannerHeading = connect((state) => ({
  size: 1,
  children: getMainBannerHeading(state),
}))(RHeading);

export const MainBannerText = connect((state) => ({
  children: getMainBannerText(state),
  isLarge: true,
}))(RSubHeading);

// export const ActionButton = Block.connect((state) => ({
//   children: getActionButtonText(state),
//   href: getMobileIdHref(state),
//   target: '_blank',
// }))(RButton);

export const ActionButton = Block.connect((state) => ({
  children: getActionButtonText(state),
  isOnlyText: true,
  shouldRender: !getIsAuth(state),
}), (dispatch) => ({ onClick: () => {
  dispatch(setAuthModalSource(EL.connect));
  dispatch(openB2BModal());
} }))(withRenderFlag(RButton));

export const ConsultationButton = Block.connect((state) => ({
  children: getConsultationButtonText(state),
  isOnlyText: true,
  isDark: true,
  shouldRender: !getIsAuth(state),
}), { onClick: openConsultationPopup })(withRenderFlag(RButton));

export const BlockActionButton = Block.connect((state) => ({
  children: getLinkButtonText(state),
  isOnlyText: true,
  shouldRender: Block.hasButton(state) && !getIsAuth(state),
}), (dispatch) => ({ onClick: () => {
  dispatch(setAuthModalSource(EL.connect_2));
  dispatch(openB2BModal());
} }))(withRenderFlag(RButton));

export const BlockInstructionButton = Block.connect((state) => ({
  children: getInstructionButtonText(state),
  isOnlyText: true,
  isDark: true,
  href: getInstructionLink(state),
  target: '_blank',
  shouldRender: Block.hasButton(state),
}))(withRenderFlag(RButton));

export const TryExampleButton = Block.connect((state) => ({
  children: Block.getButtonText(state),
  isMobileId: true,
  isDisabled: !getExampleAuthConditionsMet(state),
  title: getTitle(state),
}), { onClick: triggerMobileIdAuth })(RButton);

export const ExampleButtonInfo = Block.connect((state) => {
  const exampleAuthSlug = getExampleAuthSlug(state) || AuthType.PUSH;

  return {
    isSmall: true,
    isSecondary: true,
    children: Block.getButtonAlt(state)?.[exampleAuthSlug],
    className: cx({ buttonAlt: !isScreenBelow502(state) }),
  };
}, {})(RText);

export const ExampleStep = connect((state, { step }) => ({
  isActive: step === getExampleAuthStep(state),
  isTiny: true,
}), (dispatch, { step, title, text }) => ({
  onClick: () => {
    dispatch(setExampleAuthStep(step));
  },
}))(Step);

export const ExampleOfferCheckbox = Block.connect((state) => ({
  children: Block.getOfferText(state),
  shouldRender: getExampleAuthSlug(state) === AuthType.FILL,
  value: getExampleAuthOfferAccepted(state),
  isWhite: true,
}), { onChange: toggleExampleOffer })(withRenderFlag(Checkbox));

export const ExampleSmallText = Block.connect((state) => ({
  children: Block.getSmallText(state),
  shouldRender: getExampleAuthSlug(state) === AuthType.FILL,
  isSmall: true,
}))(withRenderFlag(RText));

export const BlockHeading = Block.connect((state, { className }) => ({
  children: Block.getHeading(state),
  className: cx(styles.blockHeading, className),
}), {})(RHeading);

export const BlockSubHeading = Block.connect((state, { className }) => ({
  children: Block.getSubHeading(state),
  size: 3,
  className: cx(styles.blockHeading, className),
}), {})(RHeading);

export const TileHeading = BlockItem.connect((state) => ({
  children: BlockItem.getTitle(state),
  size: 4,
}), {})(RHeading);

export const TileText = BlockItem.connect((state) => ({
  children: BlockItem.getText(state),
}), {})(RText);

export const TileIcon = BlockItem.connect((state) => ({
  src: mediaUrl(`benefits/${BlockItem.getSlug(state)}.svg`),
}), {})('img');

export const ItemTitle = BlockItem.connect((state) => ({
  children: BlockItem.getTitle(state),
}), {})(RSubHeading);

export const ItemText = BlockItem.connect((state) => ({
  children: BlockItem.getText(state),
  shouldRender: !!BlockItem.getText(state),
}), {})(withRenderFlag(RText));

export const BenefitExtra = BlockItem.connect((state) => ({
  isSmall: true,
  isSecondary: true,
  children: BlockItem.getExtra(state),
}), {})(RText);

export const TariffPrice = BlockItem.connect((state) => ({
  children: formatRub(BlockItem.getPrice(state)),
}), {})(RSubHeading);


export const DetailedPriceText = Block.connect((state) => ({
  children: Block.getMoreLinkTitle(state),
}), {})(RSubHeading);

export const DetailedPriceExtra = Block.connect((state) => ({
  isSmall: true,
  isSecondary: true,
  children: Block.getMoreLinkExtra(state),
}), {})(RText);

export const ConnectedFaqItem = BlockItem.connect((state) => ({
  title: BlockItem.getTitle(state),
  children: BlockItem.getText(state),
}))(StatefulFaqItem);

export const ItemBenefit = BlockItem.connect((state) => ({
  attachmentUrl: BlockItem.getAttachmentUrl(state),
}))(Benefit);

export const BlockBenefit = Block.connect((state) => ({
  attachmentUrl: Block.getAttachmentUrl(state),
}))(Benefit);

export const BenefitHeading = BlockItem.connect((state) => ({
  children: BlockItem.getTitle(state),
  size: 3,
}), {})(RHeading);

export const AdButton = connect((state) => ({
  isOnlyText: true,
  onClick: () => { window.location.href = getLocale() === 'en' ? '/corporate/en' : '/corporate'; },
  children: getCorporateAdButtonText(state),
}))(RButton);

export const ExampleBg = connect((state) => {
  const props = {
    withBlur: true,
    isMobile: isScreenBelow800(state),
    slug: getExampleAuthSlug(state),
    step: getExampleAuthStep(state),
  };

  let imageUrl = getImageUrl(state);
  const needFormatImageName = imageUrl
    && props.isMobile
    && (props.step === 2 || (props.step === 3 && props.slug === AuthType.PIN))
    && (props.slug !== AuthType.FILL);

  if (needFormatImageName) {
    imageUrl = postfixImageName(imageUrl, '@mobile');
  }

  return {
    ...props,
    imageUrl,
  };
})(ExampleBgLayout);
