import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { REACT_MODELS } from 'constants';

import { getPageReady } from 'store/common/selectors';

import { withRenderFlag, withReactModel } from 'enhancers';

import { PageLayout, Suspense, IEWarningPopup } from 'shared';
import { Prices } from 'shared/Connected';

import { Banner, Benefits, Examples, UseCases } from './components';

import commonStyles from 'styles/common.pcss';

const cx = classNames.bind(commonStyles);

const SegmentPage = () => (
  <PageLayout className={cx}>
    <IEWarningPopup />

    <Banner className={cx('marb-xxxl')} />

    <Examples className={cx('marb-xxxl')} />

    <Benefits className={cx('marb-xxxl')} />

    <Prices className={cx('marb-xxl')} />

    <UseCases className={cx('marb-xxxl', 'padt-m')} />
  </PageLayout>
);


const mapStateToProps = (state) => ({
  shouldRender: getPageReady(state),
});

export default compose(
  connect(mapStateToProps),
  withReactModel(REACT_MODELS.common),
  withReactModel(),
  withRenderFlag,
)(SegmentPage, <Suspense />);
