import { SET_EXAMPLE_SLUG, SET_EXAMPLE_STEP } from './constants';
import { getExampleCorporateAuthStep } from './selectors';
import { getExampleCorporateAuthStepsCount } from '../../../page/blockItems/selectors';


export const setExampleCorporateAuthSlug = (payload) => ({
  type: SET_EXAMPLE_SLUG,
  payload,
});

export const setExampleCorporateAuthStep = (payload) => ({
  type: SET_EXAMPLE_STEP,
  payload,
});

export const incrementExampleCorporateAuthStep = (offset) =>
  (dispatch, getState) => {
    const state = getState();
    const countSteps = getExampleCorporateAuthStepsCount(state);
    const step = getExampleCorporateAuthStep(state);
    dispatch({
      type: SET_EXAMPLE_STEP,
      payload: Math.min(countSteps - 1, Math.max(0, step + offset)),
    });
  };
