import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { getSegmentsMenu } from 'store/content';
import { getIsSegmentsMenuOpen } from 'store/common/menu';

import { withRenderFlag } from 'enhancers';

import { useLockScroll } from 'hooks';

import { Text, LinkBlock } from 'components';
import { Wrapper } from 'components/Wrapper';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const MenuComponent = ({ segments, className }) => {
  useLockScroll();

  return (
    <Wrapper className={cx('component', className)}>
      {segments.map((segment) => (
        <ul key={segment.alias} className={cx('segment')}>
          <Text className={cx('heading')} isMedium isBold500>
            {segment.heading}
          </Text>
          {segment.list.map((item) => (
            <li key={item.alias} className={cx('item')}>
              <LinkBlock href={item.url}>
                <Text className={cx('item-text')} isSecondary>
                  {item.title}
                </Text>
              </LinkBlock>
            </li>
          ))}
        </ul>
      ))}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  segments: getSegmentsMenu(state),
  isOpen: getIsSegmentsMenuOpen(state),
  shouldRender: !!getSegmentsMenu(state)?.length && getIsSegmentsMenuOpen(state),
});

MenuComponent.propTypes = {
  segments: PropTypes.array,
  className: classNamePropTypes,
};

export const Menu = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(MenuComponent);
