import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Tab } from '../Tab';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Container = ({ activeTabSlug, onClick, tabs, className }) => (
  <div
    className={cx('component', className)}
    role="menu"
    tabIndex={0}
  >
    {tabs.map(({ slug, title }, index) => (
      <Tab
        key={index.toString()}
        isActive={slug === activeTabSlug}
        onClick={(e) => { onClick({ e, slug }); }}
      >
        {title}
      </Tab>
    ))}
  </div>
);

Container.propTypes = {
  activeTabSlug: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tabs: PropTypes.array,
};
