import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { Text } from 'components';
import Close from './close.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FilterButton = ({ filter, disabled, isActive, onClick, isMobile, className }) => {
  const { icon, color, text } = filter;

  const handleClick = (e) => {
    onClick?.(filter);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <button
      className={cx('component', className)}
      style={{ backgroundColor: color }}
      onClick={handleClick}
      type="button"
      disabled={disabled}
    >
      <img alt="" src={icon} className={cx('icon')} />

      {!isMobile && (
        <Text>
          {text}
        </Text>
      )}

      {isActive && (
        <div className={cx('close-button')}>
          <Close />
        </div>
      )}
    </button>
  );
};

FilterButton.propTypes = {
  filter: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string,
  }),
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  className: classNamePropTypes,
};
