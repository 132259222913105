import { createAction } from '@reduxjs/toolkit';
import { getFilters } from './selectors';

export const addFilter = createAction('SET_FILTER');
export const removeFilter = createAction('REMOVE_FILTER');

export const onAddFilter = (filter) => (dispatch, getState) => {
  const filterIds = getFilters(getState()).map(({ id }) => id);

  if (filterIds.indexOf(filter.id) !== -1) return;

  dispatch(addFilter(filter));
};
