import { stateValues } from 'utils/redux-stateful';
import { getScenarioData } from '../selectors';


const getModalData = state => getScenarioData(state).other;
export const getModalOpenFlag = state => getModalData(state).isModalOpen;

export const getScenarioWorkflowId = state => getModalData(state).workflowId;

const getModalLaunchState = state => getScenarioData(state).launchState;
export const getModalLaunchPendingFlag = state => getModalLaunchState(state) === stateValues.Pending;

const getScenarioSaveDataState = state => getScenarioData(state).saveDataState;
export const getSaveDataPendingFlag = state => getScenarioSaveDataState(state) === stateValues.Pending;
export const getScenarioLaunchSuccessFlag = state => getScenarioSaveDataState(state) === stateValues.Success;
