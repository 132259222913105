import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';
import { Text } from '../Text';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });


export function ErrorBlock({ children, className }) {
  return (
    <div className={cx('component', className)}>
      <Text className={cx('text')} isSmaller>{children}</Text>
    </div>
  );
}

ErrorBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
