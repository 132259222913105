import { blankObj } from '../utils/fn';

const getGlobalState = state => state;

const locale = (window.location.pathname.search(/\/en([\s\S]*)$\/?$/) ? 'ru' : 'en');

export const getAppCommons = state => getGlobalState(state).common;
export const getPageData = state => getGlobalState(state).page;
export const getAppContent = state => getGlobalState(state).content;
export const getAppCommonContent = state => getAppContent(state)[locale] || blankObj;
export const getAppPageContent = state => getAppContent(state).page || blankObj;
export const getPageMeta = state => getAppPageContent(state).meta || blankObj;
