import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { classNamePropTypes } from 'constants';

import { getSegmentsButtonText } from 'store/content';
import { getIsSegmentsMenuOpen, toggleSegmentsMenu } from 'store/common/menu';

import { withRenderFlag } from 'enhancers';

import { Button } from './Button';
import { Menu } from './Menu';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const SegmentsComponent = ({ isOpen, onClick, className }) => (
  <div className={cx('component', className)}>
    <Button onClick={onClick} />

    <div className={cx('container', { open: isOpen })}>
      <Menu />
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  isOpen: getIsSegmentsMenuOpen(state),
  shouldRender: !!getSegmentsButtonText(state),
});

const mapDispatchToProps = {
  onClick: toggleSegmentsMenu,
};

SegmentsComponent.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  className: classNamePropTypes,
};

export const Segments = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRenderFlag,
)(SegmentsComponent);
