import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'constants';

import { getLocale } from 'requests/helpers';

import { BeelineLogo, MobileIDLogo } from '../../../Connected';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

export const Logos = ({ className }) => {
  const isMainRuPage = useRouteMatch('/');
  const isMainEnPage = useRouteMatch('/en');
  const isMainPage = isMainEnPage?.isExact || isMainRuPage?.isExact;

  const Component = isMainPage ? 'div' : 'a';

  return (
    <Component
      className={cx('component', className)}
      href={getLocale() === 'en' ? '/en' : '/'}
    >
      <BeelineLogo className={cx('logo')} />
      <MobileIDLogo className={cx('logo')} />
    </Component>
  );
};

Logos.propTypes = {
  className: classNamePropTypes,
};
