import { createSelector } from 'reselect';
import { getAppPageContent } from '../selectors';
import { BlockSlugList } from '../constants';
import { blankObj } from '../../utils/fn';


/** mainBanner */
export const getMainBannerData = state => getAppPageContent(state).mainBanner || blankObj;
export const getMainBannerHeading = state => getMainBannerData(state).heading;
export const getMainBannerText = state => getMainBannerData(state).text;
export const getMainBannerDescription = state => getMainBannerData(state).description;
export const getMainBannerSmallText = state => getMainBannerData(state).smallText;
export const getMainBannerMobileBg = state => getMainBannerData(state).mobileBgUrl;
export const getMainBannerBg = state => getMainBannerData(state).bgUrl;

export const getPageBlockSlugs = () => BlockSlugList;

export const getPageBlocksList = createSelector(
  getPageBlockSlugs,
  getAppPageContent,
  (slugs, data) => slugs.map(slug => ({ ...data[slug], slug })),
);
