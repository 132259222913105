import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { valueOr } from 'utils/fn';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function Heading({
  children,
  size,
  isInline,
  tagName,
  style,
  className,
  isTablet,
  isMobile,
}) {
  const Tag = valueOr(tagName, `h${size}`);

  return (
    <Tag
      className={cx('component', `h${size}`, { inline: isInline, isMobile, isTablet }, className)}
      style={style}
      {...(typeof children !== 'string' ? { children } : {
        dangerouslySetInnerHTML: { __html: children },
      })}
    />
  );
}

Heading.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf([1, 2, 3, 4]),
  isInline: PropTypes.bool,
  tagName: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  isTablet: PropTypes.bool,
  isMobile: PropTypes.bool,
};

Heading.defaultProps = {
  size: 2,
};
