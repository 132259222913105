import PropTypes from 'prop-types';

export const FORM_NAMES = {
  loginWithMobileID: 'loginWithMobileID',
  freeConsultation: 'freeConsultation',
};

export const AUTH_MOBILE_ID_STATUSES = {
  retry: 'retry',
  success: 'success',
  error: 'error',
  pushDenied: 'pushDenied',
  timeout: 'timeout',
};

export const AUTH_ERRORS = {
  pushDenied: 'authorization_error',
};

export const SERVICE_TITLE = 'serviceTitle';

export const REACT_MODELS = {
  common: 'b2b-landing-common',
  en: 'b2b-landing-en',
  ru: 'b2b-landing-ru',
  corporate_ru: 'b2b-corporate-landing-ru',
  corporate_en: 'b2b-corporate-landing-en',
};

export const classNamePropTypes = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]);

export const USE_CASES_BLOCK = 'useCases';
